import { idComparer } from "@gp/utility";

import { EventOffer } from "../models";
import { LookupsStore } from "../stores";
import { getSortOrderOrDefault } from "./getSortOrderOrDefault";

export function getEventSortBySport(
  a: EventOffer,
  b: EventOffer,
  lookupsStore: LookupsStore,
) {
  if (a.sportId != b.sportId) {
    const aSport = lookupsStore.sports.get(a.sportId);
    const bSport = lookupsStore.sports.get(b.sportId);
    if (getSortOrderOrDefault(aSport?.sortOrder) < getSortOrderOrDefault(bSport?.sortOrder)) return -1;
    if (getSortOrderOrDefault(aSport?.sortOrder) > getSortOrderOrDefault(bSport?.sortOrder)) return 1;

    if (aSport == null) return 1;
    if (bSport == null) return -1;

    if (aSport.name < bSport.name) return -1;
    if (aSport.name > bSport.name) return 1;

    return idComparer({ id: aSport.id }, { id: bSport.id });
  }

  return 0;
}
