import { decorate, observable, action, runInAction, computed } from 'mobx';

import {
    BettingOffer,
    BettingOfferKeyDelete,
    BettingOfferKeyUpdate,
    Event,
    EventDelete,
    EventKeyBettingOffer,
    EventUpdate,
    IChange,
    Key,
    OfferSubscriptionResponse,
} from '@gp/models';
import { secondsToMilliseconds, Sorter } from '@gp/utility';

import { EventOffer, EventKeyOffer, SportStructureOffer, LiveSportStructureOffer } from '../models';
import { IConfiguration } from './configuration';
import { BaseOfferStore } from './BaseOfferStore';
import { getEventLiveSort, getSportSort, getEventSortBySport, getEventSortBySportCategory, getEventSortByTournament, getSortOrderOrDefault } from '../utility';

import { manipulateOfferSubscriptionResponse } from '../utility/manipulateOfferResponse'

/**
 * NOTE: this store is currently set up for displaying LIVE events!
 * It follows structure: sport -> events
 * 
 * In the near future, this will serve as a general purpose store and specific logic will be moved to specific store
 */

class MainOfferStore extends BaseOfferStore<IConfiguration> {
    eventsInSports: LiveSportStructureOffer = new LiveSportStructureOffer();
    private sportsThatNeedEventResort = new Set();
    private resortAllSports = false;
    private resortAllEvents = false;

    /**
     * Returns list of all events
     */
    get allEvents(): EventOffer[] {
        return this.events;
    }

    /**
     * Returns list of events
     */
    get events(): EventOffer[] {
        return Array.from(this.eventsMap.values()).sort(Sorter.sort(
            'isLive',
            (a, b) => {
                if (a.isLive && b.isLive) {
                    return Sorter.sort(
                        (a1: EventOffer, b1: EventOffer) => getEventSortBySport(a1, b1, this.lookupsStore),
                        'isTop',
                        'startTime',
                        (a1: EventOffer, b1: EventOffer) => getEventSortBySportCategory(a1, b1, this.lookupsStore),
                        (a1: EventOffer, b1: EventOffer) => getEventSortByTournament(a1, b1, this.lookupsStore),
                    )(a, b);
                }
                else if (a.isUpcoming && b.isUpcoming) {
                    return Sorter.sort(
                        'startTime',
                    )(a, b);
                }
                return 0;
            },
            'id'
        ));
    }

    /**
     * Gets events in sport structure. Primarily used for prematch offer
     * Sport -> Category -> Tournament -> Events
     */
    get eventsInSportStructure(): SportStructureOffer {
        const result = new SportStructureOffer();
        result.eventCount = this.eventsMap.size;

        this.eventsMap.forEach((event, eventId) => {
            const existingSport = result.sports.find(s => s.id === event.sportId);

            if (existingSport != null) {
                const existingCategory = existingSport.categories?.find(c => c.id === event.sportCategoryId);

                if (existingCategory) {
                    const existingTournament = existingCategory.tournaments.find(t => t.id === event.tournamentId && t.isOutright === event.isOutright && t.isLive === event.isLive);

                    if (existingTournament) {
                        existingTournament.addEventOffer(new EventOffer(event, this.configuration.display));
                        existingSport.eventCount++;
                    }
                    else {
                        // tournament was not found in the current category offer which means we didn't map this tournament

                        const tournamentOffer = this.getMappedTournament(event);
                        if (tournamentOffer == null) {
                            // skip this iteration
                            return;
                        }

                        existingCategory.addTournamentOffer(tournamentOffer);
                        existingSport.eventCount++;
                    }
                }
                else {
                    // category was not found in the current sport offer which means we didn't map this category

                    const categoryOffer = this.getMappedCategory(event);
                    if (categoryOffer == null) {
                        // skip this iteration
                        return;
                    }

                    existingSport.addSportCategoryOffer(categoryOffer);
                    existingSport.eventCount++;
                }
            }
            else {
                // sport was not found in the current offer which means we didn't map this sport structure.

                const sportOffer = this.getMappedSport(event);
                if (sportOffer == null) {
                    // skip this iteration
                    return;
                }

                result.addSportOffer(sportOffer);
            }
        });

        return result;
    }

    /**
     * Initializes new instance of MainOfferStore
     * @param {IConfiguration} configuration
     */
    constructor(configuration?: IConfiguration) {
        super(configuration);
    }

    assignOfferData(data: OfferSubscriptionResponse) {
        // clear comparison offer store before any new mapping
        this.comparisonOfferStore.clear();
        data = manipulateOfferSubscriptionResponse(data, this);

        if (!this.configuration.enableThrottling) {
            this.processMessage(data);
            this.configuration.bettingTypeSelectorsStore.update(this.eventsInSports.sports, this.lookupsStore.bettingTypesByAbrv);
            this.updateSorting();

            // reset offer indicators
            setTimeout(() => {
                runInAction(() => {
                    Array.from(this.keyOffersMap.values()).map(ko => Array.from(ko.values())).flat().filter(o => o.indicator !== 0).forEach(o => o.indicator = 0);
                });
            }, this.configuration.indicatorDuration || 3500);

            this.initialized = true;
            return;
        }

        if (data.startingVersion === 0) {
            // clear buffer first
            clearTimeout(this._bufferTimeoutId);
            this._bufferTimeoutId = undefined;
            this._buffer = [];

            // process message
            runInAction(() => {
                this.processMessage(data);
                this.configuration.bettingTypeSelectorsStore.update(this.eventsInSports.sports, this.lookupsStore.bettingTypesByAbrv);
                this.updateSorting();
            });

            this.initialized = true;
            return;
        }

        this._buffer.push(data);

        if (this._bufferTimeoutId == null) {
            this._bufferTimeoutId = window.setTimeout(() => runInAction(() => {
                this._buffer.forEach(r => this.processMessage(r));
                this.configuration.bettingTypeSelectorsStore.update(this.eventsInSports.sports, this.lookupsStore.bettingTypesByAbrv);
                this.updateSorting();

                // reset offer indicators
                setTimeout(() => {
                    runInAction(() => {
                        Array.from(this.keyOffersMap.values()).map(ko => Array.from(ko.values())).flat().filter(o => o.indicator !== 0).forEach(o => o.indicator = 0);
                    });
                }, this.configuration.indicatorDuration || 3500);

                this._buffer = [];
                this._bufferTimeoutId = undefined;
            }), secondsToMilliseconds(this.configuration.throttle));
        }

    }

    /**
     * Updates event sorting for all sports in sportsThatNeedEventResort
     */
    updateSorting() {

        for (const sport of this.eventsInSports.sports) {
            if (this.resortAllEvents || this.sportsThatNeedEventResort.has(sport.id)) {
                sport.resortEvents(getEventLiveSort(this.lookupsStore));
            }
        }

        if (this.resortAllSports) {
            this.eventsInSports.resortSports(getSportSort(this.lookupsStore));
        }

        this.sportsThatNeedEventResort.clear();
    }

    /**
     * Process offer message
     */
    processMessage(data: OfferSubscriptionResponse) {
        if (data == null) {
            return;
        }

        const {
            lookups,
            offerChanges,
            startingVersion,
            version
        } = data;

        if (startingVersion === 0) {
            this.logger.logWarn(`Offer reset. Previous version: ${version}`);
            this.reset();
        }

        // we've received older version than we currently have - ignore it
        if (version < this.version) {
            this.logger.logWarn(`Received older offer - skipping. Received: ${version} | Current: ${this.version}`)
            return;
        }

        if (lookups != null && !lookups.isEmpty) {

            if (lookups.sports != null) {
                this.resortAllSports = true;
            }

            if (lookups.categories != null || lookups.tournaments != null) {
                this.resortAllEvents = true;
            }

            this.lookupsStore.update(lookups);
        }
        else {
            this.logger.logInfo("Did not receive lookups.");
        }

        if (offerChanges != null) {
            this.processOfferChanges(offerChanges);
        }
        else {
            this.logger.logWarn("Received empty offer.");
        }

        // update version to new value after mapping is done.
        this.version = version;
    }

    /**
     * Process offer changes (updates / deletes)
     * @param offerChanges offer changes
     */
    processOfferChanges(offerChanges: IChange<EventUpdate, EventDelete>) {
        if (offerChanges.updates != null && offerChanges.updates.length > 0) {
            this.processEventUpdates(offerChanges.updates);
        }

        if (offerChanges.deletes != null && offerChanges.deletes.length > 0) {
            this.processEventDeletes(offerChanges.deletes);
        }
    }

    /**
     * Updates event data and offer
     * @param eventUpdates event data to update
     */
    processEventUpdates(eventUpdates: EventUpdate[]) {
        eventUpdates.forEach(eventUpdate => {
            if (eventUpdate.event != null) {
                this.updateEvent(eventUpdate.event);
            }

            if (eventUpdate.offers != null) {
                if (eventUpdate.offers.updates != null) {
                    this.processKeyUpdates(eventUpdate.eventId, eventUpdate.offers.updates);
                }
                if (eventUpdate.offers.deletes != null) {
                    this.processKeyDeletes(eventUpdate.eventId, eventUpdate.offers.deletes);
                }
            }
        });
    }

    /**
     * Deletes event offer keys
     * @param eventId event id
     * @param keyDeletes keys to delete
     */
    processKeyDeletes(eventId: string, keyDeletes: BettingOfferKeyDelete[]) {
        const eventKeys = this.eventKeysMap.get(eventId);

        keyDeletes.forEach(key => {
            // first delete key offers
            this.keyOffersMap.delete(key.id);

            // then delete key
            if (eventKeys != null) {
                eventKeys.delete(key.id);
            }
        });

        // re-set updated event keys
        // this.eventKeysMap.set(eventId, eventKeys);
    }

    /**
     * Updates event offer keys
     * @param eventId event id
     * @param keyUpdates keys to update
     */
    processKeyUpdates(eventId: string, keyUpdates: BettingOfferKeyUpdate[]) {
        keyUpdates.forEach(keyUpdate => {
            if (keyUpdate.key != null) {
                this.updateKey(eventId, keyUpdate.key);
            }

            // add current key offers state in memory for indicator comparison
            this.comparisonOfferStore.add(eventId, keyUpdate.keyId);

            // update offer
            if (keyUpdate.offers != null) {
                if (keyUpdate.offers.updates) {
                    this.processOfferUpdates(eventId, keyUpdate);
                }
                if (keyUpdate.offers.deletes) {
                    this.processOfferDeletes(keyUpdate.keyId, keyUpdate.offers.deletes);
                }
            }
        });
    }

    /**
     * Updates key data
     * @param eventId event id
     * @param key Key to update
     */
    updateKey(eventId: string, key: Key) {
        if (!this.eventKeysMap.has(eventId)) {
            this.eventKeysMap.set(eventId, new Map<string, EventKeyOffer>());
        }

        const eventKeys = this.eventKeysMap.get(eventId);
        let currentKey = eventKeys?.get(key.id);


        if (currentKey == null) {
            const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

            if (keyBettingType == null) {
                throw `Can not find betting type ${key.bettingTypeId} in lookups.`
            }

            const newKey = this.createNewKey(key, eventId, keyBettingType);

            eventKeys?.set(key.id, newKey);
        }
        else {
            currentKey.update(key);
        }
    }

    /**
     * Deletes offers
     * @param keyId key id
     * @param offers offers to delete
     */
    processOfferDeletes(keyId: string, offers: BettingOffer[]) {
        if (!this.keyOffersMap.has(keyId)) {
            this.logger.logTrace('Offer already removed');
            return;
        };

        const keyOffers = this.keyOffersMap.get(keyId);

        offers.forEach(o => {
            keyOffers?.delete(o.id);
        });

        // this.keyOffersMap.set(keyId, keyOffers);
    }

    /**
     * Updates offers
     * @param eventId event id
     * @param keyId key id
     * @param offers offers to update
     */
    processOfferUpdates(eventId: string, key: BettingOfferKeyUpdate) {
        if (!this.keyOffersMap.has(key.keyId)) {
            this.keyOffersMap.set(key.keyId, new Map<string, EventKeyBettingOffer>());
        }
        const keyOffers = this.keyOffersMap.get(key.keyId);

        const event = this.eventsMap.get(eventId);

        // check if all offers have same playerId, or if they have playerId at all
        let playerId: string | undefined = undefined;
        let teamIds: { teamOneId: string | undefined, teamTwoId: string | undefined } | undefined = undefined;

        if (key.offers?.updates?.every((o, i, src) => o.playerId != null && o.playerId !== '' && o.playerId === src[0].playerId)) {
            playerId = key.offers?.updates[0].playerId;
        }

        if (!event?.isOutright) {
            teamIds = {
                teamOneId: event?.teamOneId,
                teamTwoId: event?.teamTwoId,
            }
        }

        key.offers?.updates?.forEach((o) => {
            const tipInfo = this.getTipInformation({
                defaultTip: o.tip,
                bettingTypeId: key.bettingTypeId,
                playerId: o.playerId,
                teamId: o.teamId,
                specifiers: {
                    ...key.key?.specifier,
                    ...o.specifier
                },
                eventData: {
                    name: event?.name,
                    playerId: playerId,
                    teamIds: teamIds
                }
            });

            const existingOffer = keyOffers?.get(o.id);
            if (existingOffer != null) {
                const comparableValue = this.comparisonOfferStore.getValue(existingOffer.id);

                // calculate indicator
                if (comparableValue === undefined) {
                    existingOffer.indicator = 0;
                }
                else if (o.value > comparableValue) {
                    existingOffer.indicator = 1;
                }
                else if (o.value < comparableValue) {
                    existingOffer.indicator = -1;
                }
                else {
                    existingOffer.indicator = 0;
                }

                existingOffer.isLive = !!event?.isLive;
                existingOffer.isLocked = !!o.isLocked
                existingOffer.value = o.value;

                existingOffer.tip = tipInfo.tip;
                existingOffer.displayTip = tipInfo.displayTip;
                existingOffer.gender = tipInfo.gender;
                existingOffer.countryCode = tipInfo?.countryCode || "";
            }
            else {
                keyOffers?.set(o.id, {
                    ...o,
                    keyId: key.keyId,
                    eventId: eventId,
                    isLive: !!event?.isLive,
                    isLocked: !!o.isLocked,
                    tip: tipInfo.tip,
                    displayTip: tipInfo.displayTip,
                    gender: tipInfo.gender,
                    indicator: 0,
                    countryCode: tipInfo?.countryCode || ""
                });
            }
        });
    }


    /**
     * Deletes events and all event offer
     * @param eventDeletes events to delete
     */
    processEventDeletes(eventDeletes: EventDelete[]) {
        // list of event event ids that require delayed remove
        const delay: string[] = [];

        eventDeletes.forEach(event => {
            if (event.ended) {
                this.updateEvent(event);
                delay.push(event.id);
            }
            else {
                this.deleteEvent(event.id);
            }
        });

        if (delay.length > 0) {
            setTimeout(() => runInAction(() => {
                delay.forEach(eid => this.deleteEvent(eid));
            }), secondsToMilliseconds(this.configuration.removeDelay));
        }
    }

    /**
     * Updates event data
     * @param event Event to update
     */
    updateEvent(event: Event) {
        const newEvent = new EventOffer(event, this.configuration.display);

        // Do this before eventsMap update since 
        // function relies on diff between eventsMap nad newEvent
        this.updatedSportsThatNeedResortForNewEvent(newEvent);

        this.eventsMap.set(event.id, newEvent);
        this.eventsInSports.updateEventCount(this.eventsMap.size);

        // update eventsInSports
        // check if sport is already added
        const existingSport = this.eventsInSports.sports.find(s => s.id === event.sportId);
        if (existingSport != null) {
            // Find event
            const existingEventIdx = existingSport.events?.findIndex(e => e.id === event.id);
            if (existingEventIdx != null && existingEventIdx !== -1) {
                existingSport.events?.[existingEventIdx].update(event);
            }
            else {
                existingSport.addEvent(
                    newEvent,
                    true,
                );
            }
        }
        else {
            // Sport offer does not exist
            const sportOffer = this.getMappedSport(newEvent, true);
            if (sportOffer == null) {
                return;
            }
            this.eventsInSports.addSportOffer(sportOffer);
        }
    }

    /**
     * Takes new event that is constructed on update.
     * Adds it sport to resort list only of it does not exist in offer currently
     * or it does exist and properties used for sorting have changed.
     * @params newEvent event updates
     */
    updatedSportsThatNeedResortForNewEvent(newEvent: Event) {

        if (this.eventsMap.has(newEvent.id)) {

            // We already have this event;
            const currentEvent = this.eventsMap.get(newEvent.id);

            if (
                currentEvent?.startTime !== newEvent.startTime ||
                currentEvent.isTopEvent !== newEvent.isTopEvent
            ) {
                this.sportsThatNeedEventResort.add(newEvent.sportId);
            }
        } else {
            // We don't have event
            this.sportsThatNeedEventResort.add(newEvent.sportId);
        }
    }

    /**
     * Remove event and all event offer
     * @param eventId event id
     */
    deleteEvent(eventId: string) {
        // first delete all event key offers
        const eventKeys = this.eventKeysMap.get(eventId);
        if (eventKeys != undefined) {
            eventKeys.forEach(ek => this.keyOffersMap.delete(ek.id));
        }

        // then delete all event keys
        this.eventKeysMap.delete(eventId);

        if (this.configuration.favoritesStore) {
            // delete from favorites
            this.configuration.favoritesStore.remove(eventId);
        }

        // delete from eventsInSports
        const event = this.eventsMap.get(eventId);
        if (event != null) {
            const existingSportIndex = this.eventsInSports.sports.findIndex(s => s.id === event.sportId);
            const existingSport = this.eventsInSports.sports[existingSportIndex];

            if (existingSport.events != null) {
                const existingEventIdx = existingSport.events != null ? existingSport.events.findIndex(e => e.id === event.id) : -1;
                if (existingEventIdx !== -1) {
                    existingSport.events?.splice(existingEventIdx, 1);
                    existingSport.eventCount = existingSport.events?.length;

                    if (existingSport.eventCount < 1) {
                        // remove this sport!
                        this.eventsInSports.sports.splice(existingSportIndex, 1);
                    }
                }
            }
        }
        else {
            this.logger.logWarn('Event is not in offer anymore', eventId);
            this.logger.logInfo('Checking all sports for event', eventId);

            const sportWithEventIndex = this.eventsInSports.sports.findIndex(s => s.events?.findIndex(e => e.id === eventId) !== -1);
            const sportWithEvent = this.eventsInSports.sports[sportWithEventIndex];

            if (sportWithEvent?.events != null) {
                const eIdx = sportWithEvent.events?.findIndex(e => e.id === eventId);
                // remove event from sport!
                sportWithEvent.events?.splice(eIdx, 1);
                sportWithEvent.eventCount = sportWithEvent.events?.length;
            }
        }

        // then delete event itself
        this.eventsMap.delete(eventId);
        this.eventsInSports.updateEventCount(this.eventsMap.size);
    }

    /**
     * Resets state
     */
    reset() {
        super.reset();

        this.eventsInSports = new LiveSportStructureOffer();
        this.configuration.bettingTypeSelectorsStore.reset();
    }
}


decorate(MainOfferStore, {
    eventsInSports: observable,
    assignOfferData: action,
    processMessage: action,
    processOfferChanges: action,
    processEventUpdates: action,
    processEventDeletes: action,
    updateEvent: action,
    deleteEvent: action,
    processKeyUpdates: action,
    processKeyDeletes: action,
    updateKey: action,
    processOfferUpdates: action,
    processOfferDeletes: action,
    updateSorting: action,
    reset: action,
    allEvents: computed,
    events: computed,
    eventsInSportStructure: computed
});

export {
    MainOfferStore
}