export type RowSpan = 1 | 2;
export type RowType = 'header' | 'event' | 'key' | 'key-offer';

export class PageRowElement {
	constructor(id: string, rowType: RowType, rowSpan: RowSpan = 1) {
		this.id = id;
		this.rowType = rowType;
		this.rowSpan = rowSpan;
	}

	/**
	 * Identifier
	 * @description Value depends on row type property
	 * @example
	 * RowType.'header' -> sportId
	 * RowType.'event' -> eventId
	 * RowType.'key' -> keyId
	 * RowType.'key-offer' -> keyId + key row index
	 * @see this.rowType
	 */
	id: string;

	/**
	 * Row type
	 * 'key' and 'key-offer' are used for additional offer. They both take 1 row but are different in nature.
	 */
	rowType: RowType;

	/**
	 * Number of rows taken. 
	 * For headers it will always be 1, but for events it can be 2 (main and secondary offer)!
	 * @default 1
	 */
	rowSpan: RowSpan = 1;

	/**
	 * Creates row
	 * @param id row id
	 * @param rowType row type
	 * @param rowSpan row span
	 * @deprecated use regular class constructor, this does it anyways.
	 */
	static create(id: string, rowType: RowType, rowSpan: RowSpan = 1): PageRowElement {
		return new PageRowElement(id, rowType, rowSpan);
	}
}