import { idComparer } from "@gp/utility";
import { ComparerFn } from '@gp/utility/dist/types/common';
import { SportOffer } from "../models/SportOffer";

import { LookupsStore } from "../stores";
import { getSortOrderOrDefault } from "./getSortOrderOrDefault";

export function getSportSort(lookupsStore: LookupsStore): ComparerFn<SportOffer> {

    return (a: SportOffer, b: SportOffer) => {

        const aSport = lookupsStore.sports.get(a.id);
        const bSport = lookupsStore.sports.get(b.id);
        if (getSortOrderOrDefault(aSport?.sortOrder) < getSortOrderOrDefault(bSport?.sortOrder)) return -1;
        if (getSortOrderOrDefault(aSport?.sortOrder) > getSortOrderOrDefault(bSport?.sortOrder)) return 1;
    
        if (aSport == null) return 1;
        if (bSport == null) return -1;
    
        if (aSport.name < bSport.name) return -1;
        if (aSport.name > bSport.name) return 1;
    
        return idComparer({ id: aSport.id }, { id: bSport.id });
    }
}
