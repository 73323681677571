import { decorate, observable, action, runInAction, isObservableArray } from 'mobx';
import { FavoritesStoreConfiguration, HTTPClient, UserFavoritesModel, UserFavoritesResponseModel, NotificationProvider } from './types';

class FavoritesStore {
	//#region "fields"
	readonly httpClient: HTTPClient;
	readonly enableThrottling = true;
	readonly throttle = 700; //ms
	readonly notificationProvider: NotificationProvider | null = null;

	private resortCallback = null;

	private _updateBuffer: UserFavoritesModel = {
		userFavoriteBettingTypes: [],
		userFavoriteEvents: new Set(),
		userFavoritePlayers: new Set(),
		userFavoriteTeams: new Set(),
		userFavoriteTournaments: new Set()
	}

	private _deleteBuffer: UserFavoritesModel = {
		userFavoriteBettingTypes: [],
		userFavoriteEvents: new Set(),
		userFavoritePlayers: new Set(),
		userFavoriteTeams: new Set(),
		userFavoriteTournaments: new Set()
	}

	private _bufferTimeoutId: NodeJS.Timeout;

	public userFavoriteTeamsSet: Set<string> = observable.set(new Set(), { name: "userFavoriteTeamsSet" });
	public userFavoriteEventsSet: Set<string> = observable.set(new Set(), { name: "userFavoriteEventsSet" });
	public userFavoritePlayersSet: Set<string> = observable.set(new Set(), { name: "userFavoritePlayersSet" });
	public userFavoriteTournamentsSet: Set<string> = observable.set(new Set(), { name: "userFavoriteTournamentsSet" });
	public userFavoriteBettingTypesMap: Map<string, object[] | null> = observable.map(new Map(), { name: "userFavoriteBettingTypesMap" });

	//#endregion "fields" 

	constructor(configuration: FavoritesStoreConfiguration) {
		Object.assign(this, configuration);

		if (this.httpClient == null) {
			throw "HttpClient required!"
		}

		this.isUserFavoriteEvent = this.isUserFavoriteEvent.bind(this);
		this.isUserFavoriteTournament = this.isUserFavoriteTournament.bind(this);
		this.isUserFavoriteBettingType = this.isUserFavoriteBettingType.bind(this);
	}

	//#region "methods"

	public async getUserFavorites() {
		try {
			const response = await this.httpClient.get();

			runInAction(() => {
				this.setUserFavorites(response);
			})
		} catch (error) {
			this.onError("FAVORITES.GET_ERROR", error);
		}
	}

	private setUserFavorites(userFavorites: UserFavoritesResponseModel) {
		const {
			userFavoriteTeams,
			userFavoriteEvents,
			userFavoritePlayers,
			userFavoriteTournaments,
			userFavoriteBettingTypes,
		} = userFavorites;

		this.userFavoriteTeamsSet = observable.set(new Set(userFavoriteTeams), { name: "userFavoriteTeamsSet" });
		this.userFavoriteEventsSet = observable.set(new Set(userFavoriteEvents), { name: "userFavoriteEventsSet" });
		this.userFavoritePlayersSet = observable.set(new Set(userFavoritePlayers), { name: "userFavoritePlayersSet" });
		this.userFavoriteTournamentsSet = observable.set(new Set(userFavoriteTournaments), { name: "userFavoriteTournamentsSet" });

		userFavoriteBettingTypes.forEach((btt) => {
			this.userFavoriteBettingTypesMap.set(btt.bettingTypeId, btt.specifiers);
		});

		if (typeof this.resortCallback === "function") {
			this.resortCallback()
		}
	}

	public async addUserFavoriteEvent(eventId: string) {

		this.userFavoriteEventsSet.add(eventId);

		if (typeof this.resortCallback === "function") {
			this.resortCallback()
		}

		if (this._deleteBuffer.userFavoriteEvents.has(eventId)) {
			//this is double click, user deleted favorite and instantly clicked back to favorite it
			this._deleteBuffer.userFavoriteEvents.delete(eventId);

			return;
		}

		this._updateBuffer.userFavoriteEvents.add(eventId);

		await this.bufferUserFavoriteUpdate();
	}

	public async removeUserFavoriteEvent(eventId: string) {

		this.userFavoriteEventsSet.delete(eventId);

		if (typeof this.resortCallback === "function") {
			this.resortCallback()
		}

		if (this._updateBuffer.userFavoriteEvents.has(eventId)) {
			//this is double click, user added favorite and instantly clicked to remove it
			this._updateBuffer.userFavoriteEvents.delete(eventId);

			return;
		}

		this._deleteBuffer.userFavoriteEvents.add(eventId);

		await this.bufferUserFavoriteDelete();
	}

	public async removeMultipleUserFavoriteEvents(eventIds?: string[]) {
		await this.handleBuffer();

		const specificEventIds = eventIds || [...this.userFavoriteEventsSet];

		this._deleteBuffer.userFavoriteEvents = new Set(specificEventIds);

		try {
			const response = await this.httpClient.delete(this.createValidPayload(this._deleteBuffer));

			runInAction(() => {
				this.setUserFavorites(response);
				this.clearDeleteBuffer();
			})
		} catch (error) {
			await this.getUserFavorites();

			this.onError("FAVORITES.REMOVE_ALL_ERROR", error);
		}
	}

	public async addUserFavoriteTournament(tournamentId: string) {
		this.userFavoriteTournamentsSet.add(tournamentId);

		if (this._deleteBuffer.userFavoriteTournaments.has(tournamentId)) {
			//this is double click, user deleted favorite and instantly clicked back to favorite it
			this._deleteBuffer.userFavoriteTournaments.delete(tournamentId);

			return;
		}

		this._updateBuffer.userFavoriteTournaments.add(tournamentId);

		await this.bufferUserFavoriteUpdate();
	}

	public async removeUserFavoriteTournament(tournamentId: string) {
		this.userFavoriteTournamentsSet.delete(tournamentId);

		if (this._updateBuffer.userFavoriteTournaments.has(tournamentId)) {
			//this is double click, user added favorite and instantly clicked to remove it
			this._updateBuffer.userFavoriteTournaments.delete(tournamentId);

			return;
		}

		this._deleteBuffer.userFavoriteTournaments.add(tournamentId);

		await this.bufferUserFavoriteDelete();
	}

	public async removeMultipleUserFavoriteTournaments(tournamentIds?: string[]) {
		await this.handleBuffer();

		const specificEventIds = tournamentIds || [...this.userFavoriteTournamentsSet];

		this._deleteBuffer.userFavoriteTournaments = new Set(specificEventIds);

		try {
			const response = await this.httpClient.delete(this.createValidPayload(this._deleteBuffer));

			runInAction(() => {
				this.setUserFavorites(response);
				this.clearDeleteBuffer();
			})
		} catch (error) {
			await this.getUserFavorites();

			this.onError("FAVORITES.REMOVE_ALL_ERROR", error);
		}
	}

	public async addUserFavoriteBettingType(bettingTypeId: string, specifiers?: any) {
		const existingBettingTypeSpecifiers = this.userFavoriteBettingTypesMap.get(bettingTypeId);

		if (isObservableArray(existingBettingTypeSpecifiers)) {
			if (specifiers !== null) {
				if (existingBettingTypeSpecifiers.some((existingSpecifiers) => this.hasSameSpecifiers(existingSpecifiers, specifiers))) {
					//specifier already exists in state
					return;
				}

				existingBettingTypeSpecifiers.push(specifiers);

				const deleteBettingTypeIdx = this._deleteBuffer.userFavoriteBettingTypes.findIndex((existingBettingType) => (existingBettingType.bettingTypeId === bettingTypeId));

				if (deleteBettingTypeIdx !== -1) {
					const specifiersIdx = this._deleteBuffer.userFavoriteBettingTypes[deleteBettingTypeIdx].specifiers.findIndex((existingSpecifiers) => this.hasSameSpecifiers(existingSpecifiers, specifiers));

					if (specifiersIdx !== -1) {
						//this is double click, user deleted favorite and instantly clicked back to favorite it
						this._deleteBuffer.userFavoriteBettingTypes[deleteBettingTypeIdx].specifiers.splice(specifiersIdx, 1);

						if (this._deleteBuffer.userFavoriteBettingTypes[deleteBettingTypeIdx].specifiers.length < 1) {
							this._deleteBuffer.userFavoriteBettingTypes.splice(deleteBettingTypeIdx, 1);
						}

						return;
					}
				}

				const updateBettingTypeIdx = this._updateBuffer.userFavoriteBettingTypes.findIndex((existingBettingType) => (existingBettingType.bettingTypeId === bettingTypeId));

				if (updateBettingTypeIdx !== -1) {
					this._updateBuffer.userFavoriteBettingTypes[updateBettingTypeIdx].specifiers.push(specifiers);
				} else {
					this._updateBuffer.userFavoriteBettingTypes.push({ bettingTypeId: bettingTypeId, specifiers: [specifiers] });
				}
			} else {
				//we have betting type with specifiers and recieved null as new specifier
				//this should never happen and we can't map this
				return;
			}
		} else {
			if (existingBettingTypeSpecifiers === null) {
				//specifier already exists in state
				//null is valid specifier
				return;
			}

			if (specifiers !== null) {
				this.userFavoriteBettingTypesMap.set(bettingTypeId, [specifiers]);
			} else {
				this.userFavoriteBettingTypesMap.set(bettingTypeId, null);
			}

			const bettingTypeIdx = this._deleteBuffer.userFavoriteBettingTypes.findIndex((existingBettingType) => existingBettingType.bettingTypeId === bettingTypeId);

			if (bettingTypeIdx !== -1) {
				//this is double click, user deleted favorite and instantly clicked back to favorite it
				this._deleteBuffer.userFavoriteBettingTypes.splice(bettingTypeIdx, 1);

				return;
			} else {
				if (specifiers !== null) {
					this._updateBuffer.userFavoriteBettingTypes.push({ bettingTypeId: bettingTypeId, specifiers: [specifiers] });
				} else {
					this._updateBuffer.userFavoriteBettingTypes.push({ bettingTypeId: bettingTypeId, specifiers: null });
				}
			}
		}

		await this.bufferUserFavoriteUpdate();
	}

	public async removeUserFavoriteBettingType(bettingTypeId: string, specifiers?: any) {
		const existingBettingTypeSpecifiers = this.userFavoriteBettingTypesMap.get(bettingTypeId);

		if (isObservableArray(existingBettingTypeSpecifiers)) {
			if (specifiers != null) {
				const specifierIndex = existingBettingTypeSpecifiers.findIndex((existingSpecifiers) => this.hasSameSpecifiers(existingSpecifiers, specifiers))

				if (specifierIndex !== -1) {
					existingBettingTypeSpecifiers.splice(specifierIndex, 1);

					const updateBettingTypeIdx = this._updateBuffer.userFavoriteBettingTypes.findIndex((existingBettingType) => (existingBettingType.bettingTypeId === bettingTypeId));

					if (updateBettingTypeIdx !== -1) {
						const specifiersIdx = this._updateBuffer.userFavoriteBettingTypes[updateBettingTypeIdx].specifiers.findIndex((existingSpecifiers) => this.hasSameSpecifiers(existingSpecifiers, specifiers));

						if (specifiersIdx !== -1) {
							//this is double click, user deleted favorite and instantly clicked back to favorite it
							this._updateBuffer.userFavoriteBettingTypes[updateBettingTypeIdx].specifiers.splice(specifiersIdx, 1);

							if (this._updateBuffer.userFavoriteBettingTypes[updateBettingTypeIdx].specifiers.length < 1) {
								this._updateBuffer.userFavoriteBettingTypes.splice(updateBettingTypeIdx, 1);
							}

							return;
						}
					}

					const deleteBettingTypeIdx = this._deleteBuffer.userFavoriteBettingTypes.findIndex((existingBettingType) => (existingBettingType.bettingTypeId === bettingTypeId));

					if (deleteBettingTypeIdx !== -1) {
						this._deleteBuffer.userFavoriteBettingTypes[deleteBettingTypeIdx].specifiers.push(specifiers);
					} else {
						this._deleteBuffer.userFavoriteBettingTypes.push({ bettingTypeId: bettingTypeId, specifiers: [specifiers] });
					}
				}
			} else {
				//we have betting type with specifiers and recieved null as specifier to remove
				//this should not happen, this would mean that we would remove all specifiers from the betting type which is not the case
				return;
			}
		} else {
			if (existingBettingTypeSpecifiers === undefined) {
				//bettingType doesn't exist in state
				//nothing to remove
				return;
			}

			this.userFavoriteBettingTypesMap.delete(bettingTypeId);

			const bettingTypeIdx = this._updateBuffer.userFavoriteBettingTypes.findIndex((existingBettingType) => existingBettingType.bettingTypeId === bettingTypeId);

			if (bettingTypeIdx !== -1) {
				//this is double click, user deleted favorite and instantly clicked back to favorite it
				this._updateBuffer.userFavoriteBettingTypes.splice(bettingTypeIdx, 1);

				return;
			} else {
				if (specifiers !== null) {
					this._deleteBuffer.userFavoriteBettingTypes.push({ bettingTypeId: bettingTypeId, specifiers: [specifiers] });
				} else {
					this._deleteBuffer.userFavoriteBettingTypes.push({ bettingTypeId: bettingTypeId, specifiers: null });
				}
			}

		}

		await this.bufferUserFavoriteDelete();

	}

	private async bufferUserFavoriteUpdate() {
		if (!this.enableThrottling) {
			await this.handleBuffer();

			return;
		}

		clearTimeout(this._bufferTimeoutId);

		this._bufferTimeoutId = setTimeout(() => {
			this.handleBuffer();
		}, this.throttle);
	}

	private async bufferUserFavoriteDelete() {
		if (!this.enableThrottling) {
			await this.handleBuffer();

			return;
		}

		clearTimeout(this._bufferTimeoutId);

		this._bufferTimeoutId = setTimeout(() => {
			this.handleBuffer();
		}, this.throttle);
	}

	private hasSameSpecifiers = (existing: object | null, incoming: object | null) => {
		if (existing === null && incoming === null) {
			return true;
		};

		if ((existing === null && incoming !== null) || (existing !== null && incoming === null)) {
			return false;
		}

		const obj1Length = Object.keys(existing).length;
		const obj2Length = Object.keys(incoming).length;

		if (obj1Length === obj2Length) {
			return Object.keys(existing).every(
				key => incoming.hasOwnProperty(key) && incoming[key] === existing[key]);
		}

		return false;
	}

	private async handleBuffer() {
		const hasUpdates = !this.isBufferEmpty(this._updateBuffer);
		const hasDeletes = !this.isBufferEmpty(this._deleteBuffer);

		if (!hasUpdates && !hasDeletes) {
			//nothing to update
			return;
		}

		try {
			const updatePayload = hasUpdates && this.createValidPayload(this._updateBuffer);
			const deletePayload = hasDeletes && this.createValidPayload(this._deleteBuffer);

			this.clearUpdateBuffer();
			this.clearDeleteBuffer();

			const updateResponse = hasUpdates ? await this.httpClient.update(updatePayload) : null;
			const deleteResponse = hasDeletes ? await this.httpClient.delete(deletePayload) : null;

			let response: UserFavoritesResponseModel;

			if (deleteResponse) {
				response = deleteResponse;
			} else if (updateResponse) {
				response = updateResponse;
			}

			runInAction(() => {
				if (this.isBufferEmpty(this._updateBuffer) && this.isBufferEmpty(this._deleteBuffer)) {
					this.setUserFavorites(response);
				} else {
					this.handleBuffer();
				}
			});

		} catch (error) {
			await this.getUserFavorites();

			this.onError("FAVORITES.UPDATE_ERROR", error);
		}
	}

	public isUserFavoriteEvent(eventId: string) {
		return this.userFavoriteEventsSet.has(eventId);
	}

	public isUserFavoriteTournament(tournamentId: string) {
		return this.userFavoriteTournamentsSet.has(tournamentId);
	}

	public isUserFavoriteBettingType(bettingTypeId: string, specifiers: any) {
		const existingBettingTypeSpecifiers = this.userFavoriteBettingTypesMap.get(bettingTypeId);

		if (existingBettingTypeSpecifiers === undefined) {
			return false;
		}

		if (existingBettingTypeSpecifiers === null) {
			return true;
		}

		return existingBettingTypeSpecifiers.some((existingSpecifiers) => this.hasSameSpecifiers(existingSpecifiers, specifiers));

	}

	private isBufferEmpty(buffer: UserFavoritesModel) {
		return (buffer.userFavoriteBettingTypes.length < 1 &&
			buffer.userFavoriteEvents.size < 1 &&
			buffer.userFavoritePlayers.size < 1 &&
			buffer.userFavoriteTeams.size < 1 &&
			buffer.userFavoriteTournaments.size < 1);
	}

	private clearUpdateBuffer() {
		this._updateBuffer = {
			userFavoriteBettingTypes: [],
			userFavoriteEvents: new Set(),
			userFavoritePlayers: new Set(),
			userFavoriteTeams: new Set(),
			userFavoriteTournaments: new Set()
		}
	}

	private clearDeleteBuffer() {
		this._deleteBuffer = {
			userFavoriteBettingTypes: [],
			userFavoriteEvents: new Set(),
			userFavoritePlayers: new Set(),
			userFavoriteTeams: new Set(),
			userFavoriteTournaments: new Set()
		}
	}

	private createValidPayload(buffer: UserFavoritesModel) {
		return <UserFavoritesResponseModel>{
			userFavoriteBettingTypes: buffer.userFavoriteBettingTypes,
			userFavoriteEvents: [...buffer.userFavoriteEvents],
			userFavoritePlayers: [...buffer.userFavoritePlayers],
			userFavoriteTeams: [...buffer.userFavoriteTeams],
			userFavoriteTournaments: [...buffer.userFavoriteTournaments],
		}
	}

	private async onError(errorMessage: string, originalError: unknown) {
		if (this.notificationProvider && this.notificationProvider.onError) {
			this.notificationProvider.onError(errorMessage, originalError);
			return;
		}

		throw new Error(errorMessage);

	}

	public reset() {
		this.userFavoriteTeamsSet.clear();
		this.userFavoriteEventsSet.clear();
		this.userFavoritePlayersSet.clear();
		this.userFavoriteTournamentsSet.clear();
		this.userFavoriteBettingTypesMap.clear();
	}

	addUserFavoritePlayer(playerId: string) {
		throw 'Function not implemented!'
	}

	removeUserFavoritePlayer(playerId: string) {
		throw 'Function not implemented!'
	}

	addUserFavoriteTeam(teamId: string) {
		throw 'Function not implemented!'
	}

	removeUserFavoriteTeam(teamId: string) {
		throw 'Function not implemented!'
	}

	setResortCallback(callback: Function) {
		this.resortCallback = callback;
	}

	//#endregion "methods"
}

//#region "decorate"

decorate(FavoritesStore, {
	userFavoriteTeamsSet: observable,
	userFavoriteEventsSet: observable,
	userFavoritePlayersSet: observable,
	userFavoriteTournamentsSet: observable,
	userFavoriteBettingTypesMap: observable,
	addUserFavoriteEvent: action.bound,
	removeUserFavoriteEvent: action.bound,
	addUserFavoriteTournament: action.bound,
	removeUserFavoriteTournament: action.bound,
	addUserFavoriteBettingType: action.bound,
	removeUserFavoriteBettingType: action.bound,
	addUserFavoritePlayer: action.bound,
	removeUserFavoritePlayer: action.bound,
	addUserFavoriteTeam: action.bound,
	removeUserFavoriteTeam: action.bound,
	setResortCallback: action.bound,
	reset: action.bound,
});

//#endregion "decorate"

export {
	FavoritesStore
}