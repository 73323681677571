import { decorate, observable, action, computed } from 'mobx';

import {
	BettingOfferKeyUpdate,
	Event,
	EventDelete,
	EventKeyBettingOffer,
	EventUpdate,
	IChange,
	Key,
	OfferSubscriptionResponse
} from '@gp/models';
import {
	insert,
	isOutrightComparer,
	Sorter,
} from '@gp/utility';

import { BaseOfferStore } from "../BaseOfferStore";

import { DefaultPrematchPageableConfiguration, IPrematchPageableOfferConfiguration } from "..";
import {
	EventKeyOffer,
	EventOffer,
	getEventSortByFavorite,
	getEventSortBySport,
	getEventSortBySportCategory,
	getEventSortByTournament,
	PrematchPageRowElement,
	SportStructureOffer
} from '../..';
import { manipulateOfferSubscriptionResponse } from '../../utility/manipulateOfferResponse'

class PrematchPageableOfferStore extends BaseOfferStore<IPrematchPageableOfferConfiguration> {
	configuration: IPrematchPageableOfferConfiguration;
	page: number = 1;

	/**
	 * Sorted events following this rules:
	 * 1. sort by sport
	 * 2. sort by sport category
	 * 3. sort by tournament
	 * 4. sort by outright
	 * 5. sort by start time
	 * 6. sort by id
	 */
	get sortedEvents(): Event[] {
		const events: Event[] = [];

		this.eventsMap.forEach(event => {
			insert(events, event, (a: EventOffer, b: EventOffer) => {
				return Sorter.sort(
					(a: EventOffer, b: EventOffer) => getEventSortBySport(a, b, this.lookupsStore),
					(a: EventOffer, b: EventOffer) => getEventSortBySportCategory(a, b, this.lookupsStore),
					(a: EventOffer, b: EventOffer) => getEventSortByTournament(a, b, this.lookupsStore),
					(a: EventOffer, b: EventOffer) => getEventSortByFavorite(a, b, this.configuration.userFavoritesStore),
					isOutrightComparer,
					'startTime',
					'id'
				)(a, b);
			});
		});

		return events;
	}

	get pageData(): PrematchPageRowElement[][] {
		// current page reference
		let pd: PrematchPageRowElement[] = [];

		// all pages
		const pages: PrematchPageRowElement[][] = [pd];

		let lastSportId: string;
		let lastCategoryId: string;
		let lastTournamentId: string;
		let isOutright: boolean = false;

		this.sortedEvents.forEach(event => {
			if (event.sportId !== lastSportId ||
				event.sportCategoryId !== lastCategoryId ||
				event.tournamentId !== lastTournamentId ||
				isOutright !== event.isOutright
			) {
				if ((pd.length + 2) > this.configuration.rowCount) {
					pd = [];
					pages.push(pd);
				}

				isOutright = event.isOutright;
				lastSportId = event.sportId;
				lastCategoryId = event.sportCategoryId;
				lastTournamentId = event.tournamentId;

				pd.push(PrematchPageRowElement.create(event.sportId, 'header', 1, event.sportCategoryId, event.tournamentId, isOutright));
			}
			else if ((pd.length + 1) > this.configuration.rowCount) {
				pd = [];
				pages.push(pd);
				pd.push(PrematchPageRowElement.create(event.sportId, 'header', 1, event.sportCategoryId, event.tournamentId, isOutright));
			}

			pd.push(PrematchPageRowElement.create(event.id, 'event', undefined, undefined, undefined, event.isOutright));
		});

		return pages;
	}

	get preSortedPageData(): PrematchPageRowElement[][] {
		// current page reference
		let pd: PrematchPageRowElement[] = [];

		// all pages
		const pages: PrematchPageRowElement[][] = [pd];

		let lastSportId: string;
		let lastCategoryId: string;
		let lastTournamentId: string;
		let isOutright: boolean = false;

		Array.from(this.eventsMap.values()).sort((a, b) => {
			if (a.tournamentId === b.tournamentId) {
				return getEventSortByFavorite(a, b, this.configuration.userFavoritesStore);
			}

			return 0;
		}).forEach(event => {
			if (event.sportId !== lastSportId ||
				event.sportCategoryId !== lastCategoryId ||
				event.tournamentId !== lastTournamentId ||
				isOutright !== event.isOutright
			) {
				if ((pd.length + 2) > this.configuration.rowCount) {
					pd = [];
					pages.push(pd);
				}

				isOutright = event.isOutright;
				lastSportId = event.sportId;
				lastCategoryId = event.sportCategoryId;
				lastTournamentId = event.tournamentId;

				pd.push(PrematchPageRowElement.create(event.sportId, 'header', 1, event.sportCategoryId, event.tournamentId, isOutright));
			}
			else if ((pd.length + 1) > this.configuration.rowCount) {
				pd = [];
				pages.push(pd);
				pd.push(PrematchPageRowElement.create(event.sportId, 'header', 1, event.sportCategoryId, event.tournamentId, isOutright));
			}

			pd.push(PrematchPageRowElement.create(event.id, 'event', undefined, undefined, undefined, event.isOutright));
		});

		return pages;
	}

	/**
	* Gets events in sport structure
	* Sport -> Category -> Tournament -> Events
	*/
	get eventsInSportStructure(): SportStructureOffer {
		const result = new SportStructureOffer();
		result.eventCount = this.eventsMap.size;

		this.eventsMap.forEach((event, eventId) => {
			const existingSport = result.sports.find(s => s.id === event.sportId);

			if (existingSport != null) {
				const existingCategory = existingSport.categories?.find(c => c.id === event.sportCategoryId);

				if (existingCategory) {
					const existingTournament = existingCategory.tournaments.find(t => t.id === event.tournamentId && t.isOutright === event.isOutright && t.isLive === event.isLive);

					if (existingTournament) {
						existingTournament.addEventOffer(new EventOffer(event, this.configuration.display));
						existingSport.eventCount++;
					}
					else {
						// tournament was not found in the current category offer which means we didn't map this tournament

						const tournamentOffer = this.getMappedTournament(event);
						if (tournamentOffer == null) {
							// skip this iteration
							return;
						}

						existingCategory.addTournamentOffer(tournamentOffer);
						existingSport.eventCount++;
					}
				}
				else {
					// category was not found in the current sport offer which means we didn't map this category

					const categoryOffer = this.getMappedCategory(event);
					if (categoryOffer == null) {
						// skip this iteration
						return;
					}

					existingSport.addSportCategoryOffer(categoryOffer);
					existingSport.eventCount++;
				}
			}
			else {
				// sport was not found in the current offer which means we didn't map this sport structure.

				const sportOffer = this.getMappedSport(event);
				if (sportOffer == null) {
					// skip this iteration
					return;
				}

				result.addSportOffer(sportOffer);
			}
		});

		return result;
	}

	constructor(configuration?: IPrematchPageableOfferConfiguration) {
		super(Object.assign({}, new DefaultPrematchPageableConfiguration(), configuration));
	}

	changePage(newPage: number) {
		if (this.page !== newPage) {
			this.page = newPage;
		}
	}

	assignOfferData(data: OfferSubscriptionResponse) {
		data = manipulateOfferSubscriptionResponse(data, this);
		this.processMessage(data);
	}

	processMessage(data: OfferSubscriptionResponse) {
		if (data == null) {
			return;
		}

		const {
			lookups,
			offerChanges,
			version
		} = data;

		// we always reset store before mapping new offer
		this.reset();

		if (lookups != null && !lookups.isEmpty) {
			this.lookupsStore.update(lookups);
		}
		else {
			this.logger.logError("Did not receive lookups");
			// since we did not receive lookups we can't process offer any further
			return;
		}

		if (offerChanges != null) {
			this.processOfferChanges(offerChanges);
		}
		else {
			this.logger.logWarn("Received empty offer");
		}

		this.version = version;
	}

	processOfferChanges(offerChanges: IChange<EventUpdate, EventDelete>) {
		if (offerChanges.updates != null && offerChanges.updates.length > 0) {
			this.processEvents(offerChanges.updates);
		}
	}

	processEvents(updates: EventUpdate[]) {
		updates.forEach(eventUpdate => {
			if (eventUpdate.event != null) {
				this.insertEvent(eventUpdate.event);

				if (eventUpdate.offers != null && eventUpdate.offers.updates != null && eventUpdate.offers.updates.length > 0) {
					this.processEventKeys(eventUpdate.eventId, eventUpdate.offers.updates);
				}
				else {
					this.logger.logInfo(`Event ${eventUpdate.eventId} has no offer`);
				}
			}
			else {
				// we have no event data thus we don't want to map offer for this event
				this.logger.logWarn(`Did not receive event data for event with id: ${eventUpdate.eventId}. Skip!`);
				return;
			}
		});
	}

	insertEvent(event: Event) {
		const newEvent = this.createNewEvent(event);

		this.eventsMap.set(event.id, newEvent);
	}

	processEventKeys(eventId: string, keyUpdates: BettingOfferKeyUpdate[]) {
		keyUpdates.forEach(keyUpdate => {
			if (keyUpdate.key != null) {
				this.insertKey(eventId, keyUpdate.key);

				if (keyUpdate.offers != null && keyUpdate.offers.updates != null && keyUpdate.offers.updates.length > 0) {
					this.processKeyOffers(eventId, keyUpdate);
				}
			}
			else {
				// we have no key data thus we don't want to map it
				this.logger.logWarn(`Did not receive key data for key with id: ${keyUpdate.keyId}. Skip!`);
				return;
			}
		});
	}

	insertKey(eventId: string, key: Key) {
		const eventKeys = this.eventKeysMap.get(eventId) || new Map<string, EventKeyOffer>();
		const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

		if (keyBettingType == null) {
			throw `Can not find betting type ${key.bettingTypeId} in lookups store.`
		}

		const newKey = this.createNewKey(key, eventId, keyBettingType);
		eventKeys?.set(key.id, newKey);

		this.eventKeysMap.set(eventId, eventKeys);
	}

	processKeyOffers(eventId: string, key: BettingOfferKeyUpdate) {
		const keyOffers = this.keyOffersMap.get(key.keyId) || new Map<string, EventKeyBettingOffer>();
		const event = this.eventsMap.get(eventId);

		// check if all offers have same playerId, or if they have playerId at all
		let playerId: string | undefined = undefined;
		let teamIds: { teamOneId: string | undefined, teamTwoId: string | undefined } | undefined = undefined;

		if (key.offers?.updates?.every((o, i, src) => o.playerId != null && o.playerId !== '' && o.playerId === src[0].playerId)) {
			playerId = key.offers?.updates[0].playerId;
		}

		if (!event?.isOutright) {
			teamIds = {
				teamOneId: event?.teamOneId,
				teamTwoId: event?.teamTwoId,
			}
		}

		key.offers?.updates?.forEach(o => {
			const tipInfo = this.getTipInformation({
				defaultTip: o.tip,
				bettingTypeId: key.bettingTypeId,
				playerId: o.playerId,
				teamId: o.teamId,
				specifiers: {
					...key.key?.specifier,
					...o.specifier
				},
				eventData: {
					name: event?.name,
					playerId: playerId,
					teamIds: teamIds
				}
			});

			keyOffers?.set(o.id, {
				...o,
				keyId: key.keyId,
				eventId: eventId,
				isLive: false,
				isLocked: !!o.isLocked,
				tip: tipInfo.tip,
				displayTip: tipInfo.displayTip,
				gender: tipInfo.gender,
				indicator: 0
			});
		});

		this.keyOffersMap.set(key.keyId, keyOffers);
	}
}

decorate(PrematchPageableOfferStore, {
	page: observable,
	sortedEvents: computed,
	pageData: computed,
	eventsInSportStructure: computed,
	assignOfferData: action,
	processMessage: action,
	processOfferChanges: action,
	processEvents: action,
	insertEvent: action,
	processEventKeys: action,
	insertKey: action,
	processKeyOffers: action,
});

export {
	PrematchPageableOfferStore
}
