import { action, computed, decorate, observable } from 'mobx';

import { insert, isLiveComparer, isOutrightComparer, Sorter } from '@gp/utility';

// import { insertBySortOrder } from "../utility/array";
import { SportOffer } from "./SportOffer";
class SportStructureOffer {
	/**
	 * Number of events currently in the offer.
	 */
	eventCount: number = 0;

	sports: SportOffer[] = [];

	get isEmpty() {
		return this.sports.length === 0;
	}

	/**
	 * Adds new sport with offer at first position
	 * @param sportOffer sport with offer
	 * @param prepend if true inserts sport offer element at first place, otherwise inserts by sport sort order value. Default is false
	 */
	addSportOffer(sportOffer: SportOffer, prepend: boolean = false) {
		if (prepend) {
			this.sports.unshift(sportOffer);
		}
		else {
			// insertBySortOrder<SportOffer>(this.sports, sportOffer);
			insert(this.sports, sportOffer, Sorter.sort<SportOffer>('sortOrder', isOutrightComparer, isLiveComparer, 'id'));
		}
	}

	/**
	 * Removes sport offer if it exists
	 * @param sportOffer sport offer to remove
	 */
	removeSportOffer(sportOffer: SportOffer) {
		const soIdx = this.sports.findIndex(so => so.id === sportOffer.id && so.isLive == sportOffer.isLive && so.isOutright == sportOffer.isOutright);

		if (soIdx > -1) {
			this.sports.splice(soIdx, 1);
		}
	}

	/**
	 * Updates event count
	 */
	updateEventCount(count: number) {
		if (this.eventCount !== count) {
			this.eventCount = count;
		}
	}
}

decorate(SportStructureOffer, {
	eventCount: observable,
	sports: observable.shallow,
	isEmpty: computed,
	addSportOffer: action,
	updateEventCount: action,
});

export {
	SportStructureOffer
}