import { EventOffer } from "../models";
import { FavoritesStore } from "../stores";

export function getEventSortByFavorite(a: EventOffer, b: EventOffer, favoritesStore: FavoritesStore) {
    const aFavorite = favoritesStore.userFavoriteEventsSet.has(a.id);
    const bFavorite = favoritesStore.userFavoriteEventsSet.has(b.id);

    if(aFavorite && bFavorite){
        return 0;
    }

    if(aFavorite){
        return -1;
    }

    if(bFavorite){
        return 1;
    }

    return 0;
}