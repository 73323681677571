import {
	createThreeWay,
	createDoubleChance,
	createOverUnder,
	createHandicap,
	createWillBothTeamsScore,
	createDrawNoBet,
	createNextGoal,
	createWinner,
	createMainTip,
	createSetWinner,
	createGameWinner,
	createOverUnderGamesInTheMatch,
	createAsianHandicap,
	createMatchGameHandicap,
	createTotalPoints,
	createPointHandicap,
	createMost180s,
	createTotal180s,
	createFirstHalfDrawNoBet,
	createFirstHalfHandicap,
	createFirstHalfTotals,
	createHeadToHead,
	createFrameHandicap,
	createTotalFrames,
	createFirstHalfWinner,
	createSetHandicap,
	createInningWinners,
	createFirstHalfNextGoal,
	createRestOfFirstHalfWinner,
	createPeriodTotals,
	createSetTotalGames,
	createWinnerThreeWay,
	createWhoWinsPeriod,
	createThreeWayWinner,
	createMainTipBasketballPrematch,
} from "../../groups";

import { Rule } from "../../../models/rules";

export const soccer = new Rule(
	[
		"soccer"
	],
	[
		createThreeWay(1),
		createDoubleChance(2),
		createOverUnder(3),
		createNextGoal(4),
		createHandicap(5),
		createWillBothTeamsScore(6),
		createDrawNoBet(7),
		createFirstHalfWinner(8),
		createRestOfFirstHalfWinner(9),
		createFirstHalfNextGoal(10),
		createFirstHalfTotals(11),
		createFirstHalfHandicap(12),
	]
);

export const iceHockey = new Rule(
	[
		'ice-hockey'
	],
	[
		createThreeWay(1),
		createDoubleChance(2),
		createOverUnder(3),
		createHandicap(4),
		createPeriodTotals(5),
		createWhoWinsPeriod(6),
	]
);

export const bandyFloorball = new Rule(
	[
		'bandy',
		'floorball'
	],
	[
		createThreeWay(1),
		createOverUnder(2)
	]
);

export const tennis = new Rule(
	[
		'tennis'
	],
	[
		createWinner(1),
		createSetWinner(2),
		createMatchGameHandicap(3),
		createOverUnderGamesInTheMatch(4),
		createSetHandicap(5),
		createSetTotalGames(6),
	]
);

export const tableTennis = new Rule(
	[
		'table-tennis'
	],
	[
		createWinner(1),
		createTotalPoints(2),
		createPointHandicap(3)
	]
);

export const darts = new Rule(
	[
		'darts'
	],
	[
		createWinner(1),
		createMost180s(2),
		createTotal180s(3),
		createPointHandicap(4)
	]
);

export const volleyball = new Rule(
	[
		'volleyball',
	],
	[
		createWinner(1),
		createSetWinner(2),
		createTotalPoints(3),
		createPointHandicap(4),
		createFirstHalfTotals(5),
	]
);

export const beachVolley = new Rule(
	[
		'beach-volley'
	],
	[
		createWinner(1),
		createSetWinner(2),
		createTotalPoints(3),
		createPointHandicap(4)
	]
);

export const basketball = new Rule(
	[
		'basketball'
	],
	[
		createMainTipBasketballPrematch(1),
		createAsianHandicap(2),
		createOverUnder(3),
		createFirstHalfHandicap(4),
		createFirstHalfDrawNoBet(5),
		createFirstHalfTotals(6)
	]
);

export const aussieRules = new Rule(
	[
		'aussie-rules'
	],
	[
		createWinner(1),
		createAsianHandicap(2),
		createOverUnder(3),
		createDrawNoBet(4)
	]
);

export const handball = new Rule(
	[
		'handball'
	],
	[
		createThreeWay(1),
		createDoubleChance(2),
		createOverUnder(3),
		createAsianHandicap(4),
		createFirstHalfWinner(5),
	]
);

export const baseball = new Rule(
	[
		'baseball'
	],
	[
		createWinner(1),
		createOverUnder(2),
		createAsianHandicap(3),
		createInningWinners(4),
	]
);

export const boxingMma = new Rule(
	[
		'boxing',
		'mma'
	],
	[
		createWinner(1)
	]
);

export const cricket = new Rule(
	[
		'cricket'
	],
	[
		createWinnerThreeWay(1)
	]
);

export const motosports = new Rule(
	[
		"formula-1",
		"ski-jumping",
		"motorcycle-racing",
		"indy-racing",
		"motorsport",
		"stock-car-racing",
		"touring-car-racing"
	],
	[
		createHeadToHead(1)
	]
);

export const americanFootball = new Rule(
	[
		'american-football'
	],
	[
		createWinner(1),
		createOverUnder(2),
		createAsianHandicap(3),
		createFirstHalfWinner(4),
	]
);

export const futsal = new Rule(
	[
		'futsal'
	],
	[
		createThreeWay(1),
		createOverUnder(2)
	]
);

export const waterpolo = new Rule(
	[
		'waterpolo'
	],
	[
		createThreeWay(1),
		createOverUnder(2)
	]
);

export const alpineSkiing = new Rule(
	[
		'alpine-skiing'
	],
	[
		createWinner(1),
	]
);

export const snooker = new Rule(
	[
		'snooker'
	],
	[
		createThreeWayWinner(1),
		createTotalFrames(2),
		createFrameHandicap(3),
	]
);

export const rugby = new Rule(
	[
		'rugby'
	],
	[
		createMainTip(1),
		createOverUnder(2),
		createAsianHandicap(3),
		createFirstHalfWinner(4),
	]
);

export const misc = new Rule(
	[
		"biathlon",
		"bobsleigh",
		"golf",
		"cross-country",
		"figure-skating",
		"freestyle-skiing",
		"luge",
		"nordic-combined",
		"short-track",
		"skeleton",
		"speed-skating",
		"snowboard",
		"cycling",
		"badminton",
		"bowls",
		"squash"
	],
	[
		createHeadToHead(1)
	]
);


export const defaultRule = new Rule(
	[],
	[
		createMainTip(1),
		createDoubleChance(2),
		createOverUnder(3),
		createSetWinner(4),
		createGameWinner(5),
		createOverUnderGamesInTheMatch(6),
		createHandicap(7),
		createAsianHandicap(8)
	],
	// default
	true
)