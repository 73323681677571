import { Tournament } from '@gp/models';
import { insert, Sorter } from '@gp/utility';

/**
 * Inserts element in array by sort order property
 * @param array source
 * @param element element
 * @returns array with element inserted ad position defined by sort order property
 */
export function insertBySortOrder<T>(array: T[], element: T) {
	if (element == null) {
		return array;
	}

	return insert(array, element, Sorter.sort('sortOrder', 'id'));
}

/**
 * Inserts element in array by sort order and live property
 * @param array source
 * @param element element
 * @returns 
 */
export function insertByLiveAndSortOrder<T extends Tournament>(array: T[], element: T) {
	if (element == null) {
		return array;
	}

	return insert(array, element, Sorter.sort('isLive', 'sortOrder', 'id'));
}