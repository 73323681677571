import { Category } from '@gp/models';
import { action, decorate, observable } from 'mobx';
import { insertBySortOrder } from '../utility/array';
import { TournamentOffer } from './TournamentOffer';

class SportCategoryOffer extends Category {
	tournaments: TournamentOffer[];

	constructor(category: Category) {
		super();

		this.id = category.id;
		this.name = category.name;
		this.abrv = category.abrv;
		this.sortOrder = category.sortOrder;
		this.sportId = category.sportId;

		this.tournaments = [];
	}

	/**
	 * Adds tournament with offer at first position
	 * @param tournamentOffer tournament with offer
	 * @param prepend if true inserts tournament offer element at first place, otherwise inserts by tournament sort order value. Default is false
	 */
	addTournamentOffer(tournamentOffer: TournamentOffer, prepend: boolean = false) {
		if (prepend) {
			this.tournaments.unshift(tournamentOffer);
		}
		else {
			insertBySortOrder(this.tournaments, tournamentOffer);
		}
	}

	/**
	 * Removes tournament with offer
	 * @param tournamentId tournament offer to remove
	 */
	removeTournamentOffer(tournamentId: string) {
		const tIdx = this.tournaments.findIndex(to => to.id === tournamentId);
		if (tIdx > -1) {
			this.tournaments.splice(tIdx, 1);
		}
	}
}

decorate(SportCategoryOffer, {
	tournaments: observable.shallow,
	addTournamentOffer: action.bound,
	removeTournamentOffer: action.bound,
});

export {
	SportCategoryOffer
}