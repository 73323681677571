import { decorate, computed, observable, action, runInAction, IObservableArray } from "mobx";

import {
	BettingOffer,
	BettingOfferKeyDelete,
	BettingOfferKeyUpdate,
	BettingTypeGroup,
	Category,
	Event,
	EventDelete,
	EventKeyBettingOffer,
	EventUpdate,
	IChange,
	Key,
	OfferSubscriptionResponse,
	SpecifierType,
	Sport,
	Team,
	Tournament,
} from '@gp/models'
import { getIntersection, insert, secondsToMilliseconds, NBATeamNameComparer } from '@gp/utility';

import { MainOfferStore } from "../MainOfferStore";
import { manipulateOfferSubscriptionResponse } from '../../utility/manipulateOfferResponse'


import { DefaultAdditionalPageableConfiguration, IAdditionalPageableOfferConfiguration } from "..";
import { EventKeyOffer, EventOffer, PageRowElement } from '../..';
import type { RowType, RowSpan } from '../..';
import { AdditionalOfferComparisonStore } from "./AdditionalOfferComparisonStore";

const ALL_BETTING_TYPE_GROUP: BettingTypeGroup = {
	name: "BETTING_TYPE_GROUPS.ALL",
	abrv: "all",
	sortOrder: -2,
	id: "all"
};

const FAVORITE_BETTING_TYPE_GROUP: BettingTypeGroup = {
	name: "BETTING_TYPE_GROUPS.FAVORITES",
	abrv: "favorites",
	sortOrder: -1,
	id: "favorites"
}

class KeyPageRowElement extends PageRowElement {
	offerIds?: string[];

	constructor(id: string, rowType: RowType, rowSpan: RowSpan = 1, offerIds: string[] | undefined = undefined) {
		super(id, rowType, rowSpan);

		this.offerIds = offerIds;
	}

	/**
	 * Creates row
	 * @param id row id
	 * @param rowType row type
	 * @param rowSpan row span
	 * @param offerIds offer ids for this row
	 */
	static create(id: string, rowType: RowType, rowSpan: RowSpan = 1, offerIds: string[] | undefined = undefined): KeyPageRowElement {
		return new KeyPageRowElement(id, rowType, rowSpan, offerIds);
	}
}

const NumericCollator = Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

class AdditionalPageableOfferStore extends MainOfferStore {
	configuration: IAdditionalPageableOfferConfiguration;
	comparisonOfferStore: AdditionalOfferComparisonStore;

	pageNumber: number = 1;

	event: EventOffer | null;

	/**
	 * Current keys in the offer
	 */
	keys: IObservableArray<EventKeyOffer> = [] as IObservableArray<EventKeyOffer>;

	shouldRecalculatePages: boolean = false;
	pageData: KeyPageRowElement[][] = [];
	selectedBettingTypeGroup: BettingTypeGroup = ALL_BETTING_TYPE_GROUP;

	get availableBettingTypeGroups(): BettingTypeGroup[] {
		return [...this.lookupsStore.bettingTypeGroups.values()].sort((a, b) => a.sortOrder - b.sortOrder);
	}

	/** Current event sport. */
	get sport(): Sport | undefined {
		if (this.event == null) {
			return undefined;
		}
		return this.lookupsStore.sports.get(this.event.sportId);
	}

	/** Current event category. */
	get sportCategory(): Category | undefined {
		if (this.event == null) {
			return undefined;
		}
		return this.lookupsStore.categories.get(this.event.sportCategoryId);
	}

	/** Current event tournament. */
	get tournament(): Tournament | undefined {
		if (this.event == null) {
			return undefined;
		}
		return this.lookupsStore.tournaments.get(this.event.tournamentId);
	}

	get teamOne(): Team | undefined {
		if (this.event && this.event.displayValues && this.event.displayValues.teamOneId) {
			return this.lookupsStore.teams.get(this.event.displayValues.teamOneId);
		}
		return undefined;
	}

	get teamTwo(): Team | undefined {
		if (this.event && this.event.displayValues && this.event.displayValues.teamTwoId) {
			return this.lookupsStore.teams.get(this.event.displayValues.teamTwoId);
		}
		return undefined;
	}

	constructor(configuration?: IAdditionalPageableOfferConfiguration) {
		super(Object.assign({}, new DefaultAdditionalPageableConfiguration(), configuration));

		this.comparisonOfferStore = new AdditionalOfferComparisonStore(this);
	}

	/**
	 * Change page
	 * @param newPage 
	 */
	changePage(newPage: number) {
		if (newPage > 0 && newPage !== this.pageNumber) {
			this.pageNumber = newPage;
		}
	}

	assignOfferData(data: OfferSubscriptionResponse) {
		data = manipulateOfferSubscriptionResponse(data, this);
		// clear comparison offer store before any new mapping
		this.comparisonOfferStore.clear();

		if (!this.configuration.enableThrottling) {
			this.processMessage(data);

			// reset offer indicators
			setTimeout(() => {
				runInAction(() => {
					Array.from(this.keyOffersMap.values()).map(ko => Array.from(ko.values())).flat().filter(o => o.indicator !== 0).forEach(o => o.indicator = 0);
				});
			}, this.configuration.indicatorDuration || 3500);

			this.initialized = true;

			return;
		}

		if (data.startingVersion === 0) {
			// clear buffer first
			clearTimeout(this._bufferTimeoutId);
			this._bufferTimeoutId = undefined;
			this._buffer = [];

			// process message
			this.processMessage(data);

			this.initialized = true;
			return;
		}

		this._buffer.push(data);

		if (this._bufferTimeoutId == null) {
			this._bufferTimeoutId = window.setTimeout(() => runInAction(() => {
				this._buffer.forEach(r => this.processMessage(r));

				// reset offer indicators
				setTimeout(() => {
					runInAction(() => {
						Array.from(this.keyOffersMap.values()).map(ko => Array.from(ko.values())).flat().filter(o => o.indicator !== 0).forEach(o => o.indicator = 0);
					});
				}, this.configuration.indicatorDuration || 3500);

				this._buffer = [];
				this._bufferTimeoutId = undefined;
			}), secondsToMilliseconds(this.configuration.throttle));
		}
	}

	processMessage(data: OfferSubscriptionResponse) {
		if (data == null) {
			return;
		}

		const {
			lookups,
			offerChanges,
			startingVersion,
			version
		} = data;

		if (startingVersion === 0) {
			this.logger.logWarn(`Offer reset. Previous version: ${version}`);
			this.reset();
		}

		// we've received older version than we currently have - ignore it
		if (version < this.version) {
			this.logger.logWarn(`Received older offer - skipping. Received: ${version} | Current: ${this.version}`)
			return;
		}

		if (lookups != null && !lookups.isEmpty) {
			this.lookupsStore.update(lookups);
		}

		if (offerChanges != null) {
			this.processOfferChanges(offerChanges);

			if (this.shouldRecalculatePages || startingVersion === 0) {
				this.updatePageData();
			}
		}
		else {
			this.logger.logWarn("Received empty offer.");
		}

		// update version to new value after mapping is done.
		this.version = version;
	}

	processOfferChanges(offerChanges: IChange<EventUpdate, EventDelete>) {
		if (offerChanges.updates != null && offerChanges.updates.length > 0) {
			this.processEventUpdate(offerChanges.updates[0]);
		}

		if (offerChanges.deletes != null && offerChanges.deletes.length > 0) {
			this.processEventDelete(offerChanges.deletes[0]);
		}
	}

	/**
	 * Update events. Returns true if event was updated
	 * @param updates event updates/deletes
	 */
	processEventUpdate(eventUpdate: EventUpdate) {
		this.updateEvent(eventUpdate.event);

		if (eventUpdate.offers != null) {
			if (eventUpdate.offers.updates != null) {
				this.processKeyUpdates(eventUpdate.eventId, eventUpdate.offers.updates);
			}
			if (eventUpdate.offers.deletes != null) {
				this.processKeyDeletes(eventUpdate.eventId, eventUpdate.offers.deletes);
			}
		}
	}

	/**
	 * Updates event and removes event offer
	 * @param eventDelete event data
	 */
	processEventDelete(eventDelete: EventDelete) {
		this.updateEvent(eventDelete);

		this.keyOffersMap.clear();

		// clear page data since it contains all the keys
		this.pageData = [];

		this.shouldRecalculatePages = true;
	}

	/**
	 * Updates event data
	 * @param event Event to update
	 */
	updateEvent(event: Event) {
		let mappedEvent = this.eventsMap.get(event.id);

		if (mappedEvent != null) {
			mappedEvent.update(event);
		}
		else {
			mappedEvent = new EventOffer(event, this.configuration.display);
			this.eventsMap.set(event.id, mappedEvent);
		}

		this.event = mappedEvent;
	}

	/**
	 * Updates event offer keys
	 * @param eventId event id
	 * @param keyUpdates keys to update
	 */
	processKeyUpdates(eventId: string, keyUpdates: BettingOfferKeyUpdate[]) {
		keyUpdates.forEach(keyUpdate => {
			if (keyUpdate.key != null) {
				this.updateKey(eventId, keyUpdate.key);
			}

			// add current key offers state in memory for indicator comparison
			this.comparisonOfferStore.add(eventId, keyUpdate.keyId);

			// update offer
			if (keyUpdate.offers != null) {
				if (keyUpdate.offers.updates) {
					this.processOfferUpdates(eventId, keyUpdate);
				}
				if (keyUpdate.offers.deletes) {
					this.processOfferDeletes(keyUpdate.keyId, keyUpdate.offers.deletes);
				}
			}
		});
	}

	/**
	 * Updates key data
	 * @param eventId event id
	 * @param key Key to update
	 */
	updateKey(eventId: string, key: Key) {
		const currentKey = this.keys.find(k => k.id === key.id);

		if (currentKey == null) {
			const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);
			if (keyBettingType == null) {
				throw `Could not find betting type lookup for ${key.bettingTypeId} betting type id.`
			}

			const newKey = this.createNewKey(key, eventId, keyBettingType);

			insert(this.keys, newKey, (a, b) => {
				let aSort = 99999;
				if (this.sport != null && a.bettingType.settingsPerSport != null && a.bettingType.settingsPerSport[this.sport.id] != null) {
					aSort = a.bettingType.settingsPerSport[this.sport.id].sortOrder;
				}
				let bSort = 99999;
				if (this.sport != null && b.bettingType.settingsPerSport != null && b.bettingType.settingsPerSport[this.sport.id] != null) {
					bSort = b.bettingType.settingsPerSport[this.sport.id].sortOrder;
				}

				if (aSort < bSort) return -1;
				if (aSort > bSort) return 1;

				if (a.bettingType.abrv < b.bettingType.abrv) return -1;
				if (a.bettingType.abrv > b.bettingType.abrv) return 1;

				const eventOffer = this.eventsMap.get(a.eventId)
				if (eventOffer) {
					const comparerResult = NBATeamNameComparer(a, b, eventOffer, this.lookupsStore.teams);
					if (comparerResult != 0) return comparerResult;
				}

				// then sort by specifier value (if any)
				if (a.specifier != null && b.specifier != null) {
					// get valid specifier
					const matchingSpecifiers = getIntersection(Object.keys(a.specifier), Object.keys(b.specifier));

					// no matching specifiers found!
					if (matchingSpecifiers.length === 0) {
						return 0;
					}

					return matchingSpecifiers.reduce((acc, ms) => {
						// This function does accept undefined and null, may produce unexpected result.
						return NumericCollator.compare(a.specifier?.[ms] as string, b.specifier?.[ms] as string);
					}, 0) as any;
				}

				return 0;
			});

			// new key was added, we need to recalculate pages
			this.shouldRecalculatePages ||= true;
		}
		else {
			currentKey.update(key);
		}
	}

	/**
	 * Deletes event offer keys
	 * @param keyDeletes keys to delete
	 */
	processKeyDeletes(eventId: string, keyDeletes: BettingOfferKeyDelete[]) {
		keyDeletes.forEach(key => {
			this.keyOffersMap.delete(key.id);

			const idx = this.keys.findIndex(k => k.id === key.id);
			if (idx !== -1) {
				this.keys.splice(idx, 1);
			}

			this.shouldRecalculatePages ||= true;
		});
	}

	/**
	 * Deletes offers
	 * @param keyId key id
	 * @param offers offers to delete
	 */
	processOfferDeletes(keyId: string, offers: BettingOffer[]): void {
		if (!this.keyOffersMap.has(keyId)) {
			this.logger.logTrace('Offer already removed');
			return;
		};

		const keyOffers = this.keyOffersMap.get(keyId);

		offers.forEach(o => {
			// TODO: optimize this so it checks if the deleted tip breaks 3 element row restriction
			this.shouldRecalculatePages ||= (keyOffers?.delete(o.id) || false);
		});
	}

	/**
	 * Updates offers
	 * @param eventId event id
	 * @param keyId key id
	 * @param offers offers to update
	 */
	// processOfferUpdates(eventId: string, keyId: string, offers: BettingOffer[]) {
	processOfferUpdates(eventId: string, key: BettingOfferKeyUpdate) {
		if (!this.keyOffersMap.has(key.keyId)) {
			this.keyOffersMap.set(key.keyId, new Map<string, EventKeyBettingOffer>());
		}
		const keyOffers = this.keyOffersMap.get(key.keyId);

		const event = this.eventsMap.get(eventId);

		// check if all offers have same playerId, or if they have playerId at all
		let playerId: string | undefined = undefined;
		let teamIds: { teamOneId: string | undefined, teamTwoId: string | undefined } | undefined = undefined;

		if (key.offers?.updates?.every((o, i, src) => o.playerId != null && o.playerId !== '' && o.playerId === src[0].playerId)) {
			playerId = key.offers.updates[0].playerId;
		}

		if (!event?.isOutright) {
			teamIds = {
				teamOneId: event?.teamOneId,
				teamTwoId: event?.teamTwoId,
			}
		}


		key.offers?.updates?.forEach(o => {
			const tipInfo = this.getTipInformation({
				defaultTip: o.tip,
				bettingTypeId: key.bettingTypeId,
				playerId: o.playerId,
				teamId: o.teamId,
				specifiers: {
					...key.key?.specifier,
					...o.specifier
				},
				eventData: {
					name: event?.name,
					playerId: playerId,
					teamIds: teamIds
				}
			});

			const existingOffer = keyOffers?.get(o.id);
			if (existingOffer != null) {
				const comparableValue = this.comparisonOfferStore.getValue(existingOffer.id);

				// calculate indicator
				if (comparableValue === undefined) {
					existingOffer.indicator = 0;
				}
				else if (o.value > comparableValue) {
					existingOffer.indicator = 1;
				}
				else if (o.value < comparableValue) {
					existingOffer.indicator = -1;
				}
				else {
					existingOffer.indicator = 0;
				}

				existingOffer.isLive = !!event?.isLive;
				existingOffer.isLocked = !!o.isLocked
				existingOffer.value = o.value;

				existingOffer.tip = tipInfo.tip;
				existingOffer.displayTip = tipInfo.displayTip;
				existingOffer.gender = tipInfo.gender;
			}
			else {
				keyOffers?.set(o.id, {
					...o,
					keyId: key.keyId,
					eventId: eventId,
					isLive: !!event?.isLive,
					isLocked: !!o.isLocked,
					tip: tipInfo.tip,
					displayTip: tipInfo.displayTip,
					gender: tipInfo.gender,
					indicator: 0
				});

				// TODO: optimize this so it check if the added tip breaks the 3 element row restriction
				this.shouldRecalculatePages ||= true;
			}
		});
	}

	setEventId(eventId: string) {
		this.configuration.eventId = eventId;
	}

	setSelectedBettingTypeGroup(group: BettingTypeGroup) {
		this.selectedBettingTypeGroup = group;

		this.updatePageData();
	}

	resetSelectedBettingTypeGroup() {
		this.setSelectedBettingTypeGroup(ALL_BETTING_TYPE_GROUP);
	}

	/**
	 * Reset state
	 */
	reset() {
		super.reset();

		this.event = null;
		this.keys.clear();
		this.pageNumber = 1;
		this.pageData = [];
	}

	private sortPlayersByTeams(a: EventKeyBettingOffer, b: EventKeyBettingOffer): -1 | 0 | 1 {
		const aIsHome = a.teamId ? a.teamId === this.teamOne?.id : null;
		const bIsHome = b.teamId ? b.teamId === this.teamOne?.id : null;

		if (aIsHome == null) {
			return 1;
		}
		if (bIsHome == null) {
			return -1;
		}

		if ((aIsHome && bIsHome) || (!aIsHome && !bIsHome)) {
			// same team, sort by value!
			const diff = a.value - b.value;
			if (diff === 0) {
				return 0;
			}
			else if (diff < 0) {
				return -1;
			}
			else {
				return 1;
			}
		}
		else {
			if (a.teamId === this.teamOne?.id) {
				return -1;
			}
			else {
				return 1;
			}
		}
	}

	private sortByTip(reference: string[], a: EventKeyBettingOffer, b: EventKeyBettingOffer): number {
		if (this.event?.isOutright) {
			if (a.value !== b.value) {
				return a.value - b.value;
			}
		};

		const idxA = reference.indexOf(a.tip.toLowerCase());
		const idxB = reference.indexOf(b.tip.toLowerCase());

		if (idxA < idxB) return -1;
		if (idxA > idxB) return 1;
		return 0;
	}

	updatePageData() {
		let pd: KeyPageRowElement[] = [];
		const pages: KeyPageRowElement[][] = [];

		let selectedGroupKeys: EventKeyOffer[] = [];

		if (this.selectedBettingTypeGroup.abrv === ALL_BETTING_TYPE_GROUP.id) {
			selectedGroupKeys = this.keys;
		} else if (this.selectedBettingTypeGroup.abrv === FAVORITE_BETTING_TYPE_GROUP.id) {
			selectedGroupKeys = this.keys.filter(key => this.configuration.userFavoritesStore.isUserFavoriteBettingType(key.bettingTypeId, key.specifier || null));
		} else {
			selectedGroupKeys = this.keys.filter(key => key.bettingType.groupId === this.selectedBettingTypeGroup.abrv);
		}

		selectedGroupKeys.forEach(key => {
			const keyOffers = this.keyOffersMap.get(key.id);
			if (keyOffers == null) {
				return;
			}

			// if new key is about to be added and we have 1 or less spaces left, generate new page
			if (pd.length >= (this.configuration.rowCount - 1)) {
				pages.push(pd);
				pd = [];
			}

			const offers = Array.from(keyOffers.values());

			const config = new this.TypeConfiguration(key.bettingType.abrv, offers.map(o => o.tip.toLowerCase()))
			let sortedOffer = offers.sort((a, b) => {
				// sort offers!
				if (key.bettingType.specifierType === SpecifierType.PLAYER) {
					return this.sortPlayersByTeams(a, b);
				}

				return this.sortByTip(config.tips.sorted, a, b);
			});

			let row = KeyPageRowElement.create(key.id, 'key', 1, []);
			pd.push(row);

			// keeps track of key offer rows
			let index: number = 0;
			let offerRow = KeyPageRowElement.create(`${key.id}-${index}`, 'key-offer', 1, []);

			let i: number = 0;
			// for (let offer of keyOffers.values()) 
			for (let offer of sortedOffer) {
				// increment index for each iteration so we can keep track of where we are in the collection
				i++;

				if (row.offerIds != null && row.offerIds.length < this.configuration.rowElements) {
					row.offerIds.push(offer.id);

					if (keyOffers.size === i) {
						// this is last item in collection
						i = 0;
					}
					else {
						continue;
					}
				}
				else if (offerRow.offerIds != null && offerRow.offerIds.length < this.configuration.rowElements) {
					offerRow.offerIds.push(offer.id);

					if (keyOffers.size === i) {
						//Check if we are on last row, if we are push page and create new row on new page
						if (pd.length == (this.configuration.rowCount - 1)) {
							pages.push(pd);

							row = KeyPageRowElement.create(key.id, 'key', 1, [...offerRow.offerIds]);

							pd = [
								row,
							]
						} else {
							// this is last item in collection
							pd.push(offerRow);
						}

						i = 0;
					}
					else {
						continue;
					}
				}
				else if (keyOffers.size > this.configuration.rowElements && pd.length >= (this.configuration.rowCount - 2)) {
					//If we don't have enough rows for more offerRows push to current page and set offerRow to next page as key instead of key-offer
					if (pd.length == (this.configuration.rowCount - 1)) {
						pages.push(pd);

						row = KeyPageRowElement.create(key.id, 'key', 1, [...offerRow.offerIds]);
						offerRow = KeyPageRowElement.create(`${key.id}-${index}`, 'key-offer', 1, [offer.id]);
					} else {
						pd.push(offerRow);

						pages.push(pd);

						row = KeyPageRowElement.create(key.id, 'key', 1, [offer.id]);
						offerRow = KeyPageRowElement.create(`${key.id}-${index}`, 'key-offer', 1, []);
					}

					index++;

					pd = [
						row
					]

					continue;
				}
				else {
					// we filled offer row with offer, push in page
					pd.push(offerRow);
					index++;

					// check if we can fit new offer in the current page
					if (pd.length < (this.configuration.rowCount - 1)) {
						// we still have room - create new row with current offer item
						offerRow = KeyPageRowElement.create(`${key.id}-${index}`, 'key-offer', 1, [offer.id]);

						// last offer in the collection ,we need to push the page
						if (keyOffers.size === i) {
							pd.push(offerRow);
							i = 0;
						}
					}
					else {
						// no more room in the page
						pages.push(pd);
						row = KeyPageRowElement.create(key.id, 'key', 1, [offer.id]);
						offerRow = KeyPageRowElement.create(`${key.id}-${index}`, 'key-offer', 1, []);

						pd = [
							row
						];
					}
				}
			}
		});

		// we need to push last page!
		pages.push(pd);

		this.pageData = pages;
	}

	setRowCount(value: number) {
		this.configuration.rowCount = value;
	}
}

decorate(AdditionalPageableOfferStore, {
	event: observable,
	keys: observable,
	pageNumber: observable,
	pageData: observable,
	selectedBettingTypeGroup: observable,
	availableBettingTypeGroups: computed,
	sport: computed,
	sportCategory: computed,
	tournament: computed,
	changePage: action.bound,
	assignOfferData: action.bound,
	processMessage: action.bound,
	processOfferChanges: action.bound,
	processEventUpdate: action.bound,
	updateEvent: action.bound,
	processEventDelete: action.bound,
	processKeyUpdates: action.bound,
	updateKey: action.bound,
	processKeyDeletes: action.bound,
	processOfferUpdates: action.bound,
	processOfferDeletes: action.bound,
	updatePageData: action.bound,
	setRowCount: action.bound,
	setEventId: action.bound,
	setSelectedBettingTypeGroup: action.bound,
	resetSelectedBettingTypeGroup: action.bound,
});

export {
	AdditionalPageableOfferStore,
	KeyPageRowElement
}