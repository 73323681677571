import * as relatedBettingTypes from './related-betting-types.json';
import * as sortableSpecifiers from './sortable-specifiers.json';

export { Rule } from './Rule';
export { Rules } from './Rules';
export type { CategoriesType } from './Rules';

export {
	relatedBettingTypes,
	sortableSpecifiers
}