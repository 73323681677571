import { Sorter } from "@gp/utility";
import { ComparerFn } from '@gp/utility/dist/types/common';


import { EventOffer } from "../models";
import { LookupsStore } from "../stores";
import { getEventSortBySportCategory, getEventSortByTournament } from "./index";

export function getEventLiveSort(lookupsStore: LookupsStore): ComparerFn<EventOffer> {
    return Sorter.sort(
        'isLive',
        (a: EventOffer, b: EventOffer) => {
            if (a.isLive && b.isLive) {
                return Sorter.sort(
                    'isTop',
                    'startTime',
                    (a1: EventOffer, b1: EventOffer) => getEventSortBySportCategory(a1, b1, lookupsStore),
                    (a1: EventOffer, b1: EventOffer) => getEventSortByTournament(a1, b1, lookupsStore),
                )(a, b);
            } else if (a.isUpcoming && b.isUpcoming) {
                return Sorter.sort(
                    'startTime',
                    (a1: EventOffer, b1: EventOffer) => getEventSortBySportCategory(a1, b1, lookupsStore),
                    (a1: EventOffer, b1: EventOffer) => getEventSortByTournament(a1, b1, lookupsStore),
                )(a, b);
            }
            return 0
        },
        'id'
    )
}