import { decorate, observable, action, computed, runInAction } from 'mobx';

import {
	BettingOfferKeyUpdate,
	Event,
	EventDelete,
	EventKeyBettingOffer,
	EventUpdate,
	IChange,
	Key,
	OfferSubscriptionResponse
} from '@gp/models';
import {
	insert,
	Sorter,
	secondsToMilliseconds
} from '@gp/utility';

import { BaseOfferStore } from "../BaseOfferStore";

import { DefaultPrematchPageableConfiguration, IUpcomingPageableOfferConfiguration } from "..";
import {
	EventKeyOffer,
	EventOffer,
	PrematchPageRowElement,
	SportStructureOffer
} from '../..';
import { manipulateOfferSubscriptionResponse } from '../../utility/manipulateOfferResponse'


class UpcomingPageableOfferStore extends BaseOfferStore<IUpcomingPageableOfferConfiguration> {
	configuration: IUpcomingPageableOfferConfiguration;
	page: number = 1;

	get sortedEvents(): Event[] {
		const events = [];

		this.eventsMap.forEach(event => {
			insert(events, event, (a: Event, b: Event) => {
				const aSport = this.lookupsStore.sports.get(a.sportId);
				const bSport = this.lookupsStore.sports.get(b.sportId);

				const sportSortResult = Sorter.sort('sortOrder', 'id')(aSport, bSport);
				if (sportSortResult !== 0) {
					return sportSortResult;
				}

				const aFavorite = this.configuration.userFavoritesStore.userFavoriteEventsSet.has(a.id);
				const bFavorite = this.configuration.userFavoritesStore.userFavoriteEventsSet.has(b.id);

				if (aFavorite !== bFavorite) {
					if (aFavorite) return -1;
					if (bFavorite) return 1;
				}

				if (a.startTime !== b.startTime) {
					return Sorter.sort('startTime')(a, b);
				}

				const aCategory = this.lookupsStore.categories.get(a.sportCategoryId);
				const bCategory = this.lookupsStore.categories.get(b.sportCategoryId);

				const categorySortResult = Sorter.sort('sortOrder', 'id')(aCategory, bCategory);
				if (categorySortResult !== 0) {
					return categorySortResult;
				}

				const aTournament = this.lookupsStore.tournaments.get(a.tournamentId);
				const bTournament = this.lookupsStore.tournaments.get(b.tournamentId);

				const tournamentSortResult = Sorter.sort('sortOrder', 'id')(aTournament, bTournament);
				if (tournamentSortResult !== 0) {
					return tournamentSortResult;
				}

				return Sorter.sort('id')(a, b);
			});
		});

		return events;
	}

	get pageData(): PrematchPageRowElement[][] {
		let pd: PrematchPageRowElement[] = [];

		// all pages
		const pages = [pd];

		let lastSportId: string;

		this.sortedEvents.forEach(event => {
			if (event.sportId !== lastSportId) {
				if ((pd.length + 2) > this.configuration.rowCount) {
					pd = [];
					pages.push(pd);
				}

				lastSportId = event.sportId;

				pd.push(PrematchPageRowElement.create(event.sportId, 'header', 1, undefined, undefined, undefined));
			}
			else if ((pd.length + 1) > this.configuration.rowCount) {
				pd = [];
				pages.push(pd);
				pd.push(PrematchPageRowElement.create(event.sportId, 'header', 1, undefined, undefined, undefined));
			}

			pd.push(PrematchPageRowElement.create(event.id, 'event', undefined, undefined, undefined, event.isOutright));
		});

		return pages;
	}

	/**
	* Gets events in sport structure
	* Sport -> Category -> Tournament -> Events
	*/
	get eventsInSportStructure(): SportStructureOffer {
		const result = new SportStructureOffer();
		result.eventCount = this.eventsMap.size;

		this.eventsMap.forEach((event) => {
			const existingSport = result.sports.find(s => s.id === event.sportId);

			if (existingSport != null) {
				const existingCategory = existingSport.categories?.find(c => c.id === event.sportCategoryId);

				if (existingCategory) {
					const existingTournament = existingCategory.tournaments.find(t => t.id === event.tournamentId && t.isOutright === event.isOutright && t.isLive === event.isLive);

					if (existingTournament) {
						existingTournament.addEventOffer(new EventOffer(event, this.configuration.display));
						existingSport.eventCount++;
					}
					else {
						// tournament was not found in the current category offer which means we didn't map this tournament

						const tournamentOffer = this.getMappedTournament(event);
						if (tournamentOffer == null) {
							// skip this iteration
							return;
						}

						existingCategory.addTournamentOffer(tournamentOffer);
						existingSport.eventCount++;
					}
				}
				else {
					// category was not found in the current sport offer which means we didn't map this category

					const categoryOffer = this.getMappedCategory(event);
					if (categoryOffer == null) {
						// skip this iteration
						return;
					}

					existingSport.addSportCategoryOffer(categoryOffer);
					existingSport.eventCount++;
				}
			}
			else {
				// sport was not found in the current offer which means we didn't map this sport structure.

				const sportOffer = this.getMappedSport(event);
				if (sportOffer == null) {
					// skip this iteration
					return;
				}

				result.addSportOffer(sportOffer);
			}
		});

		return result;
	}

	constructor(configuration?: IUpcomingPageableOfferConfiguration) {
		super(Object.assign({}, new DefaultPrematchPageableConfiguration(), configuration));
	}

	changePage(newPage: number) {
		if (this.page !== newPage) {
			this.page = newPage;
		}
	}

	assignOfferData(data: OfferSubscriptionResponse) {
		data = manipulateOfferSubscriptionResponse(data, this);
		this.processMessage(data);
	}

	processMessage(data: OfferSubscriptionResponse) {
		if (data == null) {
			return;
		}

		const {
			lookups,
			offerChanges,
			version
		} = data;

		if (lookups != null && !lookups.isEmpty) {
			this.lookupsStore.update(lookups);
		}

		if (offerChanges != null) {
			this.processOfferChanges(offerChanges);
		}
		else {
			this.logger.logWarn("Received empty offer");
		}

		this.version = version;
	}

	processOfferChanges(offerChanges: IChange<EventUpdate, EventDelete>) {
		if (offerChanges.updates != null && offerChanges.updates.length > 0) {
			this.processEventUpdates(offerChanges.updates);
		}

		if (offerChanges.deletes != null && offerChanges.deletes.length > 0) {
			this.processEventDeletes(offerChanges.deletes);
		}
	}

	processEventUpdates(updates: EventUpdate[]) {
		updates.forEach(eventUpdate => {
			if (eventUpdate.event != null) {
				this.insertEvent(eventUpdate.event);

				if (eventUpdate.offers != null && eventUpdate.offers.updates != null && eventUpdate.offers.updates.length > 0) {
					this.processEventKeys(eventUpdate.eventId, eventUpdate.offers.updates);
				}
				else {
					this.logger.logInfo(`Event ${eventUpdate.eventId} has no offer`);
				}
			}
			else {
				// we have no event data thus we don't want to map offer for this event
				this.logger.logWarn(`Did not receive event data for event with id: ${eventUpdate.eventId}. Skip!`);
				return;
			}
		});
	}

	processEventDeletes(eventDeletes: EventDelete[]) {
		// list of event event ids that require delayed remove
		const delay: string[] = [];

		eventDeletes.forEach(event => {
			if (event.ended) {
				this.updateEvent(event);
				delay.push(event.id);
			}
			else {
				this.deleteEvent(event.id);
			}
		});

		if (delay.length > 0) {
			setTimeout(() => runInAction(() => {
				delay.forEach(eid => this.deleteEvent(eid));
			}), secondsToMilliseconds(this.configuration.removeDelay));
		}
	}

	updateEvent(event: Event) {
		let currEvent = this.eventsMap.get(event.id);

		if (currEvent != null) {
			currEvent.update(event);
		}
		else {
			currEvent = new EventOffer(event, this.configuration.display);
			this.eventsMap.set(event.id, currEvent);
		}
	}

	deleteEvent(eventId: string) {
		// first delete all event key offers
		const eventKeys = this.eventKeysMap.get(eventId);
		if (eventKeys != undefined) {
			eventKeys.forEach(ek => this.keyOffersMap.delete(ek.id));
		}

		// then delete all event keys
		this.eventKeysMap.delete(eventId);

		// then delete event itself
		this.eventsMap.delete(eventId);
	}

	processEvents(updates: EventUpdate[]) {
		updates.forEach(eventUpdate => {
			if (eventUpdate.event != null) {
				this.insertEvent(eventUpdate.event);

				if (eventUpdate.offers != null && eventUpdate.offers.updates != null && eventUpdate.offers.updates.length > 0) {
					this.processEventKeys(eventUpdate.eventId, eventUpdate.offers.updates);
				}
				else {
					this.logger.logInfo(`Event ${eventUpdate.eventId} has no offer`);
				}
			}
			else {
				// we have no event data thus we don't want to map offer for this event
				this.logger.logWarn(`Did not receive event data for event with id: ${eventUpdate.eventId}. Skip!`);
				return;
			}
		});
	}

	insertEvent(event: Event) {
		const newEvent = this.createNewEvent(event);

		this.eventsMap.set(event.id, newEvent);
	}

	processEventKeys(eventId: string, keyUpdates: BettingOfferKeyUpdate[]) {
		keyUpdates.forEach(keyUpdate => {
			if (keyUpdate.key != null) {
				this.insertKey(eventId, keyUpdate.key);

				if (keyUpdate.offers != null && keyUpdate.offers.updates != null && keyUpdate.offers.updates.length > 0) {
					this.processKeyOffers(eventId, keyUpdate);
				}
			}
			else {
				// we have no key data thus we don't want to map it
				this.logger.logWarn(`Did not receive key data for key with id: ${keyUpdate.keyId}. Skip!`);
				return;
			}
		});
	}

	insertKey(eventId: string, key: Key) {
		const eventKeys = this.eventKeysMap.get(eventId) || new Map<string, EventKeyOffer>();;
		const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

		if (keyBettingType == null) {
			throw `Can not find betting type ${key.bettingTypeId} in lookups store.`
		}

		const newKey = this.createNewKey(key, eventId, keyBettingType);
		eventKeys?.set(key.id, newKey);

		this.eventKeysMap.set(eventId, eventKeys);
	}

	processKeyOffers(eventId: string, key: BettingOfferKeyUpdate) {
		const keyOffers = this.keyOffersMap.get(key.keyId) || new Map<string, EventKeyBettingOffer>();
		const event = this.eventsMap.get(eventId);

		// check if all offers have same playerId, or if they have playerId at all
		let playerId: string | undefined = undefined;
		let teamIds: { teamOneId: string | undefined, teamTwoId: string | undefined } | undefined = undefined;

		if (key.offers?.updates?.every((o, i, src) => o.playerId != null && o.playerId !== '' && o.playerId === src[0].playerId)) {
			playerId = key.offers.updates[0].playerId;
		}

		if (!event?.isOutright) {
			teamIds = {
				teamOneId: event?.teamOneId,
				teamTwoId: event?.teamTwoId,
			}
		}

		key.offers?.updates?.forEach(o => {
			const tipInfo = this.getTipInformation({
				defaultTip: o.tip,
				bettingTypeId: key.bettingTypeId,
				playerId: o.playerId,
				teamId: o.teamId,
				specifiers: {
					...key.key?.specifier,
					...o.specifier
				},
				eventData: {
					name: event?.name,
					playerId: playerId,
					teamIds: teamIds
				}
			});

			keyOffers?.set(o.id, {
				...o,
				keyId: key.keyId,
				eventId: eventId,
				isLive: false,
				isLocked: !!o.isLocked,
				tip: tipInfo.tip,
				displayTip: tipInfo.displayTip,
				gender: tipInfo.gender,
				indicator: 0
			});
		});

		this.keyOffersMap.set(key.keyId, keyOffers);
	}
}

decorate(UpcomingPageableOfferStore, {
	page: observable,
	sortedEvents: computed,
	pageData: computed,
	eventsInSportStructure: computed,
	assignOfferData: action.bound,
	processMessage: action.bound,
	processOfferChanges: action.bound,
	processEvents: action.bound,
	processEventDeletes: action.bound,
	updateEvent: action.bound,
	deleteEvent: action.bound,
	insertEvent: action.bound,
	processEventKeys: action.bound,
	insertKey: action.bound,
	processKeyOffers: action.bound,
});

export {
	UpcomingPageableOfferStore
}
