import { EventOffer } from "../models";

export function getEventSortByLiveStatus(a: EventOffer, b: EventOffer) {
	const res = (b.liveStatus || 0) - (a.liveStatus || 0);
	if (res < 0) {
		return -1;
	}
	else if (res > 0) {
		return 1;
	}

	return 0;
}