import { ComparisonOffersStore } from "../ComparisonOffersStore";
import type { AdditionalPageableOfferStore } from "./AdditionalPageableOfferStore";

export class AdditionalOfferComparisonStore extends ComparisonOffersStore {
	protected source: AdditionalPageableOfferStore;

	constructor(source: AdditionalPageableOfferStore) {
		super(source);
	}

	/**
	 * Adds event key offers to comparison map
	 * @param eventId *NOT USED*
	 * @param keyId key id
	 */
	add(eventId: string, keyId: string) {
		const existingKey = this.source.keys.find(k => k.id === keyId);

		if (existingKey == null) {
			return;
		}

		const offers = this.source.keyOffersMap.get(keyId);

		if (offers == null || offers.size === 0) {
			return;
		}

		offers.forEach(o => this.comparisonOffers.set(o.id, o.value));
	}
}