import { decorate, observable, action, computed } from 'mobx';
import { CurrentScore, Event, HomeAwayScore, LiveStatus, Result } from '@gp/models';

type Display = "american" | "european";

type DisplayValues = {
	teamOneId?: string,
	teamTwoId?: string,
	result?: Result,
	currentScore?: CurrentScore,
}

class EventOffer extends Event {
	private display: Display = "european";
	tvCoverage: string | null = null;
	displayValues: DisplayValues;

	get isLive(): boolean {
		return this.liveStatus === LiveStatus.LIVE;
	}

	constructor(event: Event, display: Display | null = null) {
		super(event);

		if (display) {
			this.display = display;
		}

		this.updateDisplayValues(event);
	}

	/**
	 * Updates event data
	 * @description update only properties relevant for sport offer display
	 * @param event new event data
	 */
	update(event: Event) {
		this.bettingStatus = event.bettingStatus || 0;
		this.isLocked = event.isLocked;
		this.matchStatus = event.matchStatus;
		this.ended = !!event.ended;
		this.currentScore = event.currentScore;
		this.result = event.result;
		this.startTime = event.startTime;
		this.matchTime = event.matchTime;
		this.totalOfferCount = event.totalOfferCount;
		this.note = event.note;

		this.updateDisplayValues(event);


		// in case of an ended event we don't want to update
		if (!event.ended) {
			this.liveStatus = event.liveStatus || 0;
			this.isTopEvent = !!event.isTopEvent;
			this.hasLiveCoverage = !!event.hasLiveCoverage;
			this.isUpcoming = !!event.isUpcoming;
		}
	}

	updateDisplayValues(event: Event): void {

		if (this.display == "european") {
			this.displayValues = {
				currentScore: event.currentScore,
				result: event.result,
				teamOneId: event.teamOneId,
				teamTwoId: event.teamTwoId,
			}
			return;
		};

		const { currentScore, result, teamOneId, teamTwoId } = event;

		const switchFunction = (score: HomeAwayScore) => {
			if (score.away && score.home) {
				const away = score.away;
				const home = score.home;

				score.home = away;
				score.away = home;
				return;
			}

			if (score.away) {
				score.home = score.away;
				delete score.away;
				return;
			}

			if (score.home) {
				score.away = score.home;
				delete score.home;
				return;
			}
		}

		if (result) {
			if (result.scorePerPeriod?.length > 0) {
				result.scorePerPeriod.forEach(period => switchFunction(period))
			}

			if (result.currentGameScore) {
				switchFunction(result.currentGameScore)
			}

			if (result.tiebreakScore) {
				switchFunction(result.tiebreakScore);
			}

			if (result.currentScore) {
				switchFunction(result.currentScore);
			}

			if (result.serve) {
				if (result.serve == "away") {
					result.serve = "home";
				} else {
					result.serve = "away";
				}
			}

			const homeStats = result.homeTeamTotalStatistics;
			const awayStats = result.awayTeamTotalStatistics;

			if (homeStats && awayStats) {
				result.homeTeamTotalStatistics = awayStats;
				result.awayTeamTotalStatistics = homeStats;
			}
			else if (homeStats) {
				result.awayTeamTotalStatistics = homeStats;
				delete result.homeTeamTotalStatistics;
			}
			else if (awayStats) {
				result.homeTeamTotalStatistics = awayStats;
				delete result.awayTeamTotalStatistics;
			}
		}

		if (currentScore) {
			switchFunction(currentScore);
		}

		this.displayValues = {
			currentScore: currentScore,
			result: result,
			teamOneId: teamTwoId,
			teamTwoId: teamOneId,
		}
	}

	setTvCoverage(tvCoverage: string) {
		this.tvCoverage = tvCoverage;
	}
}

decorate(EventOffer, {
	bettingStatus: observable,
	liveStatus: observable,
	isLocked: observable,
	matchStatus: observable,
	ended: observable,
	startTime: observable,
	matchTime: observable,
	currentScore: observable,
	result: observable,
	isTopEvent: observable,
	totalOfferCount: observable,
	hasLiveCoverage: observable,
	hasTvCoverage: observable,
	note: observable,
	isUpcoming: observable,
	tvCoverage: observable,
	isLive: computed,
	displayValues: observable,
	update: action.bound,
	setTvCoverage: action.bound,
	updateDisplayValues: action.bound
});

export {
	EventOffer
}