import { idComparer } from "@gp/utility";

import { EventOffer } from "../models";
import { LookupsStore } from "../stores";
import { getSortOrderOrDefault } from "./getSortOrderOrDefault";

export function getEventSortByTournament(
  a: EventOffer,
  b: EventOffer,
  lookupsStore: LookupsStore,
) {
  if (a.tournamentId != b.tournamentId) {
    const aTournament = lookupsStore.tournaments.get(a.tournamentId);
    const bTournament = lookupsStore.tournaments.get(b.tournamentId);
    if (getSortOrderOrDefault(aTournament?.sortOrder) < getSortOrderOrDefault(bTournament?.sortOrder)) return -1;
    if (getSortOrderOrDefault(aTournament?.sortOrder) > getSortOrderOrDefault(bTournament?.sortOrder)) return 1;

    if (aTournament == null) return 1;
    if (bTournament == null) return -1;

    if (aTournament.name < bTournament.name) return -1;
    if (aTournament.name > bTournament.name) return 1;

    return idComparer({ id: aTournament.id }, { id: bTournament.id });
  }

  return 0;
}

