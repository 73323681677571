import { decorate, action, observable } from 'mobx';

import {
	Event,
	EventKeyBettingOffer,
	OfferSubscriptionResponse,
	Player,
	BettingType,
	Key,
} from '@gp/models';

import { EventKeyOffer, EventOffer, SportCategoryOffer, SportOffer, TournamentOffer } from '../models';
import { constants } from '../mapper';
import { DefaultConfiguration, IConfiguration } from "./configuration";
import { LookupsStore } from './LookupsStore';
import { ComparisonOffersStore } from './ComparisonOffersStore';
import { ISpecifiers, NameProvider, NameExpressionHelpers } from '@gp/utility';

import { OfferMapper as EuropeanOfferMapper, TypeConfiguration as EuropeanTypeConfiguration } from '../mapper/european';
import { OfferMapper as AmericanOfferMapper, TypeConfiguration as AmericanTypeConfiguration } from '../mapper/american';

/**
 * Map<eventId, Event>
 */
type EventMap = Map<string, EventOffer>;
/**
 * Map<eventId, Map<keyId, EventKey>>
 */
type EventKeysMap = Map<string, Map<string, EventKeyOffer>>;
/**
 * Map<keyId, Map<offerId, EventKeyBettingOffer>>
 */
type KeyOffersMap = Map<string, Map<string, EventKeyBettingOffer>>;

type EventData = {
	/**
	 * Only applicable for non-outright events
	 */
	teamIds?: {
		teamOneId?: string;
		teamTwoId?: string;
	}
	/**
	 * This is specific for offer keys since they can contain {%player} specifier.
	 * If this is the case, we check if all the offers contain the same playerId (they most certainly do)
	 * and we take playerId from the offers. Offers in that case might not require playerId since they won't
	 * have player specifier.
	 * 
	 * @example
	 * "{%player} scores a goal"
	 * // key contains offers like this
	 * [
	 * 	{ tip: "y", playerId: "<id>", ... },
	 * 	{ tip: "n", playerId: "<id>", ... }
	 * ]
	 */
	playerId?: string;
	/**
	 * Event name
	 * 
	 * Only applicable for outright events
	 */
	name?: string;
}
type TipInformationData = {
	defaultTip: string;
	bettingTypeId: string;
	eventData: EventData;
	specifiers?: ISpecifiers
	playerId?: string;
	teamId?: string;
}

class BaseOfferStore<TConfig extends IConfiguration> {
	protected version: number = 0;

	protected _buffer: OfferSubscriptionResponse[] = [];
	protected _bufferTimeoutId: number | undefined;

	protected comparisonOfferStore: ComparisonOffersStore;

	get logger() {
		return this.configuration.logger;
	}


	configuration: TConfig;

	nameProvider: NameProvider;

	lookupsStore: LookupsStore;

	/**
	 * Events in current offer
	 */
	eventsMap: EventMap;

	/**
	 * Event offer keys
	 */
	eventKeysMap: EventKeysMap;

	/**
	 * Key offers
	 */
	keyOffersMap: KeyOffersMap;

	/**
	 * True if offer was initialized. This comes handy when offer reset happens and we need to display tips
	 */
	initialized: boolean = false;

	get OfferMapper() {
		switch (this.configuration.display) {
			case 'european':
				return EuropeanOfferMapper;
			case 'american':
				return AmericanOfferMapper;
			default:
				return EuropeanOfferMapper;
		}
	}

	get TypeConfiguration() {
		switch (this.configuration.display) {
			case 'european':
				return EuropeanTypeConfiguration;
			case 'american':
				return AmericanTypeConfiguration;
			default:
				return EuropeanTypeConfiguration;
		}
	}

	constructor(configuration?: TConfig) {
		if (configuration != undefined) {
			this.configuration = Object.assign({}, new DefaultConfiguration(), configuration);
		}
		else {
			this.configuration = (new DefaultConfiguration() as TConfig);
		}

		this.lookupsStore = new LookupsStore();

		this.eventsMap = observable.map<string, EventOffer>(undefined, { name: 'eventsMap' });
		this.eventKeysMap = observable.map<string, Map<string, EventKeyOffer>>(undefined, { name: 'eventKeysMap' });
		this.keyOffersMap = observable.map<string, Map<string, EventKeyBettingOffer>>(undefined, { name: 'keyOffersMap' });

		this.comparisonOfferStore = new ComparisonOffersStore(this);
		this.nameProvider = new NameProvider();
	}

	/**
	 * Resets data
	 */
	reset() {
		this.lookupsStore.reset();

		this.eventsMap.clear();
		this.eventKeysMap.clear();
		this.keyOffersMap.clear();

		clearTimeout(this._bufferTimeoutId);
		this._bufferTimeoutId = undefined;
		this._buffer = []

		this.version = 0;
	}

	/**
	 * Formats specifier
	 * @param specifier specifier object
	 */
	formatSpecifier(specifier: ISpecifiers): string {
		const specifierKeys = Object.keys(specifier);

		const specifierKeyValues: string[] = [];
		specifierKeys.forEach(k => {
			const specifierValue = constants.multiMarginSpecifiers.includes(k) ? `${specifier[k]}.${k[0].toUpperCase()}` : specifier[k];
			specifierKeyValues.push(specifierValue);
		});

		return specifierKeyValues.join(' / ');
	}

	/**
	 * It filters offer specifiers based on betting type name.
	 * It returns string of specifier values joined with ' / '.
	 */
	getMarginByOffer(offer: EventKeyBettingOffer): string {

		const bettingType = this.eventKeysMap.get(offer.eventId)?.get(offer.keyId);
		const bettingTypeName = bettingType?.bettingType.name;
		const marginSpecifiers: string[] = [];

		const specifierPlaceHoldersWithOperator =
			bettingTypeName == null ?
				null :
				NameExpressionHelpers.parseDescriptor(bettingTypeName);

		const specifierPlaceHolders =
			specifierPlaceHoldersWithOperator == null ?
				null :
				specifierPlaceHoldersWithOperator
					.expressions
					.map((specifier: any) => NameExpressionHelpers.parseExpression(specifier)?.operand)

		const specifiers = Object
			.entries(offer.specifier || {})
			.map(([key, value]) => ([key, value, constants.multiMarginSpecifiers.includes(key)]))
			.sort((A, B) => {
				if (A[2] === B[2]) {
					return 0
				}

				if (A[2]) {
					return -1
				}

				return 1
			})
			.map(
				([key, value, isMultiMargin]) =>
					[
						key as string,
						!isMultiMargin ? value as string : `${offer.specifier?.[key as string]}.${key[0].toUpperCase()}`
					]
			)

		for (const [key, value] of specifiers) {

			if (specifierPlaceHolders != null && specifierPlaceHolders.indexOf(key) === -1) {
				continue;
			}

			marginSpecifiers.push(value);
		}


		return marginSpecifiers.join(' / ');
	}

	// --------------------
	// -----  UTILITY -----
	// --------------------


	protected getTipInformation(data: TipInformationData): { tip: string, displayTip: string, abrv: string, gender?: number, countryCode?: string } {
		if (data.playerId != null && data.playerId !== '') {
			const player = this.lookupsStore.players.get(data.playerId);

			if (player != null) {
				const constructedTip = this.constructPlayerName(player);

				return {
					tip: constructedTip,
					displayTip: constructedTip,
					abrv: constructedTip.trim().toLowerCase().replace(' ', '-')
				};
			}
			else {
				this.logger.logWarn(`Player ${data.playerId} not found in Players lookup.`, {
					defaultTip: data.defaultTip,
					playerId: data.playerId
				});
			}
		}
		else if (data.teamId != null && data.teamId !== '') {
			const team = this.lookupsStore.teams.get(data.teamId);

			if (team != null) {
				return {
					tip: team.name,
					displayTip: team.name,
					abrv: team.abrv || team.name?.trim().toLowerCase().replace(' ', '-') || '',
					gender: team.gender,
					countryCode: team?.countryCode || ""
				}
			}
			else {
				this.logger.logWarn(`Team ${data.teamId} not found in Teams lookup.`, {
					defaultTip: data.defaultTip,
					teamId: data.teamId
				});
			}
		}
		else if (this.lookupsStore.bettingTypes.has(data.bettingTypeId)) {
			// check if tip exists
			const btData = this.lookupsStore.bettingTypes.get(data.bettingTypeId);
			if (btData?.tips != null && btData.tips.length > 0) {
				const tip = btData.tips.find(t => t.name === data.defaultTip);
				if (tip != null && tip.displayName != null && tip.displayName !== '') {
					return {
						tip: data.defaultTip,
						displayTip: this.nameProvider.getName(tip.displayName, this.constructSpecifiers(data.eventData, data.specifiers)),
						abrv: data.defaultTip.trim().toLowerCase().replace(' ', '-')
					};
				}
			}
		}

		return {
			tip: data.defaultTip,
			displayTip: this.nameProvider.getName(data.defaultTip, this.constructSpecifiers(data.eventData, data.specifiers)),
			abrv: data.defaultTip.trim().toLowerCase().replace(' ', '-')
		};
	}

	protected constructSpecifiers(eventData: EventData, defaultSpecifiers?: ISpecifiers): ISpecifiers {
		const specs: ISpecifiers = {
			...defaultSpecifiers
		};

		if (eventData.teamIds != null) {
			specs.competitor1 = this.lookupsStore.teams.get(eventData.teamIds.teamOneId || "")?.name || '';
			specs.competitor2 = this.lookupsStore.teams.get(eventData.teamIds.teamTwoId || "")?.name || '';
		}

		if (eventData.name != null && eventData.name !== '') {
			specs.event = eventData.name || '';
		}

		if (eventData.playerId != null && eventData.playerId !== '') {
			specs.player = this.constructPlayerName(this.lookupsStore.players.get(eventData.playerId));
		}

		return specs;
	}

	protected constructPlayerName(player: Player | undefined): string {
		if (player == null) {
			return '';
		}

		if (player.lastName && player.firstName) {
			return `${player.lastName}, ${player.firstName}`;
		}
		else if (player.lastName) {
			return player.lastName;
		}
		else if (player.firstName) {
			return player.firstName;
		}

		return '';
	}

	/**
	 * Maps entire sport structure. From sport to tournament with events
	 * @param event Event
	 * @param isEventType if true, events are mapped to sports and not passed downward to category...
	 * @returns Sport offer instance
	 */
	protected getMappedSport(event: Event, isEventType: boolean = false) {
		const sportOffer = new SportOffer(event.isLive, event.isOutright);

		// get sport info
		const sport = this.lookupsStore.sports.get(event.sportId);

		if (sport == null) {
			this.logger.logError(`Sport ${event.sportId} for event ${event.id} was not in the lookup.`);
			return null;
		}

		Object.assign(sportOffer, sport);

		sportOffer.headers = this.OfferMapper.getMainSportHeaders(sport.abrv, event.isLive, this.configuration.customConfiguration);

		if (isEventType) {
			sportOffer.events = [];

			sportOffer.addEvent(new EventOffer(event, this.configuration.display));
		}
		else {
			sportOffer.categories = [];

			// map category (inner structure)
			const mappedCategory = this.getMappedCategory(event);
			if (mappedCategory != null) {
				sportOffer.categories.push(mappedCategory);
			}
			else {
				return null;
			}
		}

		// if we got this far, we've inserted one event
		sportOffer.eventCount = 1
		return sportOffer;
	}

	/**
	 * Maps part of sports structure starting from sport category downwards
	 * @param event Event
	 * @returns Sport category offer instance
	 */
	protected getMappedCategory(event: Event): SportCategoryOffer | null {
		const category = this.lookupsStore.categories.get(event.sportCategoryId);

		if (category == null) {
			this.logger.logError(`Category ${event.sportCategoryId} for event ${event.id} was not in the lookup.`);
			return null;
		}

		const categoryOffer = new SportCategoryOffer(category);

		// map tournament (inner structure)
		const mappedTournament = this.getMappedTournament(event);
		if (mappedTournament != null) {
			categoryOffer.tournaments.push(mappedTournament);
		}
		else {
			return null;
		}

		return categoryOffer;
	}

	/**
	 * Maps tournament offer
	 * @param event Event
	 * @returns Tournament offer events
	 */
	protected getMappedTournament(event: Event): TournamentOffer | null {

		const tournament = this.lookupsStore.tournaments.get(event.tournamentId);

		if (tournament == null) {
			this.logger.logError(`Tournament ${event.tournamentId} for event ${event.id} was not in the lookup.`);
			return null;
		}

		const tournamentOffer = new TournamentOffer(tournament);

		tournamentOffer.events.push(new EventOffer(event, this.configuration.display));

		return tournamentOffer;
	}

	/**
	 * Creates new EventKey
	 * @param key key data
	 * @param eventId event id
	 * @param bettingType betting type
	 */
	protected createNewKey(key: Key, eventId: string, bettingType: BettingType): EventKeyOffer {
		const newKey = new EventKeyOffer({
			...key,
			eventId: eventId,
			bettingType: bettingType
		});

		return newKey;
	}

	/**
	 * Creates new event with all getters
	 * @param event event data
	 */
	protected createNewEvent(event: Event): EventOffer {
		return new EventOffer(event, this.configuration.display);
	}
}

decorate(BaseOfferStore, {
	initialized: observable,
	eventsMap: observable,
	eventKeysMap: observable,
	keyOffersMap: observable,
	reset: action,
	formatSpecifier: action
});

export {
	BaseOfferStore,
}