import { BettingType } from "@gp/models";

export class SportOtherOffer {
	bettingType: BettingType;
	bettingTypeId: string;
	count: number;

	constructor(bettingTypeId: string, bettingType: BettingType, count: number) {
		this.bettingTypeId = bettingTypeId;
		this.bettingType = bettingType;
		this.count = count;
	}
}