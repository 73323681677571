import { action, decorate, observable } from "mobx";

import { AvailableBettingType, SportSelection, IBettingTypeSelectorsStore, SportSelectorType, SportSelectors, Selector } from "./IBettingTypeSelectorsStore";

class BettingTypeSelectorsStore implements IBettingTypeSelectorsStore {
	private _maxNumberOfColumns: number;

	sportSelections: SportSelection = new Map<string, SportSelectors>();

	constructor(maxNumberOfColumns: number = 4) {
		if (maxNumberOfColumns < 1) {
			throw "maxNumberOfColumns must be greater than 0";
		}

		this._maxNumberOfColumns = maxNumberOfColumns;
	}

	createSportSelectors(): SportSelectors {
		const obj: SportSelectors = {};

		for (let i = 1; i <= this._maxNumberOfColumns; i++) {
			obj[`${i}`] = null;
		}

		return obj;
	}

	update(sports: SportSelectorType[], availableBettingType: AvailableBettingType): void {
		if (sports == null || sports.length === 0) {
			return;
		}

		sports.forEach(sport => {
			const currentSelectors = this.sportSelections.get(this.selectorCollectionKey(sport));

			if (currentSelectors == null) {
				this.initializeSportSelection(sport, availableBettingType);
			}
			else {
				this.updateSportSelection(sport, currentSelectors, availableBettingType);
			}
		});
	}

	reset(): void {
		this.sportSelections.clear();
	}

	initializeSportSelection(sport: SportSelectorType, availableBettingType: AvailableBettingType): void {
		const newSportSelection = this.createSportSelectors();

		let col = 1;
		for (let i = 0; i < sport.headers.selectors.length && col <= this._maxNumberOfColumns; i++) {
			const selector = sport.headers.selectors[i];
			const primary: string[] = selector.primary;

			const availableBT: string | undefined = primary.find(p => availableBettingType.has(p) || p === "result");

			if (availableBT != null) {
				// we found available betting types under current selector
				newSportSelection[`${col}` as keyof SportSelectors] = {
					name: selector.name,
					tips: selector.tips,
					displayTips: selector.displayTips,
					bettingTypes: selector.primary,
					secondaryBettingTypes: selector.secondary
				} as Selector;

				col += 1;
			}
		}

		const sportKey = this.selectorCollectionKey(sport);
		this.sportSelections.set(sportKey, newSportSelection);
	}

	updateSportSelection(sport: SportSelectorType, currentSportSelectors: SportSelectors, availableBettingType: AvailableBettingType): void {
		let col = 1;
		for (let i = 0; i < sport.headers.selectors.length && col <= this._maxNumberOfColumns; i++) {
			// check current selected betting type
			const curr: Selector = currentSportSelectors[`${col}` as keyof SportSelectors];
			if (curr != null && curr.bettingTypes.some(bt => availableBettingType.has(bt) || bt === "result")) {
				// any betting type from the current selector is available in the offer
				col += 1;
				continue;
			}

			const selector = sport.headers.selectors[i];

			// check if selectors is already mapped
			if (Object.values(currentSportSelectors).some(c => c != null && c.name === selector.name)) {
				continue;
			}

			const primary: string[] = selector.primary;

			const availableBT: string | undefined = primary.find(p => availableBettingType.has(p) || p === "result");

			if (availableBT != null) {
				// we found available betting type under current selector
				currentSportSelectors[`${col}`] = {
					name: selector.name,
					tips: selector.tips,
					displayTips: selector.displayTips,
					bettingTypes: selector.primary,
					secondaryBettingTypes: selector.secondary
				} as Selector;

				col += 1;
			}
		}

		const sportKey = this.selectorCollectionKey(sport);
		this.sportSelections.set(sportKey, currentSportSelectors);
	}

	setSportSelector(sport: SportSelectorType, newSelectors: SportSelectors): void {
		const sportKey = this.selectorCollectionKey(sport);
		this.sportSelections.set(sportKey, newSelectors);
	}

	getSportSelector(sport: SportSelectorType): SportSelectors | undefined {
		const sportKey = this.selectorCollectionKey(sport);
		return this.sportSelections.get(sportKey);
	}

	selectorCollectionKey = (sport: SportSelectorType) => {
		if (sport.isOutright) {
			return `${sport.abrv}-outright`
		}
		if (sport.isLive) {
			return `${sport.abrv}-live`
		}

		return sport.abrv;
	}
}

decorate(BettingTypeSelectorsStore, {
	sportSelections: observable,
	update: action,
	reset: action,
	initializeSportSelection: action,
	updateSportSelection: action,
	setSportSelector: action
});

export {
	BettingTypeSelectorsStore
}