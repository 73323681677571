import { IMappingGroup } from "../";

export class Rule {
	sports: Set<string>;
	groups: IMappingGroup[];
	isDefault: boolean = false;

	constructor(sports: string[], groups: IMappingGroup[], isDefault: boolean = false) {
		this.sports = new Set(sports);
		this.groups = groups;
		this.isDefault = isDefault;
	}
}