import { decorate, action } from "mobx";

import { insert, Sorter, isOutrightComparer } from "@gp/utility";
import { ComparerFn } from '@gp/utility/dist/types/common';

import { SportOffer } from "./SportOffer";
import { SportStructureOffer } from "./SportStructureOffer";

class LiveSportStructureOffer extends SportStructureOffer {
	addSportOffer(sportOffer: SportOffer, prepend?: boolean): void {
		if (prepend) {
			this.sports.unshift(sportOffer);
		}
		else {
			insert(this.sports, sportOffer, Sorter.sort<SportOffer>('sortOrder', isOutrightComparer, '!isLive', 'name', 'id'));
		}
	}

	/**
	 * Resort sports
	 * @param sortFn Sort function used for sorting sports
	 */
	resortSports(sortFn?: ComparerFn<SportOffer>) {
		if (sortFn) {
			this.sports = this.sports.sort(sortFn);
		}
		else {
			this.sports = this.sports.sort(Sorter.sort('sortOrder', isOutrightComparer, '!isLive', 'name', 'id'));
		}
	}
}

decorate(LiveSportStructureOffer, {
	addSportOffer: action,
	resortSports: action.bound,
});

export {
	LiveSportStructureOffer
}