import { TipConfiguration, TipOptions } from './TipConfiguration';

export class TypeConfiguration {
    /**
     * Betting type abrv
     */
    abrv: string;

    tips: TipConfiguration;
    isMargin: boolean = false;

    sortOrder: number;

    /**
     * Creates new betting type configuration object 
     * @description Configuration is created for specified betting type abrv or taken from tips argument if configuration for provided betting type abrv is not present
     * @param abrv betting type abrv
     * @param tips tips related to betting type
     */
    constructor(abrv: string, tips: string[] = [], sortOrder: number = 9999) {
        this.abrv = abrv.toLowerCase();
        this.sortOrder = sortOrder;

        this.getConfiguration(tips);
    }

    private getConfiguration(inputTips: string[]) {
        switch (this.abrv) {
            case "3way":
            case "which-team-wins-the-rest-of-the-match":
            case "overtime-which-team-wins-the-rest":
            case "1x2-incl-overtime":
            case "most-180s":
            case "who-wins-1st-half":
            case "1st-half-which-team-wins-the-rest":
            case "10-minutes-1x2-from-from-to-to":
            case "who-wins-2nd-half":
            case "booking-1x2":
            case "1st-half-booking-1x2":
            case "which-team-will-have-more-corners":
            case "overtime-1x2":
            case "overtime-1st-half-1x2":
            case "1st-half-corner-1x2":
            case "team-with-highest-scoring-inning":
            case "innings-1-to-5th-top-1x2":
            case "team-to-win-more-innings":
            case "2nd-half-1x2-incl-overtime":
            case "most-sixes":
            case "1x2-incl-extra-innings":
            case "who-scores-last":
            case "team-with-highest-scoring-quarter":
            case "most-fours":
            case "most-run-outs":
            case "team-with-highest-scoring-half":
            case "1st-half-try-1x2":
            case "most-catches":
            case "most-extras":
            case "try-1x2":
            case "1st-half-first-team-to-score":
            case "last-team-to-score-try":
            case "most-keeper-catches":
            case "2nd-half-first-team-to-score-try":
            case "first-team-to-score-try":
            case "innings-1-to-5-1x2":
            case "most-stumpings":
            case "setnr-set-gamenrx-and-gamenry-game-winner":
            case "v1-koja-ce-ekipa-dobiti-1-zuti-karton-511":
            case "v1-tko-postize-prvi-gol-u-1-poluvremenu-506":
            case "highest-scoring-half":
            case "competitior1-highest-scoring-half":
            case "competitior2-highest-scoring-half":
            case "head2head-1x2":
                this.tips = TipConfiguration.get({ configName: "3way" });
                break;
            case "1st-half-handicap-hcp":
            case "2nd-half-handicap-hcp":
            case "handicap-hcp":
            case "1st-half-try-handicap-hcp":
            case "handicap-hcp-incl-extra-innings":
            case "map-handicap-hcp":
            case "periodnr-period-handicap-hcp":
            case "quarternr-quarter-handicap-hcp":
            case "set-handicap-hcp":
            case "setnr-set-handicap-hcp":
            case "try-handicap-hcp":
                this.tips = TipConfiguration.get({ configName: "3wayMargin" });
                this.isMargin = true;
                break;
            case "quarternr-quarter-1x2":
            case "who-wins-period-periodnr":
            case "inningnr-inning-1x2":
                this.tips = TipConfiguration.get({ configName: "3wayMargin" });
                break;
            case "tip-1x2-and-over-under":
                this.tips = TipConfiguration.get({ configName: "3wayOverUnderMargin" });
                this.isMargin = true;
                break;
            case "1st-half-tip1x2-over-under":
                this.tips = TipConfiguration.get({ configName: "3wayOverUnder" });
                this.isMargin = true;
                break;
            case "3-way-both-teams-to-score":
            case "1st-half-tip-1x2-both-teams-to-score":
            case "1x2-both-teams-to-score-incl-overtime-and-penalties":
            case "2nd-half-1x2-both-teams-to-score":
                this.tips = TipConfiguration.get({ configName: "3wayAndYesNo" });
                break;
            case "double-chance":
            case "1st-half-double-chance":
            case "2nd-half-double-chance":
            case "1st-half-try-double-chance":
            case "overtime-double-chance":
            case "try-double-chance":
                this.tips = TipConfiguration.get({ configName: "doubleChance" });
                break;
            case "winner":
            case "draw-no-bet":
            case "1st-half-draw-no-bet":
            case "winner-incl-extra-innings":
            case "v1-1st-half-2way-125":
            case "winner-incl-overtime":
            case "winner-incl-super-over":
            case "penalty-shootout-winner":
            case "which-team-kicks-off":
            case "to-qualify":
            case "winner-incl-overtime-and-penalties":
            case "2nd-half-draw-no-bet":
            case "2nd-half-draw-no-bet-incl-overtime":
            case "which-team-has-the-first-attack":
            case "race-to-goalnr-goals-incl-overtime":
            case "v1-tko-prolazi-dalje-500":
            case "v1-duel-strijelaca-528":
            case "v1-hednicap-gamova-u-mecu-523":
            case "head2head":
            case "spread":
                this.tips = TipConfiguration.get({ configName: "winner" });
                break;
            case "setnr-set-winner":
                this.tips = TipConfiguration.get({ configName: "winnerWithSet" })
                break;
            case "gamenr-set-winner":
                this.tips = TipConfiguration.get({ configName: "winnerWithGame" })
                break;
            case "goalnr-goal":
            case "1st-half-goalnr-goal":
            case "2nd-half-goalnr-goal":
            case "overtime-goalnr-goal":
            case "penalty-shootout-goalnr-goal":
            case "last-goal":
            case "last-corner":
            case "1st-half-last-corner":
            case "first-team-to-score":
            case "next-carded-team":
            case "cornernr-corner":
                this.tips = TipConfiguration.get({ configName: "winnerNone" });
                break;
            case "which-team-wins-the-coin-toss-and-the-match":
                this.tips = TipConfiguration.get({ configName: "winnerNeither" });
                break;
            case "winner-total-incl-overtime":
            case "winner-total":
            case "team-over-under":
                this.tips = TipConfiguration.get({ configName: "winnerOverUnder" });
                break;
            case "over-under":
            case "over-under-home-team":
            case "over-under-away-team":
            case "total-points":
            case "total-incl-overtime":
            case "total-incl-extra-innings":
            case "total-180s":
            case "over-under-games-in-the-match":
            case "1st-half-totals":
            case "1st-half-competitor1-total":
            case "2nd-half-competitor2-total":
            case "2nd-half-competitor1-total":
            case "1st-half-competitor2-total":
            case "2nd-half-over-under":
            case "periodnr-period-total":
            case "setnr-set-over-under":
            case "competitor2-total-incl-overtime":
            case "competitor1-total-incl-overtime":
            case "innings-1-to-5th-top-competitor1-total":
            case "setnr-set-total-games":
            case "competitor1-total-games":
            case "competitor2-total-games":
            case "over-under-corners":
            case "1st-half-over-under-corners":
            case "overtime-total":
            case "2nd-half-total-incl-overtime":
            case "quarternr-quarter-total":
            case "competitor1-total-corners":
            case "competitor2-total-corners":
            case "1st-half-competitor1-total-corners":
            case "1st-half-competitor2-total-corners":
            case "total-bookings":
            case "1st-half-total-bookings":
            case "2nd-half-total-bookings":
            case "competitor1-total-bookings":
            case "competitor2-total-bookings":
            case "1st-half-competitor1-total-bookings":
            case "1st-half-competitor2-total-bookings":
            case "1st-half-total-booking-points":
            case "total-frames":
            case "total-legs-for-setnr-set":
            case "framenr-frame-total-points":
            case "competitor1-total-180s":
            case "competitor2-total-180s":
            case "v1-vise-manje-gamova-u-1-setu-524":
            case "v1-vise-manje-koseva-527":
            case "inningnr-innings-total":
            case "inningnr-innings-over-overnr-deliverynr-delivery-competitor1-total":
            case "inningnr-innings-competitor1-total":
            case "inningnr-innings-odd-even":
            case "competitor2-total-tries":
            case "total-tries":
            case "competitor1-total-tries":
            case "quarternr-quarter-competitor1-total":
            case "quarternr-quarter-competitor2-total":
            case "player-total-assists":
            case "player-total-rebounds":
            case "player-total-3-point-field-goals":
            case "player-total-points":
                this.tips = TipConfiguration.get({ configName: "overUnder" });
                this.isMargin = true;
                break;
            case "1st-half-correct-score":
                this.tips = TipConfiguration.get({ configName: "correctScoreOther", from: 2, to: 2 });
                break;
            case "correct-score-score-max-6":
                this.tips = TipConfiguration.get({ configName: "correctScore", from: 6, to: 6 });
                break;
            case "correct-score":
                this.tips = TipConfiguration.get({ configName: "correctScoreOther", from: 6, to: 6 });
                break;
            case "1st-half-sending-off":
            case "any-set-to-nil":
            case "1st-half-both-teams-score-a-goal":
            case "2nd-half-both-teams-to-score":
            case "home-team-receives-no-goal":
            case "away-team-receives-no-goal":
            case "will-both-teams-score":
            case "both-halves-under-total":
            case "both-halves-over-total":
            case "1st-half-first-try-converted":
            case "player-to-score-incl-overtime":
            case "competitor1-or-any-clean-sheet":
            case "tiebreak-in-match":
            case "any-team-to-miss-a-field-goal":
            case "competitor1-to-score-in-all-periods":
            case "draw-or-any-clean-sheet":
            case "any-team-to-score-a-safety-incl-overtime":
            case "away-team-to-score":
            case "competitor2-to-score-in-all-periods":
            case "2nd-half-first-try-converted":
            case "competitor1-to-win-exactly-1-set":
            case "draw-or-both-teams-to-score":
            case "current-period-either-team-to-score":
            case "first-try-converted":
            case "first-team-to-score-wins-game-yes-no":
            case "bonus-ball-single-digit":
            case "competitor2-to-win-exactly-1-set":
            case "both-teams-to-score-twice":
            case "home-team-to-score":
            case "bonus-ball-equals-any-regular-ball":
            case "match-to-go-to-shootout":
            case "next-away-penalty-scored":
            case "competitor2-or-any-clean-sheet":
            case "any-team-win-to-nil-incl-extra-innings":
            case "tiebreak-in-match":
            case "will-both-teams-score":
            case "both-teams-to-score-for-periodnr-period":
            case "will-there-be-an-ultra-kill":
            case "will-there-be-a-deciding-frame":
            case "will-there-be-a-tiebreak-in-the-setnr-set":
            case "will-there-be-a-rampage":
            case "will-there-be-a-grand-slam-incl-extra-innings":
            case "will-there-be-a-drop-goal":
            case "will-there-be-a-tie":
            case "will-there-be-a-super-over":
            case "will-there-be-overtime":
            case "will-there-be-overtime":
            case "will-there-be-an-extra-inning":
            case "will-there-be-a-goal-in-overtime":
            case "will-there-be-a-penalty":
            case "will-there-be-a-red-card":
            case "will-there-be-a-5th-set":
            case "will-there-be-a-4th-set":
            case "will-competitor1-win-a-set":
            case "will-competitor2-win-a-set":
            case "competitor1-clean-sheet-incl-overtime-and-penalties":
            case "competitor2-clean-sheet-incl-overtime-and-penalties":
            case "competitor1-to-win-exactly-2-sets":
            case "competitor2-to-win-exactly-2-sets":
            case "competitor1-to-win-all-periods":
            case "competitor2-to-win-all-periods":
            case "competitor1-to-win-any-period":
            case "competitor2-to-win-any-period":
            case "competitor1-to-win":
            case "competitor2-to-win":
            case "competitor1-to-bat-in-9th-inning":
            case "competitor2-to-bat-in-9th-inning":
            case "competitor1-or-both-teams-to-score":
            case "competitor2-or-both-teams-to-score":
            case "competitor1-win-to-nil":
            case "competitor2-win-to-nil":
            case "competitor1-to-win-either-half":
            case "competitor2-to-win-either-half":
            case "competitor1-to-win-both-halves":
            case "competitor2-to-win-both-halves":
            case "competitor1-to-score-in-both-halves":
            case "competitor2-to-score-in-both-halves":
            case "competitor1-sending-off":
            case "competitor2-sending-off":
            case "1st-half-competitor1-sending-off":
            case "1st-half-competitor2-sending-off":
            case "1st-half-competitor1-clean-sheet":
            case "1st-half-competitor2-clean-sheet":
            case "2nd-half-competitor1-clean-sheet":
            case "2nd-half-competitor2-clean-sheet":
            case "2st-half-competitor1-sending-off":
            case "2st-half-competitor2-sending-off":
            case "framenr-frame-competitor1-break-50":
            case "framenr-frame-competitor2-break-50":
            case "framenr-frame-competitor1-break-100":
            case "framenr-frame-competitor2-break-100":
            case "framenr-frame-will-there-be-a-foul-committed":
            case "framenr-frame-break-50":
            case "framenr-frame-break-100":
            case "setnr-set-leg-legnr-any-player-to-score-a-180":
            case "setnr-set-leg-legnr-competitor1-to-score-a-180":
            case "setnr-set-leg-legnr-competitor2-to-score-a-180":
            case "setnr-set-leg-legnr-checkout-score-score":
            case "v1-hoce-li-obe-ekipe-postici-pogodak-u-1-poluvremenu-501":
            case "v1-domacin-postize-barem-1-pogodak-502":
            case "v1-gost-postize-barem-1-pogodak-503":
            case "v1-domacin-postize-pogodak-u-1-poluvremenu-504":
            case "v1-gost-postize-pogodak-u-1-poluvremenu-505":
            case "v1-hoce-li-bilo-koja-ekipa-pobijediti-s-jednim-golom-razlika-507":
            case "v1-hoce-li-bilo-koja-ekipa-pobijediti-s-dva-gola-razlike-508":
            case "v1-hoce-li-bilo-koja-ekipa-pobijediti-s-tri-gola-razlike-509":
            case "v1-hoce-li-biti-penal-na-utakmici-510":
            case "v1-hoce-li-biti-zutog-kartona-izmedju-512":
            case "v1-hoce-li-biti-prekrsaj-izmedju-513":
            case "v1-hoce-li-biti-tie-brake-u-1-setu-525":
            case "v1-hoce-li-biti-tie-brake-u-mecu-526":
            case "competitor1-to-win-exactly-2-setsd":
            case "competitor2-to-win-exactly-2-setsd":
            case "will-the-fight-go-the-distance":
            case "any-team-to-win":
            case "any-team-to-score-a-2-point-conversion-incl-overtime":
            case "next-home-penalty-scored":
                this.tips = TipConfiguration.get({ configName: "yesNo" });
                break;
            case "both-teams-to-score-over-under":
                this.tips = TipConfiguration.get({ configName: "yesNoOverUnder" });
                this.isMargin = true;
                break;
            case "handicap":
            case "handicap-incl-overtime":
            case "handicap-incl-extra-innings":
            case "handicap-incl-overtime-and-penalties":
            case "set-handicap":
            case "setnr-set-handicap":
            case "1st-half-handicap":
            case "2nd-half-handicap":
            case "2nd-half-handicap-incl-overtime":
            case "corner-handicap":
            case "1st-half-corner-handicap":
            case "inningnr-inning-handicap":
            case "overtime-1st-half-handicap":
            case "overtime-handicap":
            case "match-game-handicap":
            case "setnr-set-game-handicap":
            case "point-handicap":
            case "frame-handicap":
            case "quarternr-quarter-draw-no-bet":
            case "point-handicap-hcp-incl-overtime":
            case "race-to-pointnr-points":
                this.tips = TipConfiguration.get({ configName: "awayHome" });
                this.isMargin = true;
                break;
            case "odd-even-goals":
            case "1st-half-odd-even":
            case "odd-even-corners":
            case "1st-half-odd-even-corners":
            case "odd-even-incl-overtime":
            case "odd-even":
            case "2nd-half-odd-even":
            case "odd-even-incl-overtime-and-penalties":
            case "odd-even-incl-extra-innings":
            case "odd-even":
            case "2nd-half-odd-even-incl-overtime":
            case "competitor-odd-even-strokes":
            case "odd-even-sets":
            case "runs-odd-even":
            case "1st-half-competitor1-odd-even":
            case "penalty-shootout-competitor2-odd-even":
            case "competitor1-odd-even-incl-overtime":
            case "competitor1-odd-even":
            case "competitor2-odd-even":
            case "2nd-half-competitor2-odd-even":
            case "away-team-runs-odd-even":
            case "odd-even-frames":
            case "bonus-ball-odd-even":
            case "competitor2-odd-even-incl-overtime":
            case "home-team-runs-odd-even":
            case "draw-sum-odd-even":
            case "1st-half-competitor2-odd-even":
            case "bonus-ball-sum-odd-even":
            case "penalty-shootout-odd-even":
            case "2nd-half-competitor1-odd-even":
            case "odd-even-tries":
            case "penalty-shootout-competitor1-odd-even":
            case "odd-even-games":
            case "1st-half-odd-even-tries":
            case "1st-over-odd-even":
            case "quarternr-quarter-odd-even":
            case "setnr-set-odd-even":
            case "framenr-frame-odd-even":
            case "inningnr-innings-over-overnr-competitor1-odd-even":
                this.tips = TipConfiguration.get({ configName: "oddEven" });
                break;
            case "halftime-fulltime":
            case "double-result-1st-set-match":
            case "1x2-and-first-team-to-score":
                this.tips = TipConfiguration.get({ configName: "htFt" });
                break;
            case "goalnr-goal-1x2":
                this.tips = TipConfiguration.get({ configName: "htFtNoGoal" });
                break;
            case "double-chance-both-teams-to-score":
            case "2nd-half-double-chance-both-teams-to-score":
            case "double-chance-match-1st-half-both-teams-score":
            case "1st-half-double-chance-both-teams-to-score":
            case "double-chance-match-2nd-half-both-teams-score":
                this.tips = TipConfiguration.get({ configName: "doubleChanceYesNo" });
                break;
            case "1st-2nd-half-both-teams-to-score":
                this.tips = TipConfiguration.get({ configName: "htFtYesNo" });
                break;
            case "total-goals-aggregated":
                this.tips = TipConfiguration.get({ configName: "goalsAggregated" });
                break;
            case "goals-away-team":
            case "goals-home-team":
                this.tips = TipConfiguration.get({ configName: "upTo", to: 3 });
                break;
            // NOTE: add specific configuration before default
            default:
                // if betting type is not defined, tips need to be provided and are sorted automatically using .sort() function
                this.tips = new TipConfiguration(inputTips, new TipOptions(true));
                break;
        }
    }
}