import { action, decorate } from "mobx";

import { LivePageableOfferStore } from ".";
import { DefaultLiveUpcomingPageableConfiguration, ILiveUpcomingPageableOfferConfiguration } from "..";
import { PageRowElement } from "../..";

class LiveUpcomingPageableOfferStore extends LivePageableOfferStore {
	configuration: ILiveUpcomingPageableOfferConfiguration;

	constructor(configuration?: ILiveUpcomingPageableOfferConfiguration) {
		super(Object.assign({}, new DefaultLiveUpcomingPageableConfiguration(), configuration));
	}

	updatePageData() {
		let pd: PageRowElement[] = [
			PageRowElement.create('upcoming-0', 'header')
		];
		const pages = [pd];

		this.events.forEach((event, idx) => {
			if (pd.length >= this.configuration.rowCount) {
				pd = [];
				pages.push(pd);

				// id here is irrelevant since the header is always going to be the same
				// and only one header is being displayed per page
				pd.push(PageRowElement.create('upcoming-' + (idx + 1), 'header'));
			}
			// all events always take one space
			pd.push(PageRowElement.create(event.id, 'event'));
		});

		this.pageData = pages;
	}
}

decorate(LiveUpcomingPageableOfferStore, {
	updatePageData: action.bound
});

export {
	LiveUpcomingPageableOfferStore
}