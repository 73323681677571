import {
	createMoneyline,
	createSpread,
	createTotal,
} from "../../groups";

import { Rule } from "../../../models/rules";

export const highlights = new Rule(
	[],
	[
		createSpread(1),
		createTotal(2),
		createMoneyline(3),
	],
);

export const lastMinute = new Rule(
	[],
	[
		createSpread(1),
		createTotal(2),
		createMoneyline(3),
	]
)

export const upcoming = new Rule(
	[],
	[
		createSpread(1),
		createTotal(2),
		createMoneyline(3),
	]
)

export const mostPlayed = new Rule(
	[],
	[
		createSpread(1),
		createTotal(2),
		createMoneyline(3),
	]
)