import { resultPlaceholder } from '../../constants';
import { MappingGroup } from './MappingGroup'

import * as tips from './tips';

export {
	MappingGroup
}

export const createThreeWay = (priority: number) => new MappingGroup("3way", tips.threeWay, ["3way", "overtime-1x2", "penalty-shootout-winner"], ["who-wins-1st-half", "overtime-1st-half-1x2"], priority);
export const createThreeWayOvertime = (priority: number) => new MappingGroup("3way", tips.threeWay, ["overtime-1x2"], ["overtime-1st-half-1x2"], priority);
export const createWinner = (priority: number) => new MappingGroup("winner", tips.winner, ["winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "penalty-shootout-winner"], [], priority);
export const createWinnerInclSuperover = (priority: number) => new MappingGroup("winner-incl-superover", tips.winner, ["winner-incl-super-over"], [], priority);
export const createThreeWayWinner = (priority: number) => new MappingGroup("3way", tips.threeWay, ["3way", "overtime-1x2", "winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "penalty-shootout-winner", 'which-team-wins-the-rest-of-the-match', "setnr-set-which-player-wins-the-rest"], ["who-wins-1st-half", "overtime-1st-half-1x2"], priority);
export const createWinnerThreeWay = (priority: number) => new MappingGroup("winner", tips.threeWay, ["3way", "overtime-1x2", "winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "penalty-shootout-winner"], ["who-wins-1st-half", "overtime-1st-half-1x2"], priority);
export const createHeadToHead = (priority: number) => new MappingGroup("head-to-head", tips.winner, ["winner", "head2head", "head2head-1x2", "head2head-teams"], [], priority);
export const createMainTip = (priority: number) => new MappingGroup("3way", tips.threeWay, ["3way", "overtime-1x2", "winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "head2head", "head2head-1x2", "head2head-teams", "penalty-shootout-winner"], ["who-wins-1st-half"], priority);
export const createMainTipWithOT = (priority: number) => new MappingGroup("1x2-incl-overtime", tips.threeWay, ["1x2-incl-overtime", "overtime-1x2", "winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "head2head", "head2head-1x2", "head2head-teams", "penalty-shootout-winner"], ["who-wins-1st-half"], priority);
export const createRestOfMatchWinner = (priority: number) => new MappingGroup("which-team-wins-the-rest-of-the-match", tips.threeWay, ["which-team-wins-the-rest-of-the-match", "overtime-which-team-wins-the-rest"], ["1st-half-which-team-wins-the-rest"], priority);
export const createRestOfFirstHalfWinner = (priority: number) => new MappingGroup("1st-half-which-team-wins-the-rest", tips.threeWay, ["1st-half-which-team-wins-the-rest"], [], priority);
export const createDrawNoBet = (priority: number) => new MappingGroup("draw-no-bet", tips.winner, ["draw-no-bet"], ["1st-half-draw-no-bet"], priority);
export const createFirstHalfDrawNoBet = (priority: number) => new MappingGroup("1st-half-draw-no-bet", tips.winner, ["1st-half-draw-no-bet"], [], priority);
export const createNextGoal = (priority: number) => new MappingGroup("goalnr-goal", tips.threeWay, ["goalnr-goal", "overtime-goalnr-goal"], ["1st-half-goalnr-goal"], priority);
export const createFirstHalfNextGoal = (priority: number) => new MappingGroup("1st-half-goalnr-goal", tips.threeWay, ["1st-half-goalnr-goal"], [], priority);
export const createWillBothTeamsScore = (priority: number) => new MappingGroup("will-both-teams-score", tips.yesNo, ["will-both-teams-score"], [], priority);
export const createOverUnder = (priority: number) => new MappingGroup("over-under", tips.overUnder, ["over-under", "total-incl-overtime", "total-incl-extra-innings", "overtime-total"], ["1st-half-totals"], priority);
export const createOverUnderGamesInTheMatch = (priority: number) => new MappingGroup("over-under-games-in-the-match", tips.overUnder, ["over-under-games-in-the-match"], [], priority);
export const createSetOverUnder = (priority: number) => new MappingGroup("setnr-set-over-under", tips.overUnder, ["setnr-set-over-under"], [], priority);
export const createQuarterTotal = (priority: number) => new MappingGroup("quarternr-quarter-total", tips.overUnder, ["quarternr-quarter-total"], [], priority);
export const createPeriodTotals = (priority: number) => new MappingGroup("periodnr-period-total", tips.overUnder, ["periodnr-period-total"], [], priority);
export const createTotalPoints = (priority: number) => new MappingGroup("total-points", tips.overUnder, ["total-points"], [], priority);
export const createTotalFrames = (priority: number) => new MappingGroup("total-frames", tips.overUnder, ["total-frames"], [], priority);
export const createPointHandicap = (priority: number) => new MappingGroup("point-handicap", tips.homeAway, ["point-handicap"], [], priority);
export const createFrameHandicap = (priority: number) => new MappingGroup("frame-handicap", tips.homeAway, ["frame-handicap"], [], priority);
export const createFrameTotalPoints = (priority: number) => new MappingGroup("framenr-frame-total-points", tips.overUnder, ["framenr-frame-total-points"], [], priority);
export const createFrameBreak50 = (priority: number) => new MappingGroup("framenr-frame-break-50", tips.yesNo, ["framenr-frame-break-50"], [], priority);
export const createFirstHalfTotals = (priority: number) => new MappingGroup("1st-half-totals", tips.overUnder, ["1st-half-totals"], [], priority);

/**
 * Normal handicap: handicap-hcp (margin, 1, x, 2)
 */
export const createHandicap = (priority: number) => new MappingGroup("handicap-hcp", tips.threeWayMargin, ["handicap-hcp", "overtime-handicap", "handicap-incl-overtime"], ["1st-half-handicap-hcp", "overtime-1st-half-handicap"], priority);
export const createFirstHalfHandicap = (priority: number) => new MappingGroup("1st-half-handicap-hcp", tips.threeWayMargin, ["1st-half-handicap-hcp"], [], priority);
export const createDoubleChance = (priority: number) => new MappingGroup("double-chance", tips.doubleChance, ["double-chance"], [], priority);

/**
 * Asian handicap (margin, 1, 2)
 */
export const createAsianHandicap = (priority: number) => new MappingGroup("handicap", tips.homeAway, ["handicap", "handicap-incl-overtime", "handicap-incl-extra-innings"], ["1st-half-handicap", "overtime-1st-half-handicap"], priority);
export const createHandicapInclOvertime = (priority: number) => new MappingGroup("handicap-incl-overtime", tips.homeAway, ["handicap-incl-overtime"], ["1st-half-handicap"], priority);
export const createFirstHalfAsianHandicap = (priority: number) => new MappingGroup("1st-half-handicap", tips.homeAway, ["1st-half-handicap"], [], priority);
export const createSetWinner = (priority: number) => new MappingGroup("setnr-set-winner", tips.winner, ["setnr-set-winner"], [], priority);
export const createSetLegWinner = (priority: number) => new MappingGroup("setnr-set-leg-legnr-winner", tips.winner, ["setnr-set-leg-legnr-winner"], [], priority);
export const createSetWhoWinsRest = (priority: number) => new MappingGroup("setnr-set-which-player-wins-the-rest", tips.threeWay, ["setnr-set-which-player-wins-the-rest"], [], priority);
export const createSetTotalGames = (priority: number) => new MappingGroup("setnr-set-total-games", tips.overUnder, ["setnr-set-total-games"], [], priority);
export const createSetHandicap = (priority: number) => new MappingGroup("set-handicap", tips.homeAway, ["set-handicap"], [], priority);
export const createSetLegHandicap = (priority: number) => new MappingGroup("setnr-set-leg-handicap", tips.homeAway, ["setnr-set-leg-handicap"], [], priority);
export const createGameWinner = (priority: number) => new MappingGroup("gamenr-set-winner", tips.winner, ["gamenr-set-winner"], [], priority);
export const createMatchGameHandicap = (priority: number) => new MappingGroup("match-game-handicap", tips.homeAway, ["match-game-handicap"], [], priority);
export const createResult = (priority: number) => new MappingGroup("result", [], [resultPlaceholder], [], priority);
export const createWhoWinsPeriod = (priority: number) => new MappingGroup("who-wins-period-periodnr", tips.threeWayMargin, ["who-wins-period-periodnr"], [], priority);
export const createWhoWinsQuarter = (priority: number) => new MappingGroup("quarternr-quarter-1x2", tips.threeWayMargin, ["quarternr-quarter-1x2"], [], priority);
export const createFirstHalfWinner = (priority: number) => new MappingGroup("who-wins-1st-half", tips.threeWay, ["who-wins-1st-half"], [], priority);
export const createPeriodWinners = (priority: number) => new MappingGroup("period-winner", tips.threeWayMargin, ["who-wins-period-periodnr", "setnr-set-winner", "gamenr-set-winner", "framenr-frame-winner", "mapnr-map-winner", "mapnr-map-winner-incl-overtime"], [], priority);
export const createNextScore = (priority: number) => new MappingGroup("next-score", tips.threeWay, ["goalnr-goal", "pointnr-point-incl-overtime", "first-team-to-score"], [], priority);
export const createMost180s = (priority: number) => new MappingGroup("most-180s", tips.threeWay, ["most-180s"], [], priority);
export const createTotal180s = (priority: number) => new MappingGroup("total-180s", tips.overUnder, ["total-180s"], [], priority);
export const createFrameWinner = (priority: number) => new MappingGroup("framenr-frame-winner", tips.winner, ["framenr-frame-winner"], [], priority);

export const createInningWinners = (priority: number) => new MappingGroup("inningnr-inning-1x2", tips.threeWayMargin, ["inningnr-inning-1x2"], [], priority);
export const createInningTotal = (priority: number) => new MappingGroup("inningnr-inning-total", tips.overUnder, ["inningnr-inning-total"], [], priority);
export const createInningTotalHits = (priority: number) => new MappingGroup("inningnr-inning-total-hits", tips.overUnder, ["inningnr-inning-total-hits"], [], priority);

export const createMainTipBasketballLive = (priority: number) => new MappingGroup("winner", tips.winner, ["3way", "winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "penalty-shootout-winner",], [], priority);
export const createMainTipBasketballPrematch = (priority: number) => new MappingGroup("1x2-incl-overtime", tips.threeWay, ["3way", "1x2-incl-overtime", "overtime-1x2", "winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "head2head", "head2head-1x2", "head2head-teams", "penalty-shootout-winner"], ["who-wins-1st-half"], priority);
