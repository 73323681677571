import { decorate, observable, action } from 'mobx';
import { EventKey, Key } from "@gp/models";

const ALTERNATE_BETTING_TYPES = [
    "team-point-over-under",
    "handicap-incl-overtime",
    "total-incl-overtime",
    "team-over-under",
    "point-handicap-hcp-incl-overtime",
	"spread"
]

class EventKeyOffer extends EventKey {
	constructor(key: EventKey) {
		super();

		this.id = key.id;
		this.bettingTypeId = key.bettingTypeId;
		this.bettingType = key.bettingType;
		this.eventId = key.eventId;
		this.isFavorite = !!key.isFavorite;
		this.isLocked = !!key.isLocked;
		this.specifier = key.specifier;

		this.setAlternate();
	}

	/**
	 * Update key data. Only lock and favorite can be changed!
	 * @param key new key data
	 */
	update(key: Key) {
		if (key.isFavorite !== this.isFavorite) {
			this.isFavorite = key.isFavorite;
			this.setAlternate();
		}
		
		if (key.isLocked !== this.isLocked) {
			this.isLocked = key.isLocked;
		}
	}

	setAlternate(){
		if (ALTERNATE_BETTING_TYPES.includes(this.bettingType?.abrv)) {
			this.isAlternate = !this.isFavorite;
		} else {
			this.isAlternate = false;
		}
	}
}

decorate(EventKeyOffer, {
	isFavorite: observable,
	isLocked: observable,
	isAlternate: observable,
	update: action.bound,
	setAlternate: action.bound,
})

export {
	EventKeyOffer
}