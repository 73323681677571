import { decorate, computed, observable, action } from "mobx";

import {
    EventKey,
    Sport,
} from "@gp/models";
import { getIntersection, insert } from "@gp/utility";

type OfferKeys = Map<string, EventKey>;

const NumericCollator = Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

class GroupStore {
    /**
     * Group id
     * @see AdditionalOfferStore.bettingGroups
     */
    id: string;

    /**
     * Group name
     */
    name: string;
    /**
     * Related sport
     */
    sport: Sport;

    /**
     * Group offer keys
     */
    keys: OfferKeys;

    /**
     * Groups sort order
     */
    sortOrder: number = Infinity;

    /**
     * Gets sorted betting offers by betting type sort order in this group
     */
    get sortedBettingOffer(): EventKey[] {
        const sorted: EventKey[] = [];

        this.keys.forEach(key => {
            insert(sorted, key, (a, b) => {
                const aSort = a.bettingType.settingsPerSport ? a.bettingType.settingsPerSport[this.sport.id]?.sortOrder || Infinity : Infinity;
                const bSort = b.bettingType.settingsPerSport ? b.bettingType.settingsPerSport[this.sport.id]?.sortOrder || Infinity : Infinity;

                if (aSort < bSort) return -1;
                if (aSort > bSort) return 1;

                // then sort by specifier value (if any)
                if (a.specifier != null && b.specifier != null) {
                    // get valid specifier
                    const matchingSpecifiers = getIntersection(Object.keys(a.specifier), Object.keys(b.specifier));

                    // no matching specifiers found!
                    if (matchingSpecifiers.length === 0) {
                        return 0;
                    }

                    return matchingSpecifiers.reduce((acc, ms) => {
                        return NumericCollator.compare(a.specifier?.[ms] as string, b.specifier?.[ms] as string);
                    }, 0) as any;
                }

                return 0;
            });
        });

        return sorted;
    }

    constructor(id: string, name: string, sport: Sport, sortOrder: number = Infinity) {
        this.id = id;
        this.name = name;
        this.sport = sport;
        this.sortOrder = sortOrder;

        this.keys = observable.map<string, EventKey>(undefined, { name: this.name + '-keys' });
    }

    /**
     * Adds or updates key in the group if it exists
     * @param key Key to add
     */
    addOrUpdate(key: EventKey) {
        this.keys.set(key.id, key);
    }

    /**
     * Removes key from the group if it exists
     * @param keyId key id to remove
     */
    remove(keyId: string) {
        this.keys.delete(keyId);
    }

    /**
     * Clear group keys
     */
    clear() {
        this.keys.clear();
    }
}

decorate(GroupStore, {
    keys: observable,
    sortOrder: observable,
    sortedBettingOffer: computed,
    addOrUpdate: action.bound,
    remove: action.bound,
    clear: action.bound,
});

export {
    GroupStore
}