import { DefaultConfiguration } from ".";
import { IInMemoryPagingOfferConfiguration } from "./IInMemoryPagingOfferConfiguration";

export class DefaultInMemoryPagingOfferConfiguration extends DefaultConfiguration implements IInMemoryPagingOfferConfiguration {
	rowCount: number;

	constructor() {
		super();

		this.rowCount = 15;
	}
}