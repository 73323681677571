import { decorate, action, observable } from "mobx";

import {
    EventOffer as EventWithOffer,
    PageableOfferResponse,
    Event,
    Key,
    EventKeyBettingOffer,
    SportCategoryGroup,
    OtherOffer,
    KeyOffer
} from "@gp/models";

import { BaseOfferStore } from "./BaseOfferStore";
import { DefaultPageableConfiguration, IPageableOfferConfiguration } from "./configuration";
import { EventKeyOffer } from "..";
import { SportOtherOffer } from "../models/SportOtherOffer";
import { insert, Sorter } from "@gp/utility";
import { manipulatePageableOfferResponse } from '../utility/manipulateOfferResponse'

class MainPageableOfferStore extends BaseOfferStore<IPageableOfferConfiguration> {
    pageNumber: number;
    pageSize: number;
    totalItems: number;

    sportOtherOffer: Map<string, SportOtherOffer[]> = new Map<string, SportOtherOffer[]>();

    /**
     * Initializes new instance of MainPageableOfferStore
     * @param {IPageableOfferConfiguration} configuration
     */
    constructor(configuration?: Partial<IPageableOfferConfiguration>) {
        super(Object.assign({}, new DefaultPageableConfiguration(), configuration));

        this.pageNumber = 1;
        this.pageSize = this.configuration.pageSize || 10;
        this.totalItems = 0;
    }

    assignOfferData(data: PageableOfferResponse) {
        if (data == null) {
            return;
        }

        data = manipulatePageableOfferResponse(data, this);

        const {
            lookup,
            items,
            offer,
            pageNumber,
            pageSize,
            totalNumberOfItems,
            version
        } = data;

        // always reset prior to new offer mapping
        this.reset();

        this.logger.logTrace(`Version: ${version}`);

        // this should not happen
        if (version < this.version) {
            this.logger.logWarn(`Received older offer - skipping. Received: ${version} | Current: ${this.version}`)
            return;
        }

        this.version = version;

        this.pageNumber = pageNumber || 1;
        this.pageSize = pageSize || 10;
        this.totalItems = totalNumberOfItems || 0;

        if (lookup != null && !lookup.isEmpty) {
            this.lookupsStore.update(lookup);
        }
        else {
            this.logger.logInfo("Did not receive lookups.");
        }

        if (items != null && items.length > 0) {
            items.forEach(item => this.processGrouppedItems(item));
        }
        else if (offer != null && offer.length > 0) {
            offer.forEach(item => this.processEventOffer(new EventWithOffer(item)));
        }
        else {
            this.logger.logWarn("Did not receive offer.");
        }
    }

    processGrouppedItems(sportCategoryGroup: SportCategoryGroup) {
        if (sportCategoryGroup.items != null && sportCategoryGroup.items.length > 0) {
            sportCategoryGroup.items.forEach(tournamentOffer => {
                // map events
                tournamentOffer.events.forEach(e => this.processEventOffer(new EventWithOffer(e)));
            });
        }

        if (sportCategoryGroup.otherOffers != null && sportCategoryGroup.otherOffers.length > 0) {
            this.processOtherOffer(sportCategoryGroup.sportId, sportCategoryGroup.otherOffers);
        }
    }

    processOtherOffer(sportId: string, otherOffers: OtherOffer[]) {
        const sportOtherOffer: SportOtherOffer[] = [];

        const favoriteOtherOfferBettingTypeIds: Set<string> = new Set<string>(otherOffers.filter(i => i.isFavorite).map(i => i.bettingTypeId));

        for (let oo of otherOffers) {
            // we ignore non favorite in case it has favorite counterpart because it shouldn't display as other offer option!
            if (!oo.isFavorite && favoriteOtherOfferBettingTypeIds.has(oo.bettingTypeId)) {
                continue;
            }

            const bettingType = this.lookupsStore.bettingTypes.get(oo.bettingTypeId);

            if (bettingType == null) {
                throw `Expected to have betting type with id ${oo.bettingTypeId} in lookups.`
            }

            const soo = new SportOtherOffer(oo.bettingTypeId, bettingType, oo.count);

            insert(sportOtherOffer, soo, Sorter.sort(
                // Sort by sort order specified by sport settings
                (a, b) => {
                    if (a.bettingType.settingsPerSport != null && a.bettingType.settingsPerSport[sportId] != null &&
                        b.bettingType.settingsPerSport != null && b.bettingType.settingsPerSport[sportId] != null) {
                        return a.bettingType.settingsPerSport[sportId].sortOrder - b.bettingType.settingsPerSport[sportId].sortOrder;
                    }

                    return 0;
                },
                (a, b) => {
                    if (a.bettingTypeId < b.bettingTypeId) return -1;
                    if (a.bettingTypeId > b.bettingTypeId) return 1;

                    return 0;
                }
            ));
        }

        this.sportOtherOffer.set(sportId, sportOtherOffer);
    }

    /**
     * Process entire event offer
     * @param eventOffer event offer
     */
    processEventOffer(eventOffer: EventWithOffer) {
        if (eventOffer.event !== undefined) {
            this.setEvent(eventOffer.event);
        }

        if (eventOffer.offers !== undefined && eventOffer.offers.length > 0) {
            eventOffer.offers.forEach(keyOffer => {
                if (keyOffer.key !== undefined) {
                    this.setKey(eventOffer.eventId, keyOffer.key);
                }

                // add key offers
                if (keyOffer.offers !== undefined && keyOffer.offers.length > 0) {
                    this.processKeyOffers(new Event(eventOffer.event), keyOffer);
                }
            });
        }
    }

    /**
     * Process key offers
     * @param event event
     * @param keyOffer key offer
     */
    processKeyOffers(event: Event, keyOffer: KeyOffer) {
        const keyOffers = this.keyOffersMap.get(keyOffer.keyId) || new Map<string, EventKeyBettingOffer>();

        // check if all offers have same playerId, or if they have playerId at all
        let playerId: string | undefined = undefined;
        let teamIds: { teamOneId: string | undefined, teamTwoId: string | undefined } | undefined = undefined;

        if (keyOffer.offers?.every((o, i, src) => o.playerId != null && o.playerId !== '' && o.playerId === src[0].playerId)) {
            playerId = keyOffer.offers[0].playerId;
        }

        if (!event.isOutright) {
            teamIds = {
                teamOneId: event.teamOneId,
                teamTwoId: event.teamTwoId,
            }
        }

        let mappedOffers = keyOffer.offers?.map(o => {
            const tipInfo = this.getTipInformation({
                defaultTip: o.tip,
                bettingTypeId: keyOffer.bettingTypeId,
                playerId: o.playerId,
                teamId: o.teamId,
                specifiers: {
                    ...keyOffer.key?.specifier,
                    ...o.specifier
                },
                eventData: {
                    name: event.name,
                    playerId: playerId,
                    teamIds: teamIds
                }
            });

            return new EventKeyBettingOffer({
                ...o,
                keyId: keyOffer.keyId,
                eventId: event.id,
                isLocked: !!o.isLocked,
                tip: tipInfo.tip,
                displayTip: tipInfo.displayTip,
                gender: tipInfo.gender,
                teamAbrv: tipInfo.abrv,
                countryCode: tipInfo?.countryCode || ""
            });
        }) || [];

        if (event.isOutright) {
            const yesOfferExists = mappedOffers.some(offer => offer.teamAbrv?.toLowerCase() === 'yes' || offer.teamAbrv?.toLowerCase() === 'y');
            const noOfferExists = mappedOffers.some(offer => offer.teamAbrv?.toLowerCase() === 'no' || offer.teamAbrv?.toLowerCase() === 'n');
            const sortTipByOverUnder =
                // if all offers are over/under
                mappedOffers?.filter(offer => {
                    const teamAbrv = offer.teamAbrv?.toLowerCase();
                    return teamAbrv?.includes("over") || teamAbrv?.includes("under")
                }).length === mappedOffers?.length;

            if (yesOfferExists && noOfferExists) {
                mappedOffers = Sorter.sortOfferByTip(mappedOffers);
            } else if (sortTipByOverUnder) {
                mappedOffers = Sorter.sortOfferByOverUnder(mappedOffers);
            } else {
                mappedOffers = Sorter.sortOfferByValue(mappedOffers);
            }
        }

        mappedOffers.forEach(o => keyOffers?.set(o.id, o));

        this.keyOffersMap.set(keyOffer.keyId, keyOffers);
    }

    /**
     * Sets event data
     * @param event Event to update
     */
    setEvent(event: Event) {
        const newEvent = this.createNewEvent(event);

        this.eventsMap.set(event.id, newEvent);
    }

    /**
     * Sets key data
     * @param eventId event id
     * @param key key to add
     */
    setKey(eventId: string, key: Key) {
        const eventKeys = this.eventKeysMap.get(eventId) || new Map<string, EventKeyOffer>();
        const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

        if (keyBettingType == null) {
            throw `Expected to have betting type with ${key.bettingTypeId} id.`
        }

        const newKey = this.createNewKey(key, eventId, keyBettingType);
        eventKeys.set(key.id, newKey);

        this.eventKeysMap.set(eventId, eventKeys);
    }

    reset() {
        this.pageNumber = 1;
        this.pageSize = this.configuration.pageSize || 10;
        this.totalItems = 0;

        this.sportOtherOffer.clear();
        this.configuration.bettingTypeSelectorsStore.reset();

        super.reset();
    }
}

decorate(MainPageableOfferStore, {
    pageNumber: observable,
    pageSize: observable,
    totalItems: observable,
    sportOtherOffer: observable,
    assignOfferData: action,
    processEventOffer: action,
    processKeyOffers: action,
    setEvent: action,
    setKey: action,
    processGrouppedItems: action,
    processOtherOffer: action,
    reset: action
});

export {
    MainPageableOfferStore
}
