import { PageRowElement, RowSpan, RowType } from "./";

export class PrematchPageRowElement extends PageRowElement {
	constructor(id: string, rowType: RowType, categoryId: string, tournamentId: string, isOutright: boolean = false, rowSpan: RowSpan = 1) {
		super(id, rowType, rowSpan);

		this.categoryId = categoryId;
		this.tournamentId = tournamentId;
		this.isOutright = isOutright;
	}

	/**
	 * Sport category id
	 * Has value only if rowType is header
	 */
	categoryId: string;

	/**
	 * Tournament id
	 * Has value only if rowType is header
	 */
	tournamentId: string;

	/**
	 * Indicates if row should be displayed as outright
	 */
	isOutright: boolean;

	/** @deprecated use regular class constructor, this does it anyways. */
	static create(id: string, rowType: RowType, rowSpan: RowSpan = 1, categoryId: string = null, tournamentId: string = null, isOutright: boolean = false): PrematchPageRowElement {
		return new PrematchPageRowElement(id, rowType, categoryId, tournamentId, isOutright, rowSpan);
	}
}