import {
    createHandicap,
    createOverUnder,
    createPeriodTotals,
    createRestOfMatchWinner,
    createThreeWay,
    createWhoWinsPeriod,
} from "../../groups";

import { Rule } from "../../../models/rules";

export const iceHockey = new Rule(
    [
        "ice-hockey"
    ],
    [
        createThreeWay(1),
        createRestOfMatchWinner(2),
        createOverUnder(3),
        createHandicap(4),
        createPeriodTotals(5),
        createWhoWinsPeriod(6)
    ]
);