import { EventKey } from "@gp/models";
import { getIntersection } from "@gp/utility";

import { sortableSpecifiers } from '../mapper/models/rules';

const NumericCollator = Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

/**
 * Sorts event keys by specifier value.
 * @param eventKeys Array of event keys - must be within the same betting type
 * @returns Sorted event keys array
 */
export function getSortedEventKeysBySpecifierValue(eventKeys: EventKey[]): EventKey[] {
	if (eventKeys == null || eventKeys.length === 0) {
		return eventKeys;
	}

	// we can do this because all offers within the same key (betting type) have the same
	// specifiers. As an example, all keys in over-under type will have "total" specifier
	if (eventKeys[0].specifier == null) {
		return eventKeys;
	}

	// we need to find all specifiers that we can sort
	const matchingSpecifiers = getIntersection(Object.keys(eventKeys[0].specifier), sortableSpecifiers)

	if (matchingSpecifiers.length === 0) {
		return eventKeys;
	}

	// TODO: see if we can optimize this. This has time complexity of N * O(n log n) where N is number of matching specifiers and n is number of event keys
	// usually this won't be a problem because in practice we have 1 or 2 specifiers at most
	matchingSpecifiers.forEach(specifierKey => {
		eventKeys = eventKeys.sort((a: EventKey, b: EventKey) => {
			// This will work if value is undefined
			return NumericCollator.compare(a.specifier?.[specifierKey] as string, b.specifier?.[specifierKey] as string);
		});
	});

	return eventKeys;
}