import {
    marginPlaceholder,
    emptyPlaceholder,
    nonePlaceholder,
    setnrPlaceholder,
    gamenrPlaceholder,
    framenrPlaceholder
} from '../constants';


/**
 * Gets scores from 0:0 to home:away
 * @param home home score
 * @param away away score
 * @returns array of scores
 */
function getScoresTo(home: number, away: number): string[] {
    const output: string[] = [];

    for (let i = 0; i <= home; ++i) {
        for (let j = 0; j <= away; ++j) {
            output.push(`${i}:${j}`);
        }
    }

    return output;
}

/**
 * Gets number tip up to
 * @param num
 * @example
 * // returns ["0", "1", "2", "3+"]
 * getUpTo(3)
 */
function getUpTo(num: number): string[] {
    const output: string[] = [];

    for (let i = 0; i <= num; ++i) {
        let entry = `${i}`;

        if (i === num) {
            entry += '+';
        }

        output.push(entry);
    }

    return output;
}


interface ITipConfigurationParameter {
    configName: string;
    from?: number;
    to?: number;
    tips?: string[];
}

export interface ITipOptions {
    autoSort: boolean;
    /**
     * If true, you should display empty places in correct places for missing tip offers
     */
    displayPlaceholders: boolean;
}

class DefaultTipOptions implements ITipOptions {
    autoSort: boolean = false;
    displayPlaceholders: boolean = false;
}

export class TipOptions implements ITipOptions {
    autoSort: boolean;
    displayPlaceholders: boolean;

    constructor(autoSort: boolean = false, displayPlaceholders: boolean = false) {
        this.autoSort = autoSort || false;
        this.displayPlaceholders = displayPlaceholders || false;
    }
}

export class TipConfiguration {
    original: string[];
    sorted: string[];
    display: string[];

    tipOptions: ITipOptions;

    constructor(tips: string[], tipOptions: ITipOptions = new DefaultTipOptions()) {
        this.sorted = tips.filter(t => t !== marginPlaceholder && t !== emptyPlaceholder && t !== setnrPlaceholder && t !== framenrPlaceholder);

        if (tipOptions.autoSort) {
            const collator = Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
            this.sorted = this.sorted.sort(collator.compare);
        }

        this.original = tips;
        this.display = tips;
        this.tipOptions = tipOptions;
    }

    static get(config: ITipConfigurationParameter) {
        switch (config.configName) {
            case "3way":
                return new TipConfiguration(["2", "1", "x"], new TipOptions(false, true));
            case "3wayMargin":
                return new TipConfiguration([marginPlaceholder, "2", "1", "x"], new TipOptions(false, true));
            case "3wayOverUnder":
                return new TipConfiguration(["1/-", "x/-", "2/-", "1/+", "x/+", "2/+"]);
            case "3wayOverUnderMargin":
                return new TipConfiguration([marginPlaceholder, "1/-", "x/-", "2/-", "1/+", "x/+", "2/+"]);
            case "3wayYesNo":
                return new TipConfiguration(["1/y", "x/y", "2/y", "1/n", "x/n", "2/n"]);
            case "3wayAndYesNo":
                return new TipConfiguration(["1 & y", "x & y", "2 & y", "1 & n", "x & n", "2 & n"]);
            case "winner":
                return new TipConfiguration(["2", emptyPlaceholder, "1"], new TipOptions(false, true));
            case "winnerNone":
            case "awayNoneHome":
                const awayNoneHomeTips = new TipConfiguration(["2", nonePlaceholder, "1"], new TipOptions(false, true));
                awayNoneHomeTips.display = ["2", "1", "x"];
                return awayNoneHomeTips;
            case "winnerWithSet":
                return new TipConfiguration(["2", setnrPlaceholder, "1"], new TipOptions(false, true));
            case "winnerWithGame":
                return new TipConfiguration(["2", gamenrPlaceholder, "1"], new TipOptions(false, true));
            case "winnerNeither":
                return new TipConfiguration(["2", "1", "neither"]);
            case "winnerOverUnder":
                return new TipConfiguration(["1/-", "2/-", "1/+", "2/+"]);
            case "awayHome":
                return new TipConfiguration([marginPlaceholder, "2", "1"], new TipOptions(false, true));
            case "overUnder":
                return new TipConfiguration([marginPlaceholder, "+", "-"], new TipOptions(false, true));
            case "overUnderWinner":
                return new TipConfiguration(["+ & y", "- & y", "+ & n", "- & n"])
            case "doubleChance":
                const dbTips = new TipConfiguration(["1 or x", "1 or 2", "x or 2"], new TipOptions(false, true));
                dbTips.display = ["1X", "12", "X2"];
                return dbTips;
            case "doubleChanceYesNo":
                return new TipConfiguration(["1/x & y", "1/2 & y", "x/2 & y", "1/x & n", "1/2 & n", "x/2 & n"]);
            case "correctScore":
                return new TipConfiguration(getScoresTo(config.from || 0, config.to || 0));
            case "correctScoreOther":
                return new TipConfiguration([...getScoresTo(config.from || 0, config.to || 0), "other"]);
            case "yesNo":
                return new TipConfiguration(["y", emptyPlaceholder, "n"], new TipOptions(false, true));
            case "yesNoFull":
                return new TipConfiguration(["yes", emptyPlaceholder, "no"], new TipOptions(false, true));
            case "yesNoOverUnder":
                return new TipConfiguration([marginPlaceholder, "y & +", "n & +", "y & -", "n & -"]);
            case "htFt":
                return new TipConfiguration(["1/1", "x/1", "2/1", "1/x", "x/x", "2/x", "1/2", "x/2", "2/2"]);
            case "htFtNoGoal":
                const hftfTips = new TipConfiguration(["1/1", "1/x", "1/2", "2/1", "2/x", "2/2", 'no goal']);
                hftfTips.display = ["1/1", "1/x", "1/2", "2/1", "2/x", "2/2", "x/x"];
                return hftfTips;
            case "htFtYesNo":
                return new TipConfiguration(["yes/yes", "yes/no", "no/yes", "no/no"]);
            case "upTo":
                return new TipConfiguration(getUpTo(config.to || 0));
            case "upToOther":
                return new TipConfiguration([...getScoresTo(config.from || 0, config.to || 0), "other"]);
            case "oddEven":
                return new TipConfiguration(["odd", emptyPlaceholder, "even"], new TipOptions(false, true));
            case "goalsAggregated":
                return new TipConfiguration(["0-1", "2-3", "4-5", "6+"]);
            default:
                return new TipConfiguration(config.tips || [], new TipOptions(true));
        }
    }
}