import {
    OfferMapper as AmericanOfferMapper,
    defaultRules as americanDefaultRules,
    terminalRules as americanTerminalRules,
} from "./american";

import {
    OfferMapper as EuropeanOfferMapper,
    defaultRules,
    defaultRulesAt,
    terminalRules,
    terminalRulesAt,
} from "./european";

import * as europeanGroups from './european/groups';
import * as americanGroups from './american/groups';

import { TypeConfiguration as AmericanTypeConfiguration } from './american/TypeConfiguration';
import { TypeConfiguration as EuropeanTypeConfiguration } from './european/TypeConfiguration';

export class OfferConfigurationStore {
    display: "european" | "american" = "european";

    get OfferMapper() {
        switch (this.display) {
            case "european":
                return EuropeanOfferMapper;
            case "american":
                return AmericanOfferMapper;
            default:
                return EuropeanOfferMapper;
        }
    }

    get typeConfiguration() {
        switch (this.display) {
            case "european":
                return EuropeanTypeConfiguration;
            case "american":
                return AmericanTypeConfiguration;
            default:
                return EuropeanTypeConfiguration;
        }
    }

    get europeanGroups() {
        return europeanGroups;
    }

    get americanGroups() {
        return americanGroups;
    }

    constructor(display: "european" | "american") {
        this.display = display;
    }

    getEuropeanDefault() {
        return defaultRules;
    }

    getEuropeanWithResults() {
        return terminalRules;
    }

    getEuropeanWithoutNextGoalOnHockey() {
        return defaultRulesAt;
    }

    getEuropeanWithResultsWithoutNextGoalOnHockey() {
        return terminalRulesAt;
    }

    getAmericanDefault() {
        return americanDefaultRules;
    }

    getAmericanWithResults() {
        return americanTerminalRules;
    }
}