import {
	createAsianHandicap,
	createDoubleChance,
	createHandicap,
	createMainTip,
	createNextGoal,
	createOverUnder,
	createOverUnderGamesInTheMatch,
	createPeriodWinners,
} from "../../groups";

import { Rule } from "../../../models/rules";

export const highlights = new Rule(
	[],
	[
		createMainTip(1),
		createOverUnder(2),
		createDoubleChance(3),
		createHandicap(4),
		createAsianHandicap(5),
	],
);

export const lastMinute = new Rule(
	[],
	[
		createMainTip(1),
		createOverUnder(2)
	]
)

export const upcoming = new Rule(
	[],
	[
		createMainTip(1),
		createPeriodWinners(2),
		createNextGoal(3),
		createOverUnder(4),
		createOverUnderGamesInTheMatch(5)
	]
)

export const mostPlayed = new Rule(
	[],
	[
		createMainTip(1),
		createOverUnder(2)
	]
)