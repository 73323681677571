import { idComparer } from "@gp/utility";

import { EventOffer } from "../models";
import { LookupsStore } from "../stores";
import { getSortOrderOrDefault } from "./getSortOrderOrDefault";

export function getEventSortBySportCategory(
  a: EventOffer,
  b: EventOffer,
  lookupsStore: LookupsStore
) {
  if (a.sportCategoryId != b.sportCategoryId) {
    const aSportCategory = lookupsStore.categories.get(a.sportCategoryId);
    const bSportCategory = lookupsStore.categories.get(b.sportCategoryId);
    if (getSortOrderOrDefault(aSportCategory?.sortOrder) < getSortOrderOrDefault(bSportCategory?.sortOrder)) return -1;
    if (getSortOrderOrDefault(aSportCategory?.sortOrder) > getSortOrderOrDefault(bSportCategory?.sortOrder)) return 1;

    if (aSportCategory == null) return 1;
    if (bSportCategory == null) return -1;

    if (aSportCategory.name < bSportCategory.name) return -1;
    if (aSportCategory.name > bSportCategory.name) return 1;

    return idComparer({ id: aSportCategory.id }, { id: bSportCategory.id });
  }

  return 0;
}
