import { MappingGroup } from './MappingGroup'

import * as tips from './tips';

export {
	MappingGroup
}

export const createSpread = (priority: number) => new MappingGroup("spread", tips.threeWayMargin, ["handicap-hcp", "point-handicap-hcp-incl-overtime", "handicap-hcp-incl-extra-innings", "handicap-incl-overtime"], [], priority);
export const createTotal = (priority: number) => new MappingGroup("total", tips.overUnder, ["total-incl-overtime", "total-incl-extra-innings"], [], priority);
export const createMoneyline = (priority: number) => new MappingGroup("money-line", tips.winner, ["winner", "winner-incl-overtime", "winner-incl-super-over", "winner-incl-extra-innings", "winner-incl-overtime-and-penalties", "penalty-shootout-winner"], [], priority);

export const createRunLine = (priority: number) => new MappingGroup("run-line", tips.awayHome, ["handicap-hcp", "point-handicap-hcp-incl-overtime", "handicap-hcp-incl-extra-innings", "handicap-incl-overtime"], [], priority);
export const createPuckLine = (priority: number) => new MappingGroup("puck-line", tips.awayHome, ["handicap-hcp", "point-handicap-hcp-incl-overtime", "handicap-hcp-incl-extra-innings", "handicap-incl-overtime"], [], priority);