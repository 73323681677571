import { DefaultInMemoryPagingOfferConfiguration } from './DefaultInMemoryPagingOfferConfiguration';
import { IAdditionalPageableOfferConfiguration } from "./IAdditionalPageableOfferConfiguration";


export class DefaultAdditionalPageableConfiguration extends DefaultInMemoryPagingOfferConfiguration implements IAdditionalPageableOfferConfiguration {
	eventId: string;
	rowElements: number;

	constructor() {
		super();

		this.rowElements = 3;
	}
}