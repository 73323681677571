import { decorate, observable, action, runInAction, computed } from 'mobx';

import { IStorageProvider, SessionStorageProvider } from '@gp/utility';

const STORAGE_KEY = 'favorites';

class LocalStorageFavoritesStore {
	/**
	 * List of favorite event ids
	 */
	favoriteEventIds: string[] = [];

	storageProvider: IStorageProvider;

	get count(): number {
		return this.favoriteEventIds.length;
	}

	constructor(storageProvider?: IStorageProvider) {
		if (storageProvider != null) {
			this.storageProvider = storageProvider;
		}
		else {
			this.storageProvider = new SessionStorageProvider();
		}

		this.initialize();
	}

	initialize() {
		this.storageProvider
			.init<string[]>(STORAGE_KEY, [])
			.then(items => {
				runInAction(() => {
					this.favoriteEventIds = items;
				});
			})
			.catch(e => {
				// TODO: add error handling here, for now, clear favorite events
				runInAction(() => {
					this.favoriteEventIds = [];
				});
			});
	}

	/**
	 * Returns true if event is in favorites, false otherwise
	 * @param eventId event id
	 */
	isInFavorites: (eventId: string) => boolean = (eventId) => {
		return this.favoriteEventIds.includes(eventId);
	}

	/**
	 * Add event as favorite
	 * @param eventId event id to add as favorite
	 * @description Adds only if event is not already marked as favorite
	 */
	add(eventId: string) {
		if (!this.favoriteEventIds.includes(eventId)) {
			this.favoriteEventIds.push(eventId);
			this.syncStorage();
		}
	}

	/**
	 * Removes event from favorites
	 * @param eventId event id to remove from favorite
	 */
	remove(eventId: string) {
		const eventIndex = this.favoriteEventIds.indexOf(eventId);
		if (eventIndex > -1) {
			this.favoriteEventIds.splice(eventIndex, 1);
			this.syncStorage();
		}
	}

	/**
	 * Remove all favorites
	 */
	clear() {
		this.favoriteEventIds = [];
		this.syncStorage();
	}

	/**
	 * Sync storage with current state
	 */
	private syncStorage() {
		this.storageProvider.set(STORAGE_KEY, this.favoriteEventIds);
	}
}

decorate(LocalStorageFavoritesStore, {
	favoriteEventIds: observable,
	count: computed,
	initialize: action.bound,
	add: action.bound,
	remove: action.bound,
	clear: action.bound,
});

export {
	LocalStorageFavoritesStore
}