import { ICategoryColumnConfigurationResult, IColumnResult, IDividedBettingTypesResult, ValidColumns } from "@gp/models";
import { getUniqueElements } from "@gp/utility";

import { IOfferMapperConfiguration, IMainSportHeaders, IMappingGroup } from "../models";
import { Rule } from "../models/rules/Rule";
import { CategoriesType, Rules } from "../models/rules/Rules";
import { relatedBettingTypes } from "../models/rules";
import { TypeConfiguration } from "./TypeConfiguration";

import { defaultRules } from './rules';

export type SourceConfigurationKey = keyof Omit<Rules, 'category'>;

export class OfferMapper {
	/**
	 * Gets column configuration for betting type abrv
	 * @param bettingTypeAbrv betting type abrv
	 * @param tips tips for betting type configuration
	 * @param sortOrder sort order
	 * @returns object representing column configuration
	 */
	static getColumnConfigurationForBettingType(bettingTypeAbrv: string, tips?: string[], sortOrder?: number) {
		const cfg = new TypeConfiguration(bettingTypeAbrv, tips, sortOrder);

		return {
			bettingType: cfg.abrv,
			tips: cfg.tips.original,
			displayTips: cfg.tips.display
		}
	}

	/**
	 * Maps TypeConfiguration into column for displaying
	 * @param typeConfiguration betting type configuration
	 * @returns Object representing one column in sports offer
	 */
	static mapTypeConfigurationToColumnConfiguration(typeConfiguration: TypeConfiguration) {
		return {
			bettingType: typeConfiguration.abrv,
			tips: typeConfiguration.tips.original,
			displayTips: typeConfiguration.tips.display
		}
	}

	/**
	 * Gets betting types for defined source (prematch or live)
	 * @param source 
	 */
	static getBettingTypes(source: SourceConfigurationKey, customConfiguration: Rules | null = null): IDividedBettingTypesResult {
		const rules = customConfiguration != null ? customConfiguration[source] : defaultRules[source];

		const normal = new Set();
		const marginal = new Set();

		rules.forEach(rule => {
			rule.groups.forEach(group => {
				group.primary.forEach(btAbrv => {
					const btConfig = new TypeConfiguration(btAbrv);

					if (btConfig.isMargin) {
						marginal.add(btAbrv);
					}
					else {
						normal.add(btAbrv);
					}
				});

				group.secondary.forEach(btAbrv => {
					const btConfig = new TypeConfiguration(btAbrv);

					if (btConfig.isMargin) {
						marginal.add(btAbrv);
					}
					else {
						normal.add(btAbrv);
					}
				});
			});
		});

		return {
			/**
			 * normal betting types
			 */
			normal: Array.from(normal),
			/**
			 * betting types with margine (used for isFavorite filter flag)
			 */
			marginal: Array.from(marginal)
		} as IDividedBettingTypesResult;;
	}

	/**
	 * Gets all betting types for prematch and live
	 */
	static getAllBettingTypes(customConfiguration: Rules | null = null): IDividedBettingTypesResult {
		const prematch = this.getBettingTypes('prematch', customConfiguration);
		const live = this.getBettingTypes('live', customConfiguration);

		return {
			normal: getUniqueElements([...prematch.normal, ...live.normal]),
			marginal: getUniqueElements([...prematch.marginal, ...live.marginal]),
		};
	}

	/**
	 * Gets mapping by category for Odds
	 * @param category 
	 */
	static getOddsBettingTypesByCategory(category: 'highlights'): ICategoryColumnConfigurationResult {
		// const categoryBettingTypes: IColumns = oddsRules[category];
		// return this.getMappingByCategory(categoryBettingTypes);
		throw "Not implemented"
	}

	/**
	 * Fins all unique related betting types
	 */
	static findRelatedBettingTypes(): string[] {
		const allRelated = Object.values(relatedBettingTypes);

		const result = allRelated.reduce<string[]>((acc, bettingTypes) => {
			bettingTypes.forEach(bt => {
				if (!acc.includes(bt)) {
					acc.push(bt);
				}
			});

			return acc;
		}, []);

		return result;
	}

	/**
	 * Gets related betting type for given betting type abrv. If related betting type does not exist, returns an empty array
	 * @param bettingTypeAbrv betting type abrv
	 * @returns array of related betting types or empty array
	 */
	static getRelatedBettingTypes(bettingTypeAbrv: string): string[] {
		const result = relatedBettingTypes[bettingTypeAbrv];

		if (result == null) {
			return [];
		}

		return result;
	}

	/**
	 * Gets mapping by category for Web
	 * @param category 
	 */
	static getSportBettingTypesByCategory(category: keyof CategoriesType, customConfiguration: Rules | null = null): ICategoryColumnConfigurationResult {
		const categoryBts: Rule = customConfiguration != null ? customConfiguration.category[category] : defaultRules.category[category];

		return this.getMappingByCategory(categoryBts);
	}

	private static getMappingByCategory(rule: Rule): ICategoryColumnConfigurationResult {
		const result: ICategoryColumnConfigurationResult = {
			columns: [],
			filter: {
				normal: [],
				marginal: []
			}
		};

		const cfg = this.getRuleConfiguration(rule);

		result.columns = cfg.primary;
		result.filter = cfg.filter;

		return result;
	}

	private static getRuleConfiguration(rule: Rule) {
		const primaryMap = new Map<string, IColumnResult>();
		const secondaryMap = new Map<string, IColumnResult>();

		const selectors: IMappingGroup[] = [];
		const allBts: string[] = [];
		const allBtsWithConfiguration: IColumnResult[] = [];

		const normalBts: Set<string> = new Set();
		const marginalBts: Set<string> = new Set();

		rule.groups.forEach(group => {
			selectors.push(group);

			group.primary.forEach(bt => {
				if (!primaryMap.has(bt)) {
					const pCfg = new TypeConfiguration(bt);
					primaryMap.set(bt, { bettingType: bt, tips: pCfg.tips.original, displayTips: pCfg.tips.display });

					allBts.push(bt);
					allBtsWithConfiguration.push({ bettingType: bt, tips: pCfg.tips.original, displayTips: pCfg.tips.display });

					pCfg.isMargin ? marginalBts.add(bt) : normalBts.add(bt);
				}
			});

			group.secondary.forEach(bt => {
				if (!secondaryMap.has(bt)) {
					const sCfg = new TypeConfiguration(bt);
					secondaryMap.set(bt, { bettingType: bt, tips: sCfg.tips.original, displayTips: sCfg.tips.display });

					allBts.push(bt);
					allBtsWithConfiguration.push({ bettingType: bt, tips: sCfg.tips.original, displayTips: sCfg.tips.display });

					sCfg.isMargin ? marginalBts.add(bt) : normalBts.add(bt);
				}
			});
		});

		const primary: IColumnResult[] = [];
		const secondary: IColumnResult[] = [];

		for (let val of primaryMap.values()) {
			primary.push(val);
		}
		for (let val of secondaryMap.values()) {
			secondary.push(val);
		}

		return {
			selectors,
			all: allBts,
			allWithConfig: allBtsWithConfiguration,
			primary,
			secondary,
			filter: {
				normal: Array.from(normalBts),
				marginal: Array.from(marginalBts)
			}
		}
	}

	/**
	 * Gets main sport header columns. This is used to get columns for displaying main offer
	 * @param sportAbrv sport abrv
	 * @param isLive true if you need columns for live offer, false for prematch
	 */
	static getMainSportHeaders(sportAbrv: string, isLive: boolean = false, customConfiguration: Rules | null = null): IMainSportHeaders {
		let src: Rule[];

		if (customConfiguration != null) {
			src = isLive ? customConfiguration.live : customConfiguration.prematch;
		}
		else {
			src = isLive ? defaultRules.live : defaultRules.prematch;
		}

		// find specific sport mapping, if it cannot be found, fallback to default
		const sportRule = src.find(s => s.sports.has(sportAbrv) || s.isDefault);

		if (sportRule == null) {
			throw `Could not find sport Rule for ${sportAbrv} or default group is missing.`
		}

		const sportConfig = this.getRuleConfiguration(sportRule);

		return {
			selectors: sportConfig.selectors,
			all: sportConfig.all,
			allWithConfiguration: sportConfig.allWithConfig,
			primary: sportConfig.primary,
			secondary: sportConfig.secondary,
		}
	}

	/**
	 * Gets header configuration for given category
	 * @param category 
	 * @param columns columns to use
	 * @param customConfiguration custom configuration
	 * @returns 
	 */
	static getMainHeadersForCategory(category: "lastMinute" | "highlights" | "upcoming", columns: ValidColumns[] = [], customConfiguration: IOfferMapperConfiguration | null = null): IMainSportHeaders {
		throw "Not implemented";
	}
}