import { ConsoleLogger, ILogger } from '@gp/utility';
// import { IOfferMapperConfiguration } from '../../mapper';
import { Rules } from '../../mapper/models/rules/Rules';
import { LocalStorageFavoritesStore } from '../LocalStorageFavoritesStore';
import { BettingTypeSelectorsStore, IBettingTypeSelectorsStore } from '../selectors';

import { IConfiguration } from './IConfiguration';

export class DefaultConfiguration implements IConfiguration {
	favoritesStore?: LocalStorageFavoritesStore;
	bettingTypeSelectorsStore: IBettingTypeSelectorsStore = new BettingTypeSelectorsStore();
	logger: ILogger = new ConsoleLogger();
	removeDelay: number = 10;
	throttle: number = 4;
	enableThrottling: boolean = false;
	indicatorDuration: number = 3500;
	customConfiguration?: Rules;
	// customConfiguration?: IOfferMapperConfiguration;
}