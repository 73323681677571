import { action, computed, decorate, observable, runInAction } from "mobx";

import {
	EventDelete,
	EventUpdate,
	IChange,
	OfferSubscriptionResponse,
	Event,
	BettingOfferKeyDelete,
	BettingOfferKeyUpdate,
	BettingOffer,
	Key,
	EventKeyBettingOffer,
	PageableOfferResponse
} from "@gp/models";

import { IPageableOfferConfiguration, MainPageableOfferStore } from "..";
import { secondsToMilliseconds } from "@gp/utility";
import { manipulatePageableOfferResponse } from '../../utility/manipulateOfferResponse'
import { EventKeyOffer, EventOffer, SportStructureOffer } from "../..";

/**
 * PrematchOfferStore - used for displaying prematch events. Has option to sync live events
 */
class PrematchOfferStore extends MainPageableOfferStore {
	liveVersion: number = 0;

	eventsInSports: SportStructureOffer = new SportStructureOffer();

	get isEmpty(): boolean {
		return this.eventsMap.size === 0;
	}

	get events(): EventOffer[] {
		return Array.from(this.eventsMap.values());
	}

	get liveEvents(): EventOffer[] {
		return this.events.filter(e => e.isLive);
	}

	get totalPages(): number {
		const remainder = this.totalItems % this.configuration.pageSize;
		const totalPages = remainder === 0 ? (this.totalItems / this.pageSize) : ((this.totalItems - remainder) / this.totalPages) + 1;

		return totalPages;
	}

	constructor(configuration: IPageableOfferConfiguration) {
		super(configuration);

		this.eventsMap = observable.map<string, EventOffer>(undefined, { name: 'eventsMap' });
	}

	assignOfferData(data: PageableOfferResponse): void {
		data = manipulatePageableOfferResponse(data, this);
		super.assignOfferData(data);

		this.configuration.bettingTypeSelectorsStore.update(this.eventsInSports.sports, this.lookupsStore.bettingTypesByAbrv);
	}

	/**
	 * Used to map live updates
	 * @param data data from hub
	 * @param skipReset skips reset
	 * @returns 
	 */
	assignLiveData(data: OfferSubscriptionResponse, skipReset: boolean = false) {
		// clear comparison offer store before any new mapping
		this.comparisonOfferStore.clear();

		if (!this.configuration.enableThrottling) {
			this.processMessage(data);
			this.configuration.bettingTypeSelectorsStore.update(this.eventsInSports.sports, this.lookupsStore.bettingTypesByAbrv);
			this.initialized = true;
			return;
		}

		if (data.startingVersion === 0) {
			// clear buffer first
			clearTimeout(this._bufferTimeoutId);
			this._bufferTimeoutId = undefined;
			this._buffer = [];

			// process message
			this.processMessage(data, skipReset);
			this.configuration.bettingTypeSelectorsStore.update(this.eventsInSports.sports, this.lookupsStore.bettingTypesByAbrv);

			this.initialized = true;
			return;
		}

		this._buffer.push(data);

		if (this._bufferTimeoutId == null) {
			this._bufferTimeoutId = window.setTimeout(() => runInAction(() => {
				// this.indicatorStore.hideIndicators();

				this._buffer.forEach(r => this.processMessage(r, skipReset));
				this.configuration.bettingTypeSelectorsStore.update(this.eventsInSports.sports, this.lookupsStore.bettingTypesByAbrv);

				// reset offer indicators
				setTimeout(() => {
					runInAction(() => {
						Array.from(this.keyOffersMap.values()).map(ko => Array.from(ko.values())).flat().filter(o => o.indicator !== 0).forEach(o => o.indicator = 0);
					});
				}, this.configuration.indicatorDuration || 3500);

				this._buffer = [];
				this._bufferTimeoutId = undefined;

				// this.indicatorStore.showIndicators();
			}), secondsToMilliseconds(this.configuration.throttle));
		}
	}

	/**
	 * Process offer message
	 * @param data 
	 * @returns 
	 */
	processMessage(data: OfferSubscriptionResponse, skipReset: boolean = false) {
		if (data == null) {
			return;
		}

		const {
			lookups,
			offerChanges,
			startingVersion,
			version
		} = data;

		if (startingVersion === 0) {
			this.logger.logWarn(`Offer reset. Previous version: ${version}`);
			this.reset(skipReset);
		}

		// we've received older version than we currently have - ignore it
		if (version < this.liveVersion) {
			this.logger.logWarn(`Received older offer - skipping. Received: ${version} | Current: ${this.version}`)
			return;
		}

		if (lookups != null && !lookups.isEmpty) {
			this.lookupsStore.update(lookups);
		}
		else {
			this.logger.logInfo("Did not receive lookups.");
		}

		if (offerChanges != null) {
			this.processOfferChanges(offerChanges);
		}
		else {
			this.logger.logWarn("Received empty offer.");
		}

		// update version to new value after mapping is done.
		this.liveVersion = version;
	}

	/**
	 * Process offer changes (updates / deletes)
	 * @param offerChanges offer changes
	 */
	processOfferChanges(offerChanges: IChange<EventUpdate, EventDelete>) {
		if (offerChanges.updates != null && offerChanges.updates.length > 0) {
			this.processEventUpdates(offerChanges.updates);
		}

		if (offerChanges.deletes != null && offerChanges.deletes.length > 0) {
			this.processEventDeletes(offerChanges.deletes);
		}
	}

	/**
	 * Updates event data and offer
	 * @param eventUpdates event data to update
	 */
	processEventUpdates(eventUpdates: EventUpdate[]) {
		eventUpdates.forEach(eventUpdate => {
			if (eventUpdate.event != null) {
				this.updateEvent(eventUpdate.event);
			}

			if (eventUpdate.offers != null) {
				if (eventUpdate.offers.updates != null) {
					this.processKeyUpdates(eventUpdate.eventId, eventUpdate.offers.updates);
				}
				if (eventUpdate.offers.deletes != null) {
					this.processKeyDeletes(eventUpdate.eventId, eventUpdate.offers.deletes);
				}
			}
		});
	}

	/**
	 * Deletes event offer keys
	 * @param eventId event id
	 * @param keyDeletes keys to delete
	 */
	processKeyDeletes(eventId: string, keyDeletes: BettingOfferKeyDelete[]) {
		const eventKeys = this.eventKeysMap.get(eventId);

		keyDeletes.forEach(key => {
			// first delete key offers
			this.keyOffersMap.delete(key.id);

			// then delete key
			if (eventKeys != null) {
				eventKeys.delete(key.id);
			}
		});

		// re-set updated event keys
		// this.eventKeysMap.set(eventId, eventKeys);
	}

	/**
	 * Updates event offer keys
	 * @param eventId event id
	 * @param keyUpdates keys to update
	 */
	processKeyUpdates(eventId: string, keyUpdates: BettingOfferKeyUpdate[]) {
		keyUpdates.forEach(keyUpdate => {
			if (keyUpdate.key != null) {
				this.updateKey(eventId, keyUpdate.key);
			}

			// add current key offers state in memory for indicator comparison
			this.comparisonOfferStore.add(eventId, keyUpdate.keyId);

			// update offer
			if (keyUpdate.offers != null) {
				if (keyUpdate.offers.updates) {
					this.processOfferUpdates(eventId, keyUpdate);
				}
				if (keyUpdate.offers.deletes) {
					this.processOfferDeletes(keyUpdate.keyId, keyUpdate.offers.deletes);
				}
			}
		});
	}

	/**
	 * Updates key data
	 * @param eventId event id
	 * @param key Key to update
	 */
	updateKey(eventId: string, key: Key) {
		if (!this.eventKeysMap.has(eventId)) {
			this.eventKeysMap.set(eventId, new Map<string, EventKeyOffer>());
		}

		const eventKeys = this.eventKeysMap.get(eventId);
		let currentKey = eventKeys?.get(key.id);


		if (currentKey == null) {
			const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

			if (keyBettingType == null) {
				throw `Can not find betting type ${key.bettingTypeId} in lookups store.`
			}

			const newKey = this.createNewKey(key, eventId, keyBettingType);

			eventKeys?.set(key.id, newKey);
		}
		else {
			currentKey.update(key);
		}
	}

	/**
	 * Deletes offers
	 * @param keyId key id
	 * @param offers offers to delete
	 */
	processOfferDeletes(keyId: string, offers: BettingOffer[]) {
		if (!this.keyOffersMap.has(keyId)) {
			this.logger.logTrace('Offer already removed');
			return;
		};

		const keyOffers = this.keyOffersMap.get(keyId);

		offers.forEach(o => {
			keyOffers?.delete(o.id);
		});

		// this.keyOffersMap.set(keyId, keyOffers);
	}

	/**
	 * Updates offers
	 * @param eventId event id
	 * @param keyId key id
	 * @param offers offers to update
	 */
	processOfferUpdates(eventId: string, key: BettingOfferKeyUpdate) {
		if (!this.keyOffersMap.has(key.keyId)) {
			this.keyOffersMap.set(key.keyId, new Map<string, EventKeyBettingOffer>());
		}
		const keyOffers = this.keyOffersMap.get(key.keyId);

		const event = this.eventsMap.get(eventId);

		// check if all offers have same playerId, or if they have playerId at all
		let playerId: string | undefined = undefined;
		let teamIds: { teamOneId: string | undefined, teamTwoId: string | undefined } | undefined = undefined;

		if (key.offers?.updates?.every((o, i, src) => o.playerId != null && o.playerId !== '' && o.playerId === src[0].playerId)) {
			playerId = key.offers.updates[0].playerId;
		}

		if (!event?.isOutright) {
			teamIds = {
				teamOneId: event?.teamOneId,
				teamTwoId: event?.teamTwoId,
			}
		}

		key.offers?.updates?.forEach(o => {
			const tipInfo = this.getTipInformation({
				defaultTip: o.tip,
				bettingTypeId: key.bettingTypeId,
				playerId: o.playerId,
				teamId: o.teamId,
				specifiers: {
					...key.key?.specifier,
					...o.specifier
				},
				eventData: {
					name: event?.name,
					playerId: playerId,
					teamIds: teamIds
				}
			});

			const existingOffer = keyOffers?.get(o.id);
			if (existingOffer != null) {
				const comparableValue = this.comparisonOfferStore.getValue(existingOffer.id);

				// calculate indicator
				if (comparableValue === undefined) {
					existingOffer.indicator = 0;
				}
				else if (o.value > comparableValue) {
					existingOffer.indicator = 1;
				}
				else if (o.value < comparableValue) {
					existingOffer.indicator = -1;
				}
				else {
					existingOffer.indicator = 0;
				}

				existingOffer.isLive = !!event?.isLive;
				existingOffer.isLocked = !!o.isLocked
				existingOffer.value = o.value;

				existingOffer.tip = tipInfo.tip;
				existingOffer.displayTip = tipInfo.displayTip;
				existingOffer.gender = tipInfo.gender;
			}
			else {
				keyOffers?.set(o.id, {
					...o,
					keyId: key.keyId,
					eventId: eventId,
					isLive: !!event?.isLive,
					isLocked: !!o.isLocked,
					tip: tipInfo.tip,
					displayTip: tipInfo.displayTip,
					gender: tipInfo.gender,
					indicator: 0
				});
			}
		});
	}


	/**
	 * Deletes events and all event offer
	 * @param eventDeletes events to delete
	 */
	processEventDeletes(eventDeletes: EventDelete[]) {
		// list of event event ids that require delayed remove
		const delay: string[] = [];

		eventDeletes.forEach(event => {
			if (event.ended) {
				this.updateEvent(event);
				delay.push(event.id);
			}
			else {
				this.deleteEvent(event.id);
			}
		});

		if (delay.length > 0) {
			setTimeout(() => runInAction(() => {
				delay.forEach(eid => this.deleteEvent(eid));
			}), secondsToMilliseconds(this.configuration.removeDelay));
		}
	}

	/**
	 * Updates event data
	 * @param event Event to update
	 */
	updateEvent(event: Event) {
		let currEvent = this.eventsMap.get(event.id);

		if (currEvent != null) {
			currEvent.update(event);
		}
		else {
			currEvent = new EventOffer(event, this.configuration.display);
			this.eventsMap.set(event.id, currEvent);

			// added new event - update count
			this.eventsInSports.updateEventCount(this.eventsMap.size);
		}

		this.addOrUpdateEvent(currEvent)
	}

	/**
	 * Remove event and all event offer
	 * @param eventId event id
	 */
	deleteEvent(eventId: string) {
		// first delete all event key offers
		const eventKeys = this.eventKeysMap.get(eventId);
		if (eventKeys != undefined) {
			eventKeys.forEach(ek => this.keyOffersMap.delete(ek.id));
		}

		// then delete all event keys
		this.eventKeysMap.delete(eventId);

		const event = this.eventsMap.get(eventId);
		this.removeEventFromSportOffer(event);

		// then delete event itself
		this.eventsMap.delete(eventId);

		// event deleted - update count
		this.eventsInSports.updateEventCount(this.eventsMap.size);
	}

	/**
	 * OVERRIDES
	 */

	/**
	 * Sets event data
	 * @param event Event to update
	 */
	setEvent(event: Event) {
		const newEvent = new EventOffer(event, this.configuration.display);
		this.eventsMap.set(event.id, newEvent);

		this.addOrUpdateEvent(newEvent);
	}

	/**
	 * Sets key data
	 * @param eventId event id
	 * @param key key to add
	 */
	setKey(eventId: string, key: Key) {
		const eventKeys = this.eventKeysMap.get(eventId) || new Map<string, EventKeyOffer>();
		const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

		if (keyBettingType == null) {
			throw `Could not find betting type ${key.bettingTypeId} in lookups store.`
		}

		const newKey = this.createNewKey(key, eventId, keyBettingType);
		eventKeys.set(key.id, newKey);

		this.eventKeysMap.set(eventId, eventKeys);
	}

	addOrUpdateEvent(event: Event) {
		// normal, live and outright events are segmented under their own sport with correct flags set
		const existingSport = this.eventsInSports.sports.find(s =>
			s.id === event.sportId &&
			s.isLive === event.isLive &&
			s.isOutright === event.isOutright
		);

		if (existingSport != null) {
			const existingCategory = existingSport.categories!.find(c => c.id === event.sportCategoryId);
			if (existingCategory != null) {
				const existingTournament = existingCategory.tournaments.find(t => t.id === event.tournamentId);
				if (existingTournament != null) {
					// find event
					const existingEvent = existingTournament.events.find(e => e.id === event.id);
					if (existingEvent != null) {
						existingEvent.update(event);
					}
					else {
						existingTournament.addEventOffer(new EventOffer(event, this.configuration.display));
						existingSport.updateEventCount(existingSport.eventCount + 1);
					}
				}
				else {
					const tournamentOffer = this.getMappedTournament(event);

					if (tournamentOffer == null) {
						return;
					}

					existingCategory.addTournamentOffer(tournamentOffer);
					existingSport.updateEventCount(existingSport.eventCount + 1);
				}
			}
			else {
				const categoryOffer = this.getMappedCategory(event);

				if (categoryOffer == null) {
					return;
				}

				existingSport.addSportCategoryOffer(categoryOffer);
				existingSport.updateEventCount(existingSport.eventCount + 1);
			}
		}
		else {
			const sportOffer = this.getMappedSport(event);

			if (sportOffer == null) {
				return;
			}

			this.eventsInSports.addSportOffer(sportOffer);
		}
	}

	/**
	 * Removes event from sport offer structure
	 * @param event event to remove
	 */
	removeEventFromSportOffer(event: Event | undefined) {
		if (event == null) {
			return;
		}

		// we need to update sport offer model (this is live event)
		const sportOffer = this.eventsInSports.sports.find(so => so.id === event.sportId && so.isLive == true && so.isOutright == false);
		if (sportOffer != null) {
			sportOffer.removeEvent(event.id);
			sportOffer.updateEventCount(sportOffer.eventCount - 1);

			if (
				(sportOffer.events != null && sportOffer.events.length === 0) ||
				(sportOffer.categories != null && sportOffer.categories.length === 0)
			) {
				this.eventsInSports.removeSportOffer(sportOffer);
			}
		}
	}

	reset(resetLiveOnly: boolean = false) {
		if (resetLiveOnly) {
			this.liveEvents.forEach(le => {
				// keys
				const lek = this.eventKeysMap.get(le.id);

				// remove all offers for keys
				lek?.forEach((_, keyId) => {
					this.keyOffersMap.delete(keyId);
				});

				// remove all event keys
				this.eventKeysMap.delete(le.id);

				this.removeEventFromSportOffer(le);

				// remove event
				this.eventsMap.delete(le.id);
			});

			this.eventsInSports.updateEventCount(this.eventsMap.size);
		}
		else {
			super.reset();
			this.eventsInSports = new SportStructureOffer();
		}
	}
}

decorate(PrematchOfferStore, {
	liveVersion: observable,
	eventsInSports: observable,
	events: computed,
	liveEvents: computed,
	assignLiveData: action.bound,
	processOfferChanges: action.bound,
	processEventUpdates: action.bound,
	processEventDeletes: action.bound,
	updateEvent: action.bound,
	deleteEvent: action.bound,
	processKeyUpdates: action.bound,
	processKeyDeletes: action.bound,
	updateKey: action.bound,
	processOfferUpdates: action.bound,
	processOfferDeletes: action.bound,
	assignOfferData: action.bound,
	setEvent: action.bound,
	setKey: action.bound,
	addOrUpdateEvent: action.bound,
	removeEventFromSportOffer: action.bound,
	reset: action.bound,
});

export {
	PrematchOfferStore
}
