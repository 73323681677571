import { Rule } from "./Rule";

export type CategoriesType = {
	highlights: Rule,
	lastMinute: Rule,
	upcoming: Rule,
	mostPlayed: Rule,
}

export class Rules {
	prematch: Rule[] = [];
	live: Rule[] = [];
	category: CategoriesType = {
		highlights: new Rule([], [], true),
		lastMinute: new Rule([], [], true),
		upcoming: new Rule([], [], true),
		mostPlayed: new Rule([], [], true),
	}
}