import {
	createQuarterTotal,
	createAsianHandicap,
	createFrameHandicap,
	createTotalFrames,
	createPointHandicap,
	createTotal180s,
	createMost180s,
	createThreeWay,
	createRestOfMatchWinner,
	createDrawNoBet,
	createNextGoal,
	createWillBothTeamsScore,
	createOverUnder,
	createHandicap,
	createThreeWayWinner,
	createDoubleChance,
	createWinner,
	createOverUnderGamesInTheMatch,
	createSetWinner,
	createSetHandicap,
	createSetTotalGames,
	createMatchGameHandicap,
	createWhoWinsPeriod,
	createPeriodTotals,
	createTotalPoints,
	createGameWinner,
	createHandicapInclOvertime,
	createFirstHalfWinner,
	createRestOfFirstHalfWinner,
	createFirstHalfNextGoal,
	createFirstHalfTotals,
	createFirstHalfHandicap,
	createSetOverUnder,
	createFirstHalfAsianHandicap,
	createMainTipBasketballLive,
	createInningTotalHits,
	createInningTotal,
	createInningWinners,
	createFrameBreak50,
	createFrameTotalPoints,
	createPeriodWinners,
	createFrameWinner,
	createSetLegHandicap,
	createSetWhoWinsRest,
	createSetLegWinner,
	createWhoWinsQuarter,
} from "../../groups";

import { Rule } from "../../../models/rules";

export const soccer = new Rule(
	[
		"soccer"
	],
	[
		createThreeWay(1),
		createRestOfMatchWinner(2),
		createNextGoal(3),
		createOverUnder(4),
		createWillBothTeamsScore(5),
		createHandicap(6),
		createFirstHalfWinner(7),
		createRestOfFirstHalfWinner(8),
		createFirstHalfNextGoal(9),
		createFirstHalfTotals(10),
		createFirstHalfHandicap(11),
		createDrawNoBet(12),
	]
);

export const iceHockey = new Rule(
	[
		"ice-hockey"
	],
	[
		createThreeWay(1),
		createRestOfMatchWinner(2),
		createNextGoal(3),
		createOverUnder(4),
		createHandicap(5),
		createPeriodTotals(6),
		createWhoWinsPeriod(7)
	]
);

export const tennis = new Rule(
	[
		"tennis"
	],
	[
		createWinner(1),
		createOverUnderGamesInTheMatch(2),
		createSetTotalGames(3),
		createSetHandicap(4),
		createSetWinner(5),
		createMatchGameHandicap(6)
	]
);

export const volleyball = new Rule(
	[
		'volleyball',
		'beach-volley'
	],
	[
		createWinner(1),
		createSetWinner(2),
		createTotalPoints(3),
		createSetOverUnder(4),
	]
);

export const futsal = new Rule(
	[
		'futsal',
	],
	[
		createThreeWay(1),
		createRestOfMatchWinner(2),
		createNextGoal(3),
		createOverUnder(4),
	]
);

export const tableTennis = new Rule(
	[
		'table-tennis'
	],
	[
		createWinner(1),
		createGameWinner(2),
		createTotalPoints(3)
	]
);

export const basketball = new Rule(
	[
		'basketball'
	],
	[
		createMainTipBasketballLive(1),
		createHandicapInclOvertime(2),
		createOverUnder(3),
		createQuarterTotal(4),
		//createDrawNoBet(4),
		createFirstHalfAsianHandicap(5),
		createFirstHalfTotals(6)
	]
);

export const handball = new Rule(
	[
		'handball'
	],
	[
		createThreeWay(1),
		createOverUnder(3),
		createAsianHandicap(2),
		createFirstHalfWinner(4),
		createFirstHalfHandicap(5),
		createFirstHalfTotals(6),
	]
);

export const americanFootball = new Rule(
	[
		'american-football'
	],
	[
		createWinner(1),
		createAsianHandicap(3),
		createOverUnder(2),
		createWhoWinsQuarter(4),
		createQuarterTotal(5),
	]
);

export const darts = new Rule(
	[
		'darts'
	],
	[
		createWinner(1),
		createMost180s(2),
		createTotal180s(3),
		createSetLegWinner(4),
		createSetWhoWinsRest(5),
		createSetLegHandicap(6),
	]
);

export const snooker = new Rule(
	[
		'snooker'
	],
	[
		createWinner(1),
		createFrameWinner(2),
		createTotalFrames(3),
		createFrameHandicap(4),
		createFrameTotalPoints(5),
		createFrameBreak50(6),
	]
);

export const rugby = new Rule(
	[
		'rugby'
	],
	[
		createThreeWay(1),
		createRestOfMatchWinner(2),
		createAsianHandicap(3),
		createOverUnder(4),
		createFirstHalfWinner(5),
		createFirstHalfTotals(6),
	],
)

export const baseball = new Rule(
	[
		'baseball'
	],
	[
		createWinner(1),
		createOverUnder(2),
		createAsianHandicap(3),
		createInningWinners(4),
		createInningTotal(5),
		createInningTotalHits(6),
	],
)

export const defaultRule = new Rule(
	[],
	[
		createThreeWayWinner(1),
		createDoubleChance(2),
		createHandicap(3),
		createOverUnder(4)
	],
	// default
	true
)