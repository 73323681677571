export const DEFAULT_SORT_ORDER = 9999999;

/**
 * Gets sort order, if undefined, returns DEFAULT_SORT_ORDER
 * @param sortOrder 
 * @see DEFAULT_SORT_ORDER
 * @returns 
 */
export function getSortOrderOrDefault(sortOrder?: number): number {
	if (sortOrder != null) return sortOrder;
	return DEFAULT_SORT_ORDER;
}