import {
	createMoneyline,
	createSpread,
	createTotal,
	createRunLine,
	createPuckLine,
} from "../../groups";


import { Rule } from "../../../models/rules";

export const americanFootball = new Rule(
	[
		'american-football'
	],
	[
		createSpread(1),
		createTotal(2),
		createMoneyline(3),
	]
);

export const basketball = new Rule(
	[
		'basketball'
	],
	[
		createSpread(1),
		createTotal(2),
		createMoneyline(3),
	]
);

export const baseball = new Rule(
	[
		'baseball'
	],
	[
		createRunLine(1),
		createTotal(2),
		createMoneyline(3),
	]
);

export const iceHockey = new Rule(
	[
		"ice-hockey"
	],
	[
		createPuckLine(1),
		createTotal(2),
		createMoneyline(3),
	]
);




export const defaultRule = new Rule(
	[],
	[
		createSpread(1),
		createTotal(2),
		createMoneyline(3),
	],
	// default
	true
)