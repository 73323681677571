import { decorate, computed, observable, action } from "mobx";

import {
    BettingOfferKeyDelete,
    BettingTypeGroup,
    Event,
    EventDelete,
    EventKey,
    EventUpdate,
    IChange,
    Key,
    Sport,
    Category,
    Tournament,
    Team,
} from "@gp/models";
import { getIntersection, insert } from "@gp/utility";

import { MainOfferStore } from "../MainOfferStore";
import { insertBySortOrder } from "../../utility";
import { GroupStore } from "./GroupStore";
import { IAdditionalOfferConfiguration } from "../configuration";
import { EventKeyOffer, EventOffer } from "../..";

const other = 'other';
export const otherBettingTypeGroup = BettingTypeGroup.create(other, other, other, Infinity);

type OfferKeys = Map<string, EventKeyOffer>;
type GroupOfferKeys = Map<string, GroupStore>;

const NumericCollator = Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

class AdditionalOfferStore extends MainOfferStore {
    configuration: IAdditionalOfferConfiguration;

    eventId: string | undefined;
    enableGroups: boolean = false;

    event: Event;

    /**
     * Offer keys
     */
    keys: OfferKeys;
    groupKeys: GroupOfferKeys;

    get teamOne(): Team | undefined {
        if (this.event && this.event.teamOneId) {
            return this.lookupsStore.teams.get(this.event.teamOneId);
        }
        return undefined;
    }

    get teamTwo(): Team | undefined {
        if (this.event && this.event.teamTwoId) {
            return this.lookupsStore.teams.get(this.event.teamTwoId);
        }
        return undefined;
    }

    /**
     * Returns sorted betting type groups
     */
    get bettingGroups(): GroupStore[] {
        const groups: GroupStore[] = [];

        this.groupKeys.forEach(gk => {
            insertBySortOrder(groups, gk);
        });

        // insert other group in lookup
        // this.lookupsStore.bettingTypeGroups.set(other, otherBettingTypeGroup);

        return groups;
    }

    /**
     * Current event sport
     */
    get sport(): Sport | undefined {
        return this.lookupsStore.sports.get(this.event.sportId);
    }

    /**
     * Current event category
     */
    get sportCategory(): Category | undefined {
        return this.lookupsStore.categories.get(this.event.sportCategoryId);
    }

    /**
     * Current event tournament
     */
    get tournament(): Tournament | undefined {
        return this.lookupsStore.tournaments.get(this.event.tournamentId);
    }

    /**
     * Gets sorted betting offers by betting type sort order
     */
    get sortedBettingOffer(): EventKey[] {
        const sorted: EventKey[] = [];

        this.keys.forEach(key => {
            insert(sorted, key, (a, b) => {
                // first sort by betting type
                let aSort = 99999;
                if (a.bettingType.settingsPerSport != null && this.sport != null && a.bettingType.settingsPerSport[this.sport.id] != null) {
                    aSort = a.bettingType.settingsPerSport[this.sport.id].sortOrder;
                }
                let bSort = 99999;
                if (b.bettingType.settingsPerSport != null && this.sport != null && b.bettingType.settingsPerSport[this.sport.id] != null) {
                    bSort = b.bettingType.settingsPerSport[this.sport.id].sortOrder;
                }

                if (aSort < bSort) return -1;
                if (aSort > bSort) return 1;

                // then sort by specifier value (if any)
                if (a.specifier != null && b.specifier != null) {
                    // get valid specifier
                    const matchingSpecifiers = getIntersection(Object.keys(a.specifier), Object.keys(b.specifier));

                    // no matching specifiers found!
                    if (matchingSpecifiers.length === 0) {
                        return 0;
                    }

                    return matchingSpecifiers.reduce((acc, ms) => {
                        // It will work with undefined
                        return NumericCollator.compare(a.specifier?.[ms] as string, b.specifier?.[ms] as string);
                    }, 0) as any;
                }

                return 0;
            });
        });

        return sorted;
    }

    /**
     * Creates a new instance of AdditionalOfferStore
     * @param configuration Additional offer configuration
     */
    constructor(configuration?: IAdditionalOfferConfiguration) {
        super(configuration);

        this.eventId = configuration?.eventId;
        this.enableGroups = configuration?.enableGroups || false;

        this.keys = observable.map<string, EventKeyOffer>(undefined, { name: 'keys' });
        this.groupKeys = observable.map<string, GroupStore>(undefined, { name: 'group-keys' });
    }

    /**
     * Process offer changes (updates / deletes)
     * @param offerChanges offer changes
     */
    processOfferChanges(offerChanges: IChange<EventUpdate, EventDelete>) {
        if (offerChanges.updates != null && offerChanges.updates.length > 0) {
            this.processEventUpdate(offerChanges.updates[0]);
        }

        if (offerChanges.deletes != null && offerChanges.deletes.length > 0) {
            this.processEventDelete(offerChanges.deletes[0]);
        }
    }

    /**
     * Updates event and removes event offer
     * @param eventDelete event data
     */
    processEventDelete(eventDelete: EventDelete) {
        this.updateEvent(eventDelete);

        // delete all offers first
        this.keyOffersMap.clear();

        // then delete groups with keys or keys
        if (this.enableGroups) {
            this.groupKeys.clear();
        }
        else {
            this.keys.clear();
        }
    }

    /**
     * Updates event data and offer
     * @param eventUpdate full event data
     */
    processEventUpdate(eventUpdate: EventUpdate) {
        this.updateEvent(eventUpdate.event);

        if (eventUpdate.offers != null) {
            if (eventUpdate.offers.deletes != null) {
                this.processKeyDeletes(eventUpdate.eventId, eventUpdate.offers.deletes);
            }
            if (eventUpdate.offers.updates != null) {
                this.processKeyUpdates(eventUpdate.eventId, eventUpdate.offers.updates);
            }
        }
    }

    /**
     * Updates event data
     * @param event Event to update
     */
    updateEvent(event: Event) {
        let mappedEvent = this.eventsMap.get(event.id);

        if (mappedEvent != null) {
            mappedEvent.update(event);
        }
        else {
            mappedEvent = new EventOffer(event, this.configuration.display);
            this.eventsMap.set(event.id, mappedEvent);
        }

        this.event = mappedEvent;
    }

    /**
     * Deletes event offer keys
     * @param keyDeletes keys to delete
     */
    processKeyDeletes(eventId: string, keyDeletes: BettingOfferKeyDelete[]) {
        keyDeletes.forEach(key => {
            if (this.enableGroups) {
                // get key group
                const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId)
                // if group id is undefined, we take 'other' group
                const groupId = keyBettingType?.groupId || other;
                const keyGroupStore = this.groupKeys.get(groupId);

                if (keyGroupStore !== undefined) {
                    keyGroupStore.remove(key.id);
                }
                else {
                    this.logger.logWarn(`Group ${groupId} store does not exist.`);
                }
            }
            else {
                // first delete key offer
                this.keyOffersMap.delete(key.id);
                // then delete key itself
                this.keys.delete(key.id);
            }
        });
    }

    /**
     * Updates key in the group
     * @param eventId event id
     * @param key key to update
     */
    updateKeyInGroup(eventId: string, key: Key) {
        // find group
        const bettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

        if(bettingType == null){
            throw `Missing betting type with ${key.bettingTypeId} in lookups store. Cannot update key in group.`
        }

        const groupId = bettingType?.groupId || other;
        let keyGroupStore = this.groupKeys.get(groupId);

        if (keyGroupStore !== undefined) {
            // key group store exists
            keyGroupStore.addOrUpdate(this.createNewKey(key, eventId, bettingType));
        }
        else {
            // key group store does not exist - create it!
            const groupMetadata = this.lookupsStore.bettingTypeGroups.get(groupId);

            if(groupMetadata == null){
                throw `Missing betting type group with ${groupId} id.`
            }

            if(this.sport == null){
                throw `Missing betting type group with ${groupId} id.`
            }

            keyGroupStore = new GroupStore(groupId, groupMetadata.name, this.sport, groupMetadata?.sortOrder);
            keyGroupStore.addOrUpdate(this.createNewKey(key, eventId, bettingType));

            this.groupKeys.set(groupId, keyGroupStore);
        }
    }

    /**
     * Updates key data
     * @param eventId event id
     * @param key Key to update
     */
    updateKey(eventId: string, key: Key) {
        if (this.enableGroups) {
            this.updateKeyInGroup(eventId, key);
            return;
        }

        const currentKey = this.keys.get(key.id);

        if (currentKey == null) {
            const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

            if(keyBettingType == null){
                throw `Can not find betting type ${key.bettingTypeId} in lookups store.`
            }

            const newKey = this.createNewKey(key, eventId, keyBettingType);

            this.keys.set(key.id, newKey);
        }
        else {
            currentKey.update(key);

            this.keys.set(key.id, currentKey);
        }
    }
}

decorate(AdditionalOfferStore, {
    keys: observable,
    event: observable,
    bettingGroups: computed,
    sport: computed,
    sortedBettingOffer: computed,
    processOfferChanges: action.bound,
    processEventDelete: action.bound,
    processEventUpdate: action.bound,
    processKeyDeletes: action.bound,
    updateEvent: action.bound,
    updateKeyInGroup: action.bound
});

export {
    AdditionalOfferStore
}