import { decorate, observable, action, ObservableMap, computed } from 'mobx';

import {
    Sport,
    Category,
    Tournament,
    Team,
    Player,
    BettingType,
    BettingTypeGroup,
    BettingTip
} from '@gp/models';

interface IUpdateData {
    sports?: { [sportId: string]: Sport };
    categories?: { [categoryId: string]: Category };
    tournaments?: { [tournamentId: string]: Tournament };
    teams?: { [teamId: string]: Team };
    players?: { [playerId: string]: Player };
    bettingTypes?: { [bettingTypeId: string]: BettingType<string> };
    bettingTypeGroups?: { [bettingTypeGroupId: string]: BettingTypeGroup };
    bettingTips?: { [bettingTipId: string]: BettingTip };
    isEmpty?: boolean
}

class LookupsStore {
    sports: ObservableMap<string, Sport>;
    categories: ObservableMap<string, Category>;
    tournaments: ObservableMap<string, Tournament>;
    teams: ObservableMap<string, Team>;
    players: ObservableMap<string, Player>;
    bettingTypes: ObservableMap<string, BettingType<BettingTip>>;
    bettingTypeGroups: ObservableMap<string, BettingTypeGroup>;
    bettingTips: ObservableMap<string, BettingTip>;

    get bettingTypesByAbrv(): Map<string, BettingType<BettingTip>> {
        const temp = new Map<string, BettingType<BettingTip>>();

        this.bettingTypes.forEach((value, _) => {
            temp.set(value.abrv, value);
        });

        return temp;
    }

    /**
     * Initializes new instance of LookupsStore
     */
    constructor() {
        this._initialize();
    }

    /**
     * Initializes all observable properties
     */
    _initialize() {
        this.sports = observable.map<string, Sport>(undefined, { name: 'lookups.sports' });
        this.categories = observable.map<string, Category>(undefined, { name: 'lookups.categories' });
        this.tournaments = observable.map<string, Tournament>(undefined, { name: 'lookups.tournaments' });
        this.teams = observable.map<string, Team>(undefined, { name: 'lookups.teams' });
        this.players = observable.map<string, Player>(undefined, { name: 'lookups.players' });
        this.bettingTypes = observable.map<string, BettingType<BettingTip>>(undefined, { name: 'lookups.bettingTypes' });
        this.bettingTypeGroups = observable.map<string, BettingTypeGroup>(undefined, { name: 'lookups.bettingTypeGroups' });
        this.bettingTips = observable.map<string, BettingTip>(undefined, { name: 'lookups.bettingTips' });
    }

    /**
     * Updates lookups store data
     * @param data 
     */
    update(data: IUpdateData) {
        if (data == null) {
            return;
        }

        if (data.sports != null) this.sports.merge(data.sports);
        if (data.categories != null) this.categories.merge(data.categories);
        if (data.tournaments != null) {
            let mappedTournaments = {};
            Object.values(data.tournaments).forEach(t => {
                mappedTournaments[t.id] = new Tournament(t);
            });
            this.tournaments.merge(mappedTournaments);
        }
        if (data.teams != null) this.teams.merge(data.teams);
        if (data.players != null) this.players.merge(data.players);
        if (data.bettingTypeGroups != null) this.bettingTypeGroups.merge(data.bettingTypeGroups);
        if (data.bettingTips != null) this.bettingTips.merge(data.bettingTips);
        if (data.bettingTypes != null) this.updateBettingTypes(data.bettingTypes);
    }

    updateBettingTypes(bts: { [bettingTypeId: string]: BettingType<string> }) {
        const newBts = new Map<string, BettingType>();
        for (let [key, value] of Object.entries(bts)) {
            const newBt = new BettingType();
            newBt.id = value.id;
            newBt.name = value.name;
            newBt.abrv = value.abrv;
            newBt.nameForBettingSlip = value.nameForBettingSlip;
            newBt.nameForOfferList = value.nameForOfferList;
            newBt.description = value.description;
            newBt.specifierType = value.specifierType;
            newBt.settingsPerSport = value.settingsPerSport;
            newBt.groupId = value.groupId;
            newBt.simpleName = value.simpleName;

            if (value.tips != null && value.tips.length > 0) {
                newBt.tips = value.tips
                    .map(tId => this.bettingTips.get(tId))
                    .filter(function <T>(value: T | undefined): value is T {
                        if (value != null) {
                            return true;
                        }
                        return false
                    });
            }

            newBts.set(key, newBt);
        }

        this.bettingTypes.merge(newBts);
    }

    /**
     * Clears all lookups data
     */
    reset() {
        this.sports.clear();
        this.categories.clear();
        this.tournaments.clear();
        this.teams.clear();
        this.players.clear();
        this.bettingTypes.clear();
        this.bettingTypeGroups.clear();
        this.bettingTips.clear();
    }
}

decorate(LookupsStore, {
    sports: observable,
    categories: observable,
    tournaments: observable,
    teams: observable,
    players: observable,
    bettingTypes: observable,
    bettingTypeGroups: observable,
    bettingTips: observable,
    _initialize: action.bound,
    update: action.bound,
    reset: action.bound,
    updateBettingTypes: action.bound,
    bettingTypesByAbrv: computed
});

export {
    LookupsStore,
    IUpdateData
}