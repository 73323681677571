import { OfferSubscriptionResponse, PageableOfferResponse, BettingOffer, Key } from '@gp/models'
import { BaseOfferStore } from '../stores/BaseOfferStore'
import { IConfiguration } from '../stores/configuration'

/**  Cast it into fire, destroy it. My condolences. */
export function manipulatePageableOfferResponse(
    data: PageableOfferResponse,
    store: BaseOfferStore<IConfiguration>
): PageableOfferResponse {
    if (data?.items != null) {
        data.items = data?.items.map(sportCategoryGroup => {

            if (sportCategoryGroup.items == null) {
                return sportCategoryGroup;
            }

            sportCategoryGroup.items = sportCategoryGroup.items.map(tournamentOffer => {
                if (tournamentOffer?.events == null) {
                    return tournamentOffer;
                }

                tournamentOffer.events = tournamentOffer.events.map(eventOffer => {

                    if (eventOffer?.offers == null) return eventOffer;

                    eventOffer.offers = eventOffer.offers.map(keyOffer => {

                        if (isRemapRequired(data?.lookup, store, keyOffer?.bettingTypeId)) {
                            return keyOffer
                        }

                        const playerName = getPlayerName(keyOffer.offers, data.lookup, store);

                        if (playerName == null) {
                            return keyOffer;
                        }

                        keyOffer.key = modifyKeySpecifiers(keyOffer.key, playerName);
                        keyOffer.offers = modifyBettingOfferSpecifiers(keyOffer.offers, playerName);

                        return keyOffer;
                    })

                    return eventOffer;
                })

                return tournamentOffer;
            })
            return sportCategoryGroup;
        })
    }

    return data
}

/**  Cast it into fire, destroy it. My condolences. */
export function manipulateOfferSubscriptionResponse(
    data: OfferSubscriptionResponse,
    store: BaseOfferStore<IConfiguration>
): OfferSubscriptionResponse {
    if (data?.offerChanges?.updates != null) {
        data.offerChanges.updates = data.offerChanges.updates.map(eventUpdate => {

            if (eventUpdate.offers?.updates == null) {
                return eventUpdate;
            }

            eventUpdate.offers.updates = eventUpdate.offers?.updates?.map(bettingOfferKeyUpdate => {
                if (isRemapRequired(data?.lookups, store, bettingOfferKeyUpdate?.bettingTypeId)) {
                    return bettingOfferKeyUpdate;
                }

                const playerName = getPlayerName(bettingOfferKeyUpdate.offers?.updates, data.lookups, store);
                if (playerName == null) {
                    return bettingOfferKeyUpdate;
                }

                bettingOfferKeyUpdate.key = modifyKeySpecifiers(bettingOfferKeyUpdate.key, playerName);
                if (bettingOfferKeyUpdate.offers?.updates != null) {
                    bettingOfferKeyUpdate.offers.updates = modifyBettingOfferSpecifiers(bettingOfferKeyUpdate.offers.updates, playerName);
                }

                return bettingOfferKeyUpdate;
            })

            return eventUpdate;
        })

    }

    return data;
}

function getPlayerName(
    offers: BettingOffer[] | undefined,
    lookups: PageableOfferResponse['lookup'],
    store: BaseOfferStore<IConfiguration>
): string | null {

    const playerId = offers?.find(u => u.playerId != null)?.playerId;
    const teamId = offers?.find(u => u.teamId != null)?.teamId;

    if (playerId == null) return null;

    const playerData = lookups?.players?.[playerId] || store.lookupsStore.players.get(playerId);
    const teamData = lookups?.teams?.[teamId || ""] || store.lookupsStore.teams.get(teamId || "");

    if (playerData == null) return null;

    let playerName: string;
    //player firstName is nullable and sometimes full name is stored in lastName
    if (playerData.firstName) {
        playerName = playerData.firstName + " " + playerData.lastName;
    } else {
        playerName = playerData.lastName;
    }

    const teamName = teamData?.name;
    let constructedName = playerName;

    if (teamName != null) {
        constructedName += ` (${teamName})`
    }

    return constructedName;

}

function modifyBettingOfferSpecifiers(
    offers: BettingOffer[] | undefined,
    playerName: string,
): BettingOffer[] | undefined {

    if (offers != null && offers.length > 0) {
        offers = offers.map(o => {

            if (o.specifier != null) {
                o.specifier = {
                    ...o.specifier,
                    player: playerName,
                }
                o.playerId = undefined;
                o.teamId = undefined;
            }
            return o;
        })
    }

    return offers;
}

function modifyKeySpecifiers(key: Key | undefined, playerName: string): Key | undefined {
    if (key?.specifier == null) {
        return key;
    }

    key.specifier = {
        ...key.specifier,
        player: playerName
    }

    return key;
}

function isRemapRequired(
    lookup: PageableOfferResponse['lookup'],
    store: BaseOfferStore<IConfiguration>,
    bettingTypeId: string
): boolean {

    const abrv =
        lookup?.bettingTypes?.[bettingTypeId]?.abrv
        ||
        store.lookupsStore.bettingTypes.get(bettingTypeId)?.abrv
    if (abrv == null) {
        return false;
    }

    return (
        ![
            "player-total-assists",
            "player-total-rebounds",
            "player-total-3-point-field-goals",
            "player-total-points",
            "player-total-rushing-yards",
            "player-total-passing-touchdowns",
            "player-total-passing-yards",
            "player-total-receiving-yards"
        ].includes(abrv)
    )
}