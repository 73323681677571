import * as defaultLive from "../terminal/live";
import * as defaultPrematch from "../terminal/prematch";
import * as defaultCategories from "../terminal/categories";

import * as live from "./live";


import { Rules } from "../../../models/rules";

const rules = new Rules();

rules.prematch = [
    defaultPrematch.soccer,
    defaultPrematch.iceHockey,
    defaultPrematch.bandyFloorball,
    defaultPrematch.tennis,
    defaultPrematch.tableTennis,
    defaultPrematch.darts,
    defaultPrematch.volleyball,
    defaultPrematch.beachVolley,
    defaultPrematch.basketball,
    defaultPrematch.aussieRules,
    defaultPrematch.handball,
    defaultPrematch.baseball,
    defaultPrematch.boxingMma,
    defaultPrematch.cricket,
    defaultPrematch.motosports,
    defaultPrematch.americanFootball,
    defaultPrematch.futsal,
    defaultPrematch.waterpolo,
    defaultPrematch.alpineSkiing,
    defaultPrematch.snooker,
    defaultPrematch.rugby,
    defaultPrematch.misc,
    // default
    defaultPrematch.defaultRule,
];

rules.live = [
    defaultLive.soccer,
    live.iceHockey,
    defaultLive.tennis,
    defaultLive.volleyball,
    defaultLive.futsal,
    defaultLive.tableTennis,
    defaultLive.basketball,
    defaultLive.handball,
    defaultLive.americanFootball,
	defaultLive.darts,
	defaultLive.snooker,
	defaultLive.rugby,
	defaultLive.baseball,
    // default
    defaultLive.defaultRule
];

rules.category = {
    highlights: defaultCategories.highlights,
    lastMinute: defaultCategories.lastMinute,
    upcoming: defaultCategories.upcoming,
    mostPlayed: defaultCategories.mostPlayed,
}


export default rules;