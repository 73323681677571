import defaultRules from './default';
import defaultRulesAt from './default-twat';
import terminalRules from './terminal';
import terminalRulesAt from './terminal-twat';
import * as v1Rules from './v1/sport-mapping-rules.json';
import * as printRules from './print/print-mapping-rules.json';

export {
	defaultRules,
	defaultRulesAt,
	terminalRules,
	terminalRulesAt,
	v1Rules,
	printRules,
}