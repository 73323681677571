import * as prematch from "./prematch";
import * as live from "./live";

import * as categories from "./categories";

import { Rules } from "../../../models/rules";

const rules = new Rules();

rules.prematch = [
	prematch.soccer,
	prematch.iceHockey,
	prematch.bandyFloorball,
	prematch.tennis,
	prematch.tableTennis,
	prematch.darts,
	prematch.volleyball,
	prematch.beachVolley,
	prematch.basketball,
	prematch.aussieRules,
	prematch.handball,
	prematch.baseball,
	prematch.boxingMma,
	prematch.cricket,
	prematch.motosports,
	prematch.americanFootball,
	prematch.futsal,
	prematch.waterpolo,
	prematch.alpineSkiing,
	prematch.snooker,
	prematch.rugby,
	prematch.misc,
	// default
	prematch.defaultRule,
];

rules.live = [
	live.soccer,
	live.iceHockey,
	live.tennis,
	live.volleyball,
	live.futsal,
	live.tableTennis,
	live.basketball,
	live.handball,
	live.americanFootball,
	live.darts,
	live.snooker,
	live.rugby,
	live.baseball,
	// default
	live.defaultRule
];

rules.category = {
	highlights: categories.highlights,
	lastMinute: categories.lastMinute,
	upcoming: categories.upcoming,
	mostPlayed: categories.mostPlayed,
}


export default rules;