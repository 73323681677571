import { IConfiguration } from ".";
import { BaseOfferStore } from "./BaseOfferStore";

export class ComparisonOffersStore {
	protected source: BaseOfferStore<IConfiguration>;

	comparisonOffers: Map<string, number>;

	constructor(source: BaseOfferStore<IConfiguration>) {
		this.source = source;

		this.comparisonOffers = new Map<string, number>();
	}

	/**
	 * Adds event key offers to comparison map
	 * @param eventId event id
	 * @param keyId key id
	 */
	add(eventId: string, keyId: string) {
		if (!this.source.eventKeysMap.has(eventId)) {
			// no event keys mapped, ignore
			return;
		}

		const eventKeys = this.source.eventKeysMap.get(eventId);

		if (!eventKeys?.has(keyId)) {
			// no key mapped, ignore
			return;
		}

		const keyOffers = this.source.keyOffersMap.get(keyId);

		if (keyOffers == null || keyOffers.size === 0) {
			// no offers mapped, ignore
			return;
		}

		// construct a map of offer ids with corresponding offer values
		keyOffers.forEach(o => this.comparisonOffers.set(o.id, o.value));
	}

	/**
	 * Gets offer value
	 * @param offerId offer id to lookup for value
	 * @returns offer value
	 */
	getValue(offerId: string): number | undefined {
		if (this.comparisonOffers.has(offerId)) {
			return this.comparisonOffers.get(offerId);
		}

		return undefined;
	}

	/**
	 * Clears comparison offers
	 */
	clear() {
		this.comparisonOffers.clear();
	}
}