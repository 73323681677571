export const emptyPlaceholder: string = " ";
export const marginPlaceholder: string = "margin";
export const nonePlaceholder: string = "none";
export const setnrPlaceholder: string = "setnr";
export const quarternrPlaceholder: string = "quarternr";
export const periodnrPlaceholder: string = "periodnr";
export const gamenrPlaceholder: string = "gamenr";
export const framenrPlaceholder: string = "framenr";
export const resultPlaceholder: string = "result";
export const multiMarginSpecifiers: Array<string> = [ 
    "gamenr", 
    "quarternr", 
    "roundnr", 
    "periodnr", 
    "setnr", 
    "inningnr", 
    "mapnr", 
    "framenr", 
    "scorenr", 
    "drivenr", 
    "appearancenr", 
    "challengenr", 
    "pitchnr", 
    "overnr", 
    "overnrX", 
    "overnrY", 
    "gamenrx", 
    "gamenry", 
    "turnovernr", 
    "dismissalnr", 
    "pointnr", 
    "xth", 
    "daynr", 
    "c2scorenr", 
    "pitchnr", 
    "deliverynr", 
    "playnr", 
    "hitnr", 
    "legnr", 
    "cornernr" 
];