import { action, decorate, observable } from 'mobx';

import { Tournament } from '@gp/models';
import { insert, Sorter } from '@gp/utility';
import { ComparerFn } from '@gp/utility/dist/types/common';

import { EventOffer } from './EventOffer';

class TournamentOffer extends Tournament {
	/**
	 * True if all events are live
	 */
	isLive: boolean;
	/**
	 * True if all events are outrights
	 */
	isOutright: boolean;
	events: EventOffer[];

	constructor(tournament: Tournament) {
		super(tournament);

		this.isLive = false;
		this.isOutright = false;
		this.events = [];
	}

	/**
	 * Adds an event with offer
	 * @param eventOffer event with offer
	 */
	addEventOffer(eventOffer: EventOffer, sortFn?: ComparerFn<EventOffer>) {
		if (eventOffer.isOutright) {
			this.events.push(eventOffer);
		}
		else {
			const innerSortFn = eventOffer.isLive ? Sorter.sort('isTop', 'startTime', 'id') : Sorter.sort('startTime', 'id');
			insert(this.events, eventOffer, sortFn ? sortFn : innerSortFn);
		}
	}

	/**
	 * Removes an event
	 * @param eventId event id to remove
	 */
	removeEventOffer(eventId: string) {
		if (eventId == null || eventId === '') {
			throw new Error('Invalid eventId');
		}

		const eIdx = this.events.findIndex(e => e.id === eventId);
		if (eIdx > -1) {
			this.events.splice(eIdx, 1);
		}
	}
}

decorate(TournamentOffer, {
	events: observable.shallow,
	addEventOffer: action.bound,
	removeEventOffer: action.bound
})

export {
	TournamentOffer
}