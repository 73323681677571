import { DefaultConfiguration } from "./DefaultConfiguration";
import { IPageableOfferConfiguration } from "./IPageableOfferConfiguration";

export class DefaultPageableConfiguration extends DefaultConfiguration implements IPageableOfferConfiguration {
	pageSize: number;

	constructor() {
		super();

		this.pageSize = 10;
	}
}