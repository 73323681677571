import { action, decorate, observable } from 'mobx';

import { Sport } from '@gp/models';
import { insert, Sorter } from '@gp/utility';

import { IMainSportHeaders } from '../mapper/models/IMainSportHeaders';
import { insertBySortOrder } from '../utility/array';
import { EventOffer } from './EventOffer';
import { SportCategoryOffer } from './SportCategoryOffer';
import { ComparerFn } from '@gp/utility/dist/types/common';

class SportOffer extends Sport {
	isLive: boolean;
	isOutright: boolean;

	headers: IMainSportHeaders;
	eventCount: number;
	events?: EventOffer[];
	categories?: SportCategoryOffer[];

	constructor(isLive: boolean = false, isOutright: boolean = false) {
		super();

		this.isLive = isLive;
		this.isOutright = isOutright;

		this.eventCount = 0;
	}

	/**
	 * Adds sport category with offer at first position.
	 * You should call updateEventCount when using this
	 * @param categoryOffer sport category with offer
	 * @param prepend if true inserts category offer element at first place, otherwise inserts by category sort order value. Default is false
	 */
	addSportCategoryOffer(categoryOffer: SportCategoryOffer, prepend: boolean = false) {
		if (this.categories == null) {
			throw new Error('Cannot add new sport category. Check if you have the correct mapping.')
		}

		if (prepend) {
			this.categories.unshift(categoryOffer);
		}
		else {
			if (this.isOutright) {
				this.categories.push(categoryOffer);
			}
			else {
				insertBySortOrder(this.categories, categoryOffer);
			}
		}
	}

	/**
	 * Removes sport category offer
	 * @param sportCategoryId sport category to remove
	 */
	removeSportCategoryOffer(sportCategoryId: string) {
		if (this.categories == null) {
			throw new Error('Cannot remove sport category. Check if you have the correct mapping.');
		}

		const scoIdx = this.categories.findIndex(sco => sco.id === sportCategoryId);
		if (scoIdx > -1) {
			this.categories.splice(scoIdx, 1);
		}
	}

	/**
	 * Adds an event with offer.
	 * @param event event with offer
	 * @param prepend if true inserts category offer element at first place, otherwise inserts by isTop then by start time. Default is false
	 */
	addEvent(event: EventOffer, prepend: boolean = false, sortFn?: ComparerFn<EventOffer>) {
		if (this.events == null) {
			throw new Error('Cannot add new event. Check if you have the correct mapping.')
		}

		if (prepend) {
			this.events.unshift(event);
		}
		else {
			insert(this.events, event, sortFn ? sortFn : Sorter.sort('isTop', 'startTime', 'id'));
		}
		this.eventCount++;
	}

	/**
	 * Removes an event
	 * @param eventId event id to remove
	 */
	removeEvent(eventId: string) {
		if (eventId == null || eventId === '') {
			throw new Error('Invalid eventId');
		}

		if (this.events != null) {
			// this means we have events grouped by sport
			const eIdx = this.events.findIndex(e => e.id === eventId);
			if (eIdx > -1) {
				this.events.splice(eIdx, 1);
			}

			this.eventCount--;
		}
		else if (this.categories != null) {
			// this means we have full structure: sport - category - tournament
			for (let i = this.categories.length - 1; i >= 0; i--) {
				const category = this.categories[i];

				for (let j = category.tournaments.length - 1; j >= 0; j--) {
					const tournament = category.tournaments[j];

					for (let k = tournament.events.length - 1; k >= 0; k--) {
						const event = tournament.events[k];

						if (event.id === eventId) {
							// remove event from tournament
							tournament.events.splice(k, 1);
						}
					}

					// remove tournament from category if tournament has no events
					if (tournament.events.length === 0) {
						category.tournaments.splice(j, 1);
					}
				}

				// remove category if it has no tournaments
				if (category.tournaments.length === 0) {
					this.categories.splice(i, 1);
				}
			}
		}
	}

	/**
	 * Updates event count
	 * @param value new number of events
	 */
	updateEventCount(value: number) {
		this.eventCount = value;
	}

	/**
	 * Updates events sorting.
	 * @param sortFn sorting rules function
	 */
	resortEvents(sortFn: ComparerFn<EventOffer>) {
		this.events = this.events?.sort(sortFn)
	}
}

decorate(SportOffer, {
	events: observable.shallow,
	eventCount: observable,
	categories: observable.shallow,
	addEvent: action.bound,
	removeEvent: action.bound,
	updateEventCount: action.bound,
	resortEvents: action.bound,
});

export {
	SportOffer
}