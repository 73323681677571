(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@gp/models"), require("@gp/utility"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define(["@gp/models", "@gp/utility", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["gp"] = factory(require("@gp/models"), require("@gp/utility"), require("mobx"));
	else
		root["gp"] = factory(root["@gp/models"], root["@gp/utility"], root["mobx"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__5879__, __WEBPACK_EXTERNAL_MODULE__50__, __WEBPACK_EXTERNAL_MODULE__2965__) {
return 