import { TypeConfiguration } from "../TypeConfiguration";

export class MappingGroup {
	name: string;
	priority: number;
	tips: string[];
	displayTips: string[];

	primary: string[];
	secondary: string[];

	/**
	 * Creates an instancve of MappingGroup
	 * @param name Name of the mapping groups
	 * @param displayTips Display betting type tips
	 * @param primary List of primary betting type abrvs
	 * @param secondary List of secondary betting type abrvs (sed for second row mapping)
	 * @param priority Priority during the mapping (currently not used)
	 */
	constructor(name: string, displayTips: string[], primary: string[], secondary: string[] = [], priority: number = -1) {
		if (primary.length === 0) {
			throw "At least one betting type (abrv) is required in primary betting types";
		}

		this.name = name;

		this.tips = this.setupTips(primary[0]);
		this.displayTips = displayTips;

		this.primary = primary;
		this.secondary = secondary;
		this.priority = priority;

	}

	private setupTips(bettingTypeAbrv: string) {
		const config = new TypeConfiguration(bettingTypeAbrv);

		return config.tips.original;
	}
}