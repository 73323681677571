import * as prematch from "./prematch";
import * as live from "./live";

import * as categories from "./categories";

import { Rules } from "../../../models/rules";

const rules = new Rules();

rules.prematch = [
	prematch.iceHockey,
	prematch.basketball,
	prematch.baseball,
	prematch.americanFootball,
	// default
	prematch.defaultRule,
];

rules.live = [
	live.americanFootball,
	live.iceHockey,
	live.basketball,
	// default
	live.defaultRule
];

rules.category = {
	highlights: categories.highlights,
	lastMinute: categories.lastMinute,
	upcoming: categories.upcoming,
	mostPlayed: categories.mostPlayed,
}


export default rules;