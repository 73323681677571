import { decorate, observable, action, runInAction, IObservableArray } from 'mobx';

import {
    BettingOffer,
    BettingOfferKeyDelete,
    BettingOfferKeyUpdate,
    Event,
    EventDelete,
    EventKeyBettingOffer,
    EventUpdate,
    IChange,
    Key,
    OfferSubscriptionResponse,
    Sport,
} from '@gp/models'
import { insert, secondsToMilliseconds, Sorter } from '@gp/utility';

import { LookupsStore } from '../LookupsStore';
import { BaseOfferStore } from '../BaseOfferStore';
import { manipulateOfferSubscriptionResponse } from '../../utility/manipulateOfferResponse'

import { DefaultLivePageableConfiguration, ILivePageableOfferConfiguration } from '..';
import { EventKeyOffer, EventOffer, insertBySortOrder, PageRowElement } from '../..';
import { getEventSortByFavorite, getEventSortByLiveStatus, getEventSortBySport, getEventSortBySportCategory, getEventSortByTournament } from '../../utility';

class EventRow extends EventOffer {
    /**
     * Number of rows this event takes to render
     * @default 1
     */
    numberOfRows: number

    constructor(event: Event, display: ConstructorParameters<typeof EventOffer>[1] = null, numberOfRows: number = 1) {
        super(event, display);

        this.numberOfRows = numberOfRows;
    }
}

class LivePageableOfferStore extends BaseOfferStore<ILivePageableOfferConfiguration> {
    protected version: number = 0;

    protected _buffer: OfferSubscriptionResponse[] = [];
    protected _bufferTimeoutId: number | undefined;

    protected _recalculatePages: boolean = false;
    protected _resort: boolean = false;

    /**
     * True if offer was initialized. This comes handy when offer reset happens and we need to display tips
     */
    initialized: boolean = false;

    lookupsStore: LookupsStore;

    pageNumber: number = 1;

    /**
     * Used to filter page offer for given sports
     */
    displaySportIds: Set<string> = new Set<string>();

    /**
     * List of events in the current offer
     */
    events: IObservableArray<EventRow> = [] as IObservableArray<EventRow>;
    pageData: PageRowElement[][] = [];

    /**
     * Gets list of sports
     * Typically used for side menu
     */
    get listOfSports(): Sport[] {
        const output: Sport[] = [];

        this.lookupsStore.sports.forEach(sport => {
            insertBySortOrder(output, sport)
        });

        return output;
    }

    constructor(configuration?: ILivePageableOfferConfiguration) {
        super(Object.assign({}, new DefaultLivePageableConfiguration(), configuration));
    }

    /**
     * Change page
     * @param newPage 
     */
    changePage(newPage: number) {
        if (newPage > 0 && newPage !== this.pageNumber) {
            this.pageNumber = newPage;
        }
    }

    /**
     * Toggles selected sport.
     * @param sportId sport id to add/remove
     */
    toggleSelectedSport(sportId: string) {
        if (this.displaySportIds.has(sportId)) {
            this.displaySportIds.delete(sportId);
        }
        else {
            this.displaySportIds.add(sportId);
        }
    }

    /**
     * Clears selected sports
     */
    clearSelectedSports() {
        this.displaySportIds.clear();
    }

    assignOfferData(data: OfferSubscriptionResponse) {
        data = manipulateOfferSubscriptionResponse(data, this);
        // clear comparison offer store before any new mapping
        this.comparisonOfferStore.clear();

        if (!this.configuration.enableThrottling) {
            this.processMessage(data);

            if (this._recalculatePages) {
                this.updatePageData();
            }
            if (this._resort) {
                this.resortEvents();
            }

            // reset offer indicators
            setTimeout(() => {
                runInAction(() => {
                    Array.from(this.keyOffersMap.values()).map(ko => Array.from(ko.values())).flat().filter(o => o.indicator !== 0).forEach(o => o.indicator = 0);
                });
            }, this.configuration.indicatorDuration || 3500);

            this.initialized = true;

            return;
        }

        if (data.startingVersion === 0) {
            // clear buffer first
            clearTimeout(this._bufferTimeoutId);
            this._bufferTimeoutId = undefined;
            this._buffer = [];

            // process message
            this.processMessage(data);

            if (this._recalculatePages) {
                this.updatePageData();
            }
            if (this._resort) {
                this.resortEvents();
            }

            this.initialized = true;
            return;
        }

        this._buffer.push(data);

        if (this._bufferTimeoutId == null) {
            this._bufferTimeoutId = window.setTimeout(() => runInAction(() => {
                try {
                    this._buffer.forEach(r => this.processMessage(r));

                    if (this._recalculatePages) {
                        this.updatePageData();
                    }
                    if (this._resort) {
                        this.resortEvents();
                    }
                    // reset offer indicators
                    setTimeout(() => {
                        runInAction(() => {
                            Array.from(this.keyOffersMap.values()).map(ko => Array.from(ko.values())).flat().filter(o => o.indicator !== 0).forEach(o => o.indicator = 0);
                        });
                    }, this.configuration.indicatorDuration || 3500);

                    this._buffer = [];
                    this._bufferTimeoutId = undefined;
                } catch (error) {

                    if (!this.configuration.hubSubscriptionHandler) {
                        throw new Error(`hubSubscriptionHandler not added to configuration! Error: ${error}`);
                    }

                    this.configuration.hubSubscriptionHandler.scheduleRecovery();
                }
            }), secondsToMilliseconds(this.configuration.throttle));
        }
    }

    processMessage(data: OfferSubscriptionResponse) {
        if (data == null) {
            return;
        }

        const {
            lookups,
            offerChanges,
            startingVersion,
            version
        } = data;

        if (startingVersion === 0) {
            this.logger.logWarn(`Offer reset. Previous version: ${version}`);
            this.reset();
        }

        // we've received older version than we currently have - ignore it
        if (version < this.version) {
            this.logger.logWarn(`Received older offer - skipping. Received: ${version} | Current: ${this.version}`)
            return;
        }

        if (lookups != null && !lookups.isEmpty) {
            this.lookupsStore.update(lookups);
        }
        else {
            this.logger.logInfo("Did not receive lookups.");
        }

        if (offerChanges != null) {
            this.processOfferChanges(offerChanges);

            if (startingVersion === 0) {
                this._recalculatePages = true;
            }

            // if (this._recalculatePages || startingVersion === 0) {
            //     this.updatePageData();
            // }
        }
        else {
            this.logger.logWarn("Received empty offer.");
        }

        // update version to new value after mapping is done.
        this.version = version;
    }

    processOfferChanges(offerChanges: IChange<EventUpdate, EventDelete>) {
        // let shouldRecalculatePages = false;
        if (offerChanges.updates != null && offerChanges.updates.length > 0) {
            this.processEventUpdates(offerChanges.updates);
        }

        if (offerChanges.deletes != null && offerChanges.deletes.length > 0) {
            this.processEventDeletes(offerChanges.deletes);
        }
    }

    /**
     * Update events. Returns true if event was updated
     * @param updates event updates/deletes
     */
    processEventUpdates(updates: EventUpdate[]) {
        // let recalculatePages: boolean = false;
        updates.forEach(eventUpdate => {
            let currentEvent: EventRow | undefined;
            if (eventUpdate.event != null) {
                currentEvent = this.updateEvent(eventUpdate.event);
            } else {
                currentEvent = this.events.find(e => e.id == eventUpdate.eventId);
            }

            if (currentEvent == null) {
                throw `Cannot update event that is not in offer. Has event update: ${eventUpdate.event != null}.`
            }

            if (eventUpdate.offers != null) {
                if (eventUpdate.offers.updates != null) {
                    this.processKeyUpdates(eventUpdate.eventId, eventUpdate.offers.updates);
                }
                if (eventUpdate.offers.deletes != null) {
                    this.processKeyDeletes(eventUpdate.eventId, eventUpdate.offers.deletes);
                }
            }

            this.updateDisplayRows(currentEvent);
        });
    }

    /**
     * Deletes event offer keys
     * @param eventId event id
     * @param keyDeletes keys to delete
     */
    processKeyDeletes(eventId: string, keyDeletes: BettingOfferKeyDelete[]) {
        const eventKeys = this.eventKeysMap.get(eventId);

        keyDeletes.forEach(key => {
            // first delete key offers
            this.keyOffersMap.delete(key.id);

            // then delete key
            if (eventKeys != null) {
                eventKeys.delete(key.id);
            }
        });

        // re-set updated event keys
        if (eventKeys != null) {
            this.eventKeysMap.set(eventId, eventKeys);
        }
    }

    /**
     * Updates event offer keys
     * @param eventId event id
     * @param keyUpdates keys to update
     */
    processKeyUpdates(eventId: string, keyUpdates: BettingOfferKeyUpdate[]) {
        keyUpdates.forEach(keyUpdate => {
            if (keyUpdate.key != null) {
                this.updateKey(eventId, keyUpdate.key);
            }

            // add current key offers state in memory for indicator comparison
            this.comparisonOfferStore.add(eventId, keyUpdate.keyId);

            // update offer
            if (keyUpdate.offers != null) {
                if (keyUpdate.offers.deletes) {
                    this.processOfferDeletes(keyUpdate.keyId, keyUpdate.offers.deletes);
                }

                if (keyUpdate.offers.updates) {
                    this.processOfferUpdates(eventId, keyUpdate);
                }
            }
        });
    }

    /**
     * Deletes offers
     * @param keyId key id
     * @param offers offers to delete
     */
    processOfferDeletes(keyId: string, offers: BettingOffer[]) {
        const keyOffers = this.keyOffersMap.get(keyId);
        if (keyOffers == null) {
            this.logger.logTrace('Offer already removed');
            return;
        };

        offers.forEach(o => {
            keyOffers?.delete(o.id);
        });

        this.keyOffersMap.set(keyId, keyOffers);
    }

    /**
     * Updates offers
     * @param eventId event id
     * @param keyId key id
     * @param offers offers to update
     */
    processOfferUpdates(eventId: string, key: BettingOfferKeyUpdate) {
        const keyOffers = this.keyOffersMap.get(key.keyId)|| new Map<string, EventKeyBettingOffer>();
        const event = this.events.find(e => e.id === eventId);

        // check if all offers have same playerId, or if they have playerId at all
        let playerId: string | undefined = undefined;
        let teamIds: { teamOneId: string | undefined, teamTwoId: string | undefined } | undefined = undefined;

        if (key.offers?.updates?.every((o, i, src) => o.playerId != null && o.playerId !== '' && o.playerId === src[0].playerId)) {
            playerId = key.offers.updates[0].playerId;
        }

        if (!event?.isOutright) {
            teamIds = {
                teamOneId: event?.teamOneId,
                teamTwoId: event?.teamTwoId,
            }
        }

        key.offers?.updates?.forEach(o => {
            const tipInfo = this.getTipInformation({
                defaultTip: o.tip,
                bettingTypeId: key.bettingTypeId,
                playerId: o.playerId,
                teamId: o.teamId,
                specifiers: {
                    ...key.key?.specifier,
                    ...o.specifier
                },
                eventData: {
                    name: event?.name,
                    playerId: playerId,
                    teamIds: teamIds
                }
            });

            const existingOffer = keyOffers?.get(o.id);
            if (existingOffer != null) {
                const comparableValue = this.comparisonOfferStore.getValue(existingOffer?.id);

                // calculate indicator
                if (comparableValue === undefined) {
                    existingOffer.indicator = 0;
                }
                else if (o.value > comparableValue) {
                    existingOffer.indicator = 1;
                }
                else if (o.value < comparableValue) {
                    existingOffer.indicator = -1;
                }
                else {
                    existingOffer.indicator = 0;
                }

                existingOffer.isLive = !!event?.isLive;
                existingOffer.isLocked = !!o.isLocked
                existingOffer.value = o.value;

                existingOffer.tip = tipInfo.tip;
                existingOffer.displayTip = tipInfo.displayTip;
                existingOffer.gender = tipInfo.gender;
            }
            else {
                keyOffers?.set(o.id, {
                    ...o,
                    keyId: key.keyId,
                    eventId: eventId,
                    isLive: !!event?.isLive,
                    isLocked: !!o.isLocked,
                    tip: tipInfo.tip,
                    displayTip: tipInfo.displayTip,
                    gender: tipInfo.gender,
                    indicator: 0
                });
            }
        });

        this.keyOffersMap.set(key.keyId, keyOffers);
    }


    /**
     * Updates key data
     * @param eventId event id
     * @param key Key to update
     */
    updateKey(eventId: string, key: Key) {
        const eventKeys = this.eventKeysMap.get(eventId) || new Map<string, EventKeyOffer>();
        const currentKey = eventKeys.get(key.id);


        if (currentKey == null) {
            const keyBettingType = this.lookupsStore.bettingTypes.get(key.bettingTypeId);

            if (keyBettingType == null) {
                throw `Cannot create new key. Missing betting type with id: ${key.bettingTypeId}.`
            }

            const newKey = this.createNewKey(key, eventId, keyBettingType);

            eventKeys.set(key.id, newKey);
        }
        else {
            currentKey.update(key);

            eventKeys.set(key.id, currentKey);
        }

        this.eventKeysMap.set(eventId, eventKeys);
    }

    processEventDeletes(deletes: EventDelete[]) {

        const { ended, immediate } = deletes.reduce((acc: { ended: EventDelete[], immediate: EventDelete[] }, event: EventDelete) => {
            if (event.ended) {
                acc.ended.push(event);
            }
            else {
                acc.immediate.push(event);
            }

            return acc;
        }, {
            immediate: [],
            ended: [],
        });

        if (immediate.length > 0) {
            this.deleteEvents(immediate.map(e => e.id))
            this._recalculatePages = true;
        }
        if (ended.length > 0) {
            ended.forEach(e => this.updateEvent(e));

            setTimeout(() => runInAction(() => {
                // delay.forEach(eid => this.deleteEvent(eid));
                this.deleteEvents(ended.map(e => e.id));

                // update pages after events were deleted
                this.updatePageData();
            }), secondsToMilliseconds(this.configuration.removeDelay));
        }
    }

    updateDisplayRows(event) {
        const sport: any = this.lookupsStore.sports.get(event.sportId);
        const headers = this.OfferMapper.getMainSportHeaders(sport.abrv, true, this.configuration.customConfiguration);
        const eventKeys = this.eventKeysMap.get(event.id);
        sport.isLive = true;

        if (eventKeys == null) {
            return;
        }

        let foundSecondaryOffer = false;
        const config = this.configuration.bettingTypeSelectorsStore.sportSelections.get(this.configuration.bettingTypeSelectorsStore.selectorCollectionKey(sport));

        if (config) {

            for (let ek of eventKeys.values()) {

                const secondaryColumns = {};
                try {
                    Object.keys(config).forEach(col => {
                        if (config[col] != null && Object.keys(config[col]).length > 0) {
                            const relatedBts = this.OfferMapper.getRelatedBettingTypes(config[col].name);

                            const eventBettingTypes = [];
                            eventKeys.forEach(e => eventBettingTypes.push(e.bettingType.abrv));
                            const areInOffer = relatedBts.some(bt => eventBettingTypes.includes(bt));

                            if (relatedBts.length > 0 && areInOffer) {
                                secondaryColumns[col] = [this.OfferMapper.getColumnConfigurationForBettingType(relatedBts[0])];
                            }
                            else {
                                secondaryColumns[col] = [];
                            }
                        }
                        else {
                            secondaryColumns[col] = [];
                        }
                    });
                    const headersBettingTypes = headers.secondary.map(header => header.bettingType);
                    if (headersBettingTypes.includes(ek.bettingType.abrv) && !Object.keys(secondaryColumns).every(key => secondaryColumns[key].length === 0)) {
                        foundSecondaryOffer = true;
                        break;
                    }
                } catch (error) {
                    throw error;
                }
            }
        }

        const rowNumber = !foundSecondaryOffer ? 1 : 2;

        if (event.numberOfRows != rowNumber) {
            event.numberOfRows = rowNumber;

            this._recalculatePages = true;
        }
    }

    /**
     * Adds new event or updates existing. Returns true if event was added.
     * @param event event to add/update
     */
    updateEvent(event: Event): EventRow {
        const currentEvent = this.events.find(e => e.id === event.id);

        if (currentEvent == null) {
            const newEvent = new EventRow(event, this.configuration.display);

            insert(this.events, newEvent, Sorter.sort(
                getEventSortByLiveStatus,
                (a, b) => {
                    if (a.isLive && b.isLive) {
                        return Sorter.sort(
                            (a1: EventOffer, b1: EventOffer) => getEventSortBySport(a1, b1, this.lookupsStore),
                            (a1: EventOffer, b1: EventOffer) => getEventSortByFavorite(a1, b1, this.configuration.userFavoritesStore),
                            'isTop',
                            'startTime',
                            (a1: EventOffer, b1: EventOffer) => getEventSortBySportCategory(a1, b1, this.lookupsStore),
                            (a1: EventOffer, b1: EventOffer) => getEventSortByTournament(a1, b1, this.lookupsStore),
                        )(a, b);
                    }
                    else if (a.isUpcoming && b.isUpcoming) {
                        return Sorter.sort(
                            'startTime',
                            (a1: EventOffer, b1: EventOffer) => getEventSortBySport(a1, b1, this.lookupsStore),
                            (a1: EventOffer, b1: EventOffer) => getEventSortBySportCategory(a1, b1, this.lookupsStore),
                            (a1: EventOffer, b1: EventOffer) => getEventSortByTournament(a1, b1, this.lookupsStore),
                        )(a, b);
                    }
                    return 0;
                },
                'id'
            ));

            // event inserted, recalculate pages
            this._recalculatePages = true;

            return newEvent;
        }
        else {
            currentEvent.update(event);

            this._resort = true;
            this._recalculatePages = true;

            return currentEvent;
        }
    }

    updateSportRows(sport: Sport) {
        this.configuration.hubSubscriptionHandler.recoverOnMessageMappingErrors(() => {
            if (sport != null) {
                this.events.forEach(event => {
                    if (event.sportId === sport.id) {
                        this.updateDisplayRows(event)
                    }
                });
                if (this._recalculatePages) {
                    this.updatePageData();
                }
            }
        });
    }

    /**
     * Used to resort events after update
     */
    resortEvents() {
        this.events = this.events.sort(Sorter.sort(
            // this.sortByLiveStatus,
            'isLive',
            (a, b) => {
                if (a.isLive && b.isLive) {
                    return Sorter.sort(
                        (a1: EventOffer, b1: EventOffer) => getEventSortBySport(a1, b1, this.lookupsStore),
                        (a1: EventOffer, b1: EventOffer) => getEventSortByFavorite(a1, b1, this.configuration.userFavoritesStore),
                        'isTop',
                        'startTime',
                        (a1: EventOffer, b1: EventOffer) => getEventSortBySportCategory(a1, b1, this.lookupsStore),
                        (a1: EventOffer, b1: EventOffer) => getEventSortByTournament(a1, b1, this.lookupsStore),
                    )(a, b);
                }
                else if (a.isUpcoming && b.isUpcoming) {
                    return Sorter.sort(
                        'startTime',
                        (a1: EventOffer, b1: EventOffer) => getEventSortBySport(a1, b1, this.lookupsStore),
                        (a1: EventOffer, b1: EventOffer) => getEventSortBySportCategory(a1, b1, this.lookupsStore),
                        (a1: EventOffer, b1: EventOffer) => getEventSortByTournament(a1, b1, this.lookupsStore),
                    )(a, b);
                }
                return 0;
            },
            'id'
        ));

        this._resort = false;
    }

    sortAfterFavorite() {
        this.resortEvents();
        this.updatePageData();
    }

    /**
     * Removes events in batch
     * @param eventIds event ids to delete
     */
    deleteEvents(eventIds: string[]) {
        const availableEvents = this.events.filter(e => !eventIds.includes(e.id));
        this.events.replace(availableEvents);

        // Remove event keys and offer
        eventIds.forEach(eid => {
            const eventKeys = this.eventKeysMap.get(eid);

            if (eventKeys != null) {
                eventKeys.forEach(ek => this.keyOffersMap.delete(ek.id));
            }

            this.eventKeysMap.delete(eid);
        });
    }

    /**
     * Removes single event
     * @param eventId event id to remove
     */
    deleteEvent(eventId: string) {
        const eIdx = this.events.findIndex(e => e.id === eventId);

        if (eIdx !== -1) {
            this.events.splice(eIdx, 1);
        }

        const eventKeys = this.eventKeysMap.get(eventId);
        if (eventKeys != null) {
            eventKeys.forEach(ek => this.keyOffersMap.delete(ek.id));
        }
        this.eventKeysMap.delete(eventId);
    }

    updatePageData() {
        let pd: PageRowElement[] = [];
        const pages = [pd];
        let sportId: string;

        // tracks number of places taken for the current page
        // this is required because events can take up multiple rows
        let currentPagePlacesTaken: number = 0;

        this.events
            // if no display sports are selected, display all events, otherwise, display only events for selected sports
            .filter(event => {
                if (this.displaySportIds.size < 1) {
                    return true;
                }
                else if (this.displaySportIds.size === 1 && this.displaySportIds.has("favorites")) {
                    return this.configuration.userFavoritesStore.userFavoriteEventsSet.has(event.id);
                }
                else {
                    return this.displaySportIds.has(event.sportId)
                }
            })
            .forEach(event => {
                if (event.sportId !== sportId) {
                    if (currentPagePlacesTaken + event.numberOfRows + 1 > this.configuration.rowCount) {
                        pd = [];
                        currentPagePlacesTaken = 0;
                        pages.push(pd);
                    }
                    // push header first
                    pd.push(PageRowElement.create(event.sportId, 'header'));
                    ++currentPagePlacesTaken;
                    sportId = event.sportId;
                } else if (currentPagePlacesTaken + event.numberOfRows > this.configuration.rowCount) {
                    pd = [];
                    pages.push(pd);
                    pd.push(PageRowElement.create(event.sportId, 'header'));
                    currentPagePlacesTaken = 1;
                }

                pd.push(PageRowElement.create(event.id, 'event', event.numberOfRows == 1 ? 1 : 2));
                currentPagePlacesTaken += event.numberOfRows;
            });

        this._recalculatePages = false;
        this.pageData = pages;
    }

    reset() {

        this.events = [] as IObservableArray<EventRow>;
        this.pageData = [];
        this.pageNumber = 1;

        this.clearSelectedSports();

        this._buffer = [];
        clearTimeout(this._bufferTimeoutId);

        this.initialized = false;

        super.reset();
    }
}

decorate(LivePageableOfferStore, {
    pageNumber: observable,
    initialized: observable,
    pageData: observable,
    events: observable,
    displaySportIds: observable,
    changePage: action.bound,
    toggleSelectedSport: action.bound,
    clearSelectedSports: action.bound,
    assignOfferData: action.bound,
    processMessage: action.bound,
    processOfferChanges: action.bound,
    processEventUpdates: action.bound,
    processEventDeletes: action.bound,
    processKeyUpdates: action.bound,
    processKeyDeletes: action.bound,
    processOfferUpdates: action.bound,
    processOfferDeletes: action.bound,
    updateEvent: action.bound,
    deleteEvents: action.bound,
    deleteEvent: action.bound,
    reset: action.bound,
    updatePageData: action.bound,
    updateDisplayRows: action.bound,
    resortEvents: action.bound,
    sortAfterFavorite: action.bound,
    updateSportRows: action.bound
});

export {
    LivePageableOfferStore,
    EventRow
}
